import React from 'react';
import axios from "axios";
import moment from 'moment';
import Api from "../../../assets/js/utils/Api";
import { Helmet } from 'react-helmet';
import PropTypes from "prop-types";
import Auth from "../../../assets/js/utils/Auth";
import { helper } from '../../../assets/js/utils/Element';
import BackgroundSlider from "../../components/Slider/BackgroundSlider";
import Button from "../../components/CustomButtons/Button";
import GridItem from "../../components/Grid/GridItem";
import LoaderComponent from "../../components/Loader";
import ExternalLinkModal from "../../components/User/ExternalLinkModal";
import ReactTable from "react-table";
import RefreshIcon from "@material-ui/icons/Refresh";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import DeleteExternalLinkModal from "../../components/User/DeleteExternalLinkModal";
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import linksPageStyle from '../../../assets/jss/user/linksPageStyle.jsx';
import ExternalLinkDetailModal from  "../../components/User/ExternalLinkDetailModal";
import ChangeExpiryDateModal from "../../components/User/ChangeExpiryDateModal";
import ChangeLinkPasswordModal from "../../components/User/ChangeLinkPasswordModal";

const ExternalLinks = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;

        this.state = {
            totalItemCount: 0,
            response: null,
            loading: false,
            loadingMore: false,
            totalPages: 1,
            page: 1,
            checked: [],
            checkedAll: false,
            linkName: false,
            externalLink: null,
            linkModal: false,
            savingLinkModal: false,
            newLinkUploaded: false,
            errorMessage: null,
            deleteModal: false,
            snackbarOpen: false,
            editMode: false,
            linkDetail: this.getDefaultLinkDetail(),
            linkId: null,
            viewPasswords: [],
            changeExpiryDateModal: false,
            changingExpiryDate: false,
            changeLinkPasswordModal: false,
            changingLinkPassword: false,
            expire: null,
            linkPassword: null,
        };

        this.closeSnackbar = this.closeSnackbar.bind(this);
    }
    componentDidMount(){
        const { authorized } = this.store.getState();
        if(!authorized && !Auth.hasAccessToken()){
            const location = this.history.location;
            const loginRequired = "/auth/login?return="+encodeURIComponent(location.pathname+location.search);
            this.history.push(loginRequired);
        }
        this.loadExternalLinks();
    }
    getDefaultLinkDetail(){
        return {
            id: "",
            hash: "",
            url: "",
            title: "",
            linkPassword: "",
            expire: null,
            hideLink: false,
        }
    }
    loadExternalLinks(viewMore = false){
        const source = axios.CancelToken.source();
        let page = this.state.page;
        if(viewMore){
            page += 1;
        }
        const requestData = {
            page: page,
            limit: 50,
        };
        Api.getExternalLinks(requestData, source).then(data => {
            const oldResponse = (viewMore ? this.state.response:[]);
            const response = oldResponse.concat(data.response);
            this.setState({
                totalItemCount: data.totalItemCount,
                response: response,
                loading: false,
                loadingMore: false,
                totalPages: data.totalPages
            });
        }).catch(err => {
            console.log(err);
        });
        this.setState({
            loading: (!viewMore), 
            loadingMore: viewMore,
            cancelToken: source,
            page: page,
            linkDetailModal: false,
            linkModal: false,
            deleteModal: false,
            changeExpiryDateModal: false,
            changingExpiryDate: false,
            changeLinkPasswordModal: false,
            changingLinkPassword: false,
        });
    }
    onLinkModal(status = false){
        let { checked, response } = this.state;
        let state = {
            linkName: status,
            linkModal: status,
            editMode: false,
            errorMessage: null
        };

        if(checked.length > 0){
            checked = checked[0];
            let file = response.filter(x => x.id === checked)[0];

            state['linkDetail'] = {
                    ...this.getDefaultLinkDetail(),
                    url: file.url
                };
        }else{
            state['linkDetail'] = this.getDefaultLinkDetail();
        }

        if(status === false){
            state['checked'] = [];
            state['checkedAll'] = false;
        }

        this.setState(state);
    }
    onExternalLinkModalSuccess(data, editMode = false){
        const source = axios.CancelToken.source();

        this.setState({
            savingLinkModal: true,
            cancelToken: source,
            errorMessage: null
        })

        if(editMode === true) {
            Api.editExternalLink(data, source).then(data => {
                let externalLink = "/external-links/"+data.hash;
                this.setState({
                    savingLinkModal: false, 
                    externalLink: externalLink,
                    linkName: false,
                    editMode: false,
                    cancelToken: null,
                    linkDetail: this.getDefaultLinkDetail(),
                    checked: [],
                    checkedAll: false
                });
            }).catch(err => {
                if(typeof(err) === "object" && err.hasOwnProperty("message")){
                    this.setState({
                        savingLinkModal: false, 
                        cancelToken: null,
                        errorMessage: err.message
                    });
                }
            });
        }else{
            Api.createExternalLink(data, source).then(data => {
                let externalLink = "/external-links/"+data.hash;
                this.setState({
                    savingLinkModal: false, 
                    externalLink: externalLink,
                    linkName: false,
                    editMode: false,
                    cancelToken: null,
                    linkDetail: this.getDefaultLinkDetail(),
                    checked: [],
                    checkedAll: false
                });
            }).catch(err => {
                if(typeof(err) === "object" && err.hasOwnProperty("message")){
                    this.setState({
                        savingLinkModal: false, 
                        cancelToken: null,
                        errorMessage: err.message
                    });
                }
            });
        }
    }
    onLinkModalSuccess(){
        this.setState({
            cancelToken: null,
            linkModal: false,
            savingLinkModal: false,
            linkName: false,
            errorMessage: null,
            newLinkUploaded: false,
            editMode: false,
            linkDetail: this.getDefaultLinkDetail(),
            checked: [],
            checkedAll: false,
            externalLink: null
        }, () => {
            this.loadExternalLinks();
        });
    }
    onToggleAllFiles(e){
        const checkedLinks = [];
        if(e.target.checked){
            const { response } = this.state;
            response.map((link, key) => {
                checkedLinks.push(link.id);
                return null;
            });
        }
        
        this.setState({
            checkedAll: !this.state.checkedAll,
            checked: checkedLinks
        });
    }
    getColumns(){
        const { classes } = this.props;
        const header = (
            <Checkbox
                tabIndex={-1}
                checked={this.state.checkedAll}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                onChange={(e) => this.onToggleAllFiles(e)}
                classes={{
                    checked: classes.checked,
                    root: classes.checkRoot
                }}
            />
        );
        let columns = [
            {
                Header: header,
                accessor: "check",
                sortable: false,
                filterable: false,
                headerClassName: "hd_check",
                className: "hd_check td_check",
                resizable: false,
            },
            {
                Header: "Name",
                accessor: "name",
                headerClassName: "hd_recipients",
                className: "hd_recipients td_recipients",
            },
            
            {
                Header: "Date Created",
                accessor: "created_at",
                headerClassName: "hd_created",
                className: "hd_created hd_created",
                sortMethod: (a, b) => {
                    var a1 = new Date(a).getTime();
                    var b1 = new Date(b).getTime();
                  if(a1<b1)
                    return 1;
                  else if(a1>b1)
                    return -1;
                  else
                    return 0;
                }
            },
            {
                Header: "Source Path",
                accessor: "url",
                headerClassName: "hd_attachments",
                className: "hd_attachments td_attachments",
            },
            {
                Header: "Password",
                accessor: "password",
                headerClassName: "hd_link_password",
                className: "hd_link_password td_link_password",
            },
            {
                Header: "Expiry",
                accessor: "expire_at",
                headerClassName: "hd_expire",
                className: "hd_expire td_expire",
                sortMethod: (a, b) => {
                    var a1 = new Date(a).getTime();
                    var b1 = new Date(b).getTime();
                  if(a1<b1)
                    return 1;
                  else if(a1>b1)
                    return -1;
                  else
                    return 0;
                }
            },
            {
                Header: "",
                accessor: "actions",
                headerClassName: "hd_actions",
                className: "hd_actions td_actions",
                sortable: false,
            },
        ];

        return columns;
    }
    onViewPassword(link){
        const { viewPasswords } = this.state;
        const currentIndex = viewPasswords.indexOf(link.id);
        const newViewPasswords = [...viewPasswords];
    
        if (currentIndex === -1) {
            newViewPasswords.push(link.id);
        } else {
            newViewPasswords.splice(currentIndex, 1);
        }

        this.setState({
            viewPasswords: newViewPasswords
        });
    }
    convertStringToPass(password){
        let string = "";
        for (let i = 0; i < password.length; i++) {
            string +="<span></span>";
        }

        return string;
    }
    getTableData(){
        const { classes } = this.props;
        const { response, checked, viewPasswords } = this.state;
        if(response === null){
            return [];
        }
        let tableData = [];
        response.map(link => {
            let linkArray = {
                id: link.id,
                check: (
                    <Checkbox
                        tabIndex={-1}
                        checked={checked.includes(link.id)}
                        className={classes.positionAbsolute}
                        onClick={() => this.handleToggle(link)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                        }}
                    />
                ),
                name: link.name,
                url: link.url,
                hide_link: (link.hide_link ? "Yes" : "No"),
                password: (
                    <>
                        {
                            link.password ?
                                <div className="sd-password-field">
                                    <div>
                                        { 
                                            viewPasswords.includes(link.id) ?
                                                link.password
                                            :
                                                <div className="sd-passwords" dangerouslySetInnerHTML={{__html: this.convertStringToPass(link.password)}}></div>
                                        }
                                    </div>
                                    <Button justIcon className="eye-icon" color="transparent" onClick={() => this.onViewPassword(link)} title={"View Password"}>
                                        { 
                                            viewPasswords.includes(link.id) ?
                                                <VisibilityOffIcon />
                                            :
                                                <VisibilityIcon />
                                        }
                                    </Button>
                                    <Button justIcon color="transparent" onClick={() => this.onChangeLinkPassword(true, link.id, link.password)} title={"Link Password"}>
                                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.08397 0.376953L6.13866 1.32227L8.67772 3.86133L9.62303 2.91602C10.1113 2.42773 10.1113 1.63672 9.62303 1.14844L8.8535 0.376953C8.36522 -0.111328 7.57421 -0.111328 7.08592 0.376953H7.08397ZM5.69725 1.76367L1.14452 6.31836C0.941393 6.52148 0.792955 6.77344 0.710924 7.04883L0.019518 9.39844C-0.0293102 9.56445 0.0156117 9.74219 0.136705 9.86328C0.257799 9.98438 0.435534 10.0293 0.599596 9.98242L2.94921 9.29102C3.2246 9.20899 3.47655 9.06055 3.67967 8.85742L8.23631 4.30273L5.69725 1.76367Z"/>
                                        </svg>
                                    </Button>
                                </div>
                            :
                                <Button className="text-button icon" color="transparent" onClick={() => this.onChangeLinkPassword(true, link.id, link.password)} title={"Link Password"}>
                                    Add Password
                                    <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.5 2.5V3.75H6.25V2.5C6.25 1.46484 5.41016 0.625 4.375 0.625C3.33984 0.625 2.5 1.46484 2.5 2.5ZM1.875 3.75V2.5C1.875 1.11914 2.99414 0 4.375 0C5.75586 0 6.875 1.11914 6.875 2.5V3.75H7.1875C8.05078 3.75 8.75 4.44922 8.75 5.3125V8.4375C8.75 9.30078 8.05078 10 7.1875 10H1.5625C0.699219 10 0 9.30078 0 8.4375V5.3125C0 4.44922 0.699219 3.75 1.5625 3.75H1.875ZM0.625 5.3125V8.4375C0.625 8.95508 1.04492 9.375 1.5625 9.375H7.1875C7.70508 9.375 8.125 8.95508 8.125 8.4375V5.3125C8.125 4.79492 7.70508 4.375 7.1875 4.375H1.5625C1.04492 4.375 0.625 4.79492 0.625 5.3125Z"/>
                                    </svg>
                                </Button>
                        }
                    </>
                ),
                expire_at_orignal: link.expire_at,
                expire_at: this.getFormatedExpiry(link),
                created_at: link.created_at,
                actions: (
                    <div>
                        <Button className={"text-button"} color="custom" onClick={() => this.onLinkDetailModal(true, link)} title={""}>
                            View Details
                        </Button>
                        <Button justIcon color="transparent" onClick={() => this.copyViewLink(link)} title={"Copy Link"}>
                            <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15 13.75H8.75C8.05859 13.75 7.5 13.1914 7.5 12.5V2.5C7.5 1.80859 8.05859 1.25 8.75 1.25H12.9727C13.1367 1.25 13.2969 1.31641 13.4141 1.43359L16.0664 4.08594C16.1836 4.20312 16.25 4.36328 16.25 4.52734V12.5C16.25 13.1914 15.6914 13.75 15 13.75ZM16.9492 3.19922L14.3008 0.550781C13.9492 0.199219 13.4727 0 12.9766 0H8.75C7.37109 0 6.25 1.12109 6.25 2.5V12.5C6.25 13.8789 7.37109 15 8.75 15H15C16.3789 15 17.5 13.8789 17.5 12.5V4.52734C17.5 4.03125 17.3008 3.55469 16.9492 3.20312V3.19922ZM2.5 5C1.12109 5 0 6.12109 0 7.5V17.5C0 18.8789 1.12109 20 2.5 20H8.75C10.1289 20 11.25 18.8789 11.25 17.5V16.25H10V17.5C10 18.1914 9.44141 18.75 8.75 18.75H2.5C1.80859 18.75 1.25 18.1914 1.25 17.5V7.5C1.25 6.80859 1.80859 6.25 2.5 6.25H5V5H2.5Z" fill="#556DC2"/>
                            </svg>
                        </Button>
                        <Button justIcon color="transparent" onClick={() => this.onEditModal(true, link)} title={"Edit Link"}>
                            <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.37559 0.339355L5.5248 1.19014L7.80996 3.47529L8.66074 2.62451C9.1002 2.18506 9.1002 1.47314 8.66074 1.03369L7.96816 0.339355C7.52871 -0.100098 6.8168 -0.100098 6.37734 0.339355H6.37559ZM5.12754 1.5874L1.03008 5.68662C0.847266 5.86943 0.713672 6.09619 0.639844 6.34404L0.0175784 8.45869C-0.0263669 8.60811 0.0140627 8.76807 0.123047 8.87705C0.232031 8.98604 0.391992 9.02647 0.539649 8.98428L2.6543 8.36201C2.90215 8.28818 3.12891 8.15459 3.31172 7.97178L7.4127 3.87256L5.12754 1.5874Z" fill="#556DC2"/>
                            </svg>
                        </Button>
                        <Button justIcon color="transparent" onClick={() => this.onDeleteModal(true, link.id)} title={"Delete"}>
                            <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.94141 1.25H10.5547C10.7695 1.25 10.9688 1.35938 11.0859 1.54297L11.6836 2.5H5.81641L6.41406 1.54297C6.52734 1.35938 6.73047 1.25 6.94531 1.25H6.94141ZM13.1602 2.5L12.1484 0.882812C11.8047 0.332031 11.2031 0 10.5586 0H6.94141C6.29688 0 5.69531 0.332031 5.35156 0.882812L4.33984 2.5H2.50391H1.25H0.625C0.28125 2.5 0 2.78125 0 3.125C0 3.46875 0.28125 3.75 0.625 3.75H1.33984L2.33594 17.6797C2.42578 18.9883 3.51562 20 4.82812 20H12.6719C13.9844 20 15.0703 18.9883 15.1641 17.6797L16.1602 3.75H16.875C17.2188 3.75 17.5 3.46875 17.5 3.125C17.5 2.78125 17.2188 2.5 16.875 2.5H16.25H14.9961H13.1602ZM14.9102 3.75L13.918 17.5898C13.8711 18.2422 13.3281 18.75 12.6719 18.75H4.82812C4.17188 18.75 3.62891 18.2422 3.58203 17.5898L2.59375 3.75H14.9062H14.9102Z" fill="#556DC2"/>
                            </svg>
                        </Button>
                    </div>
                ),
            };
            tableData.push(linkArray);
            return null;
        });
        return tableData;
    }
    getTrProps(state, rowInfo){
        return {}
    }
    getTrGroupProps(state, rowInfo){
        const { checked } = this.state;
        
        if (rowInfo && rowInfo.row) {
            if(checked.indexOf(rowInfo.original.id) !== -1){
                return {
                    className: 'selected_row'
                }
            }
            return {
                className: (rowInfo.original.id === 0 ? 'frozen_row' : '')
            }
        }else{
            return {}
        }
    }
    getNoDataProps(){
        return { style: { display: 'none' } };
    }
    getFormatedExpiry(link){
        if(!link.expire_at || link.expire_at.length <= 0){
            return (
                <>
                    <Button color="transparent" className="text-button icon" onClick={() => this.onChangeExpiryDate(true, link.id, link.expire_at)}>
                        Add Expiry
                        <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.1875 0C2.35938 0 2.5 0.140625 2.5 0.3125V1.25H6.25V0.3125C6.25 0.140625 6.39062 0 6.5625 0C6.73438 0 6.875 0.140625 6.875 0.3125V1.25H7.5C8.18945 1.25 8.75 1.81055 8.75 2.5V3.125V3.75V8.75C8.75 9.43945 8.18945 10 7.5 10H1.25C0.560547 10 0 9.43945 0 8.75V3.75V3.125V2.5C0 1.81055 0.560547 1.25 1.25 1.25H1.875V0.3125C1.875 0.140625 2.01562 0 2.1875 0ZM8.125 3.75H0.625V8.75C0.625 9.0957 0.904297 9.375 1.25 9.375H7.5C7.8457 9.375 8.125 9.0957 8.125 8.75V3.75ZM7.5 1.875H1.25C0.904297 1.875 0.625 2.1543 0.625 2.5V3.125H8.125V2.5C8.125 2.1543 7.8457 1.875 7.5 1.875ZM6.25 6.5625C6.25 6.73438 6.10938 6.875 5.9375 6.875H4.6875V8.125C4.6875 8.29688 4.54688 8.4375 4.375 8.4375C4.20312 8.4375 4.0625 8.29688 4.0625 8.125V6.875H2.8125C2.64062 6.875 2.5 6.73438 2.5 6.5625C2.5 6.39062 2.64062 6.25 2.8125 6.25H4.0625V5C4.0625 4.82812 4.20312 4.6875 4.375 4.6875C4.54688 4.6875 4.6875 4.82812 4.6875 5V6.25H5.9375C6.10938 6.25 6.25 6.39062 6.25 6.5625Z"/>
                        </svg>
                    </Button>
                </>
            );
        }
        return (
            <>
                { moment(link.expire_at).format("MM/DD/YYYY h:mm A") }
                <Button justIcon color="transparent" onClick={() => this.onChangeExpiryDate(true, link.id, link.expire_at)}>
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.08397 0.376953L6.13866 1.32227L8.67772 3.86133L9.62303 2.91602C10.1113 2.42773 10.1113 1.63672 9.62303 1.14844L8.8535 0.376953C8.36522 -0.111328 7.57421 -0.111328 7.08592 0.376953H7.08397ZM5.69725 1.76367L1.14452 6.31836C0.941393 6.52148 0.792955 6.77344 0.710924 7.04883L0.019518 9.39844C-0.0293102 9.56445 0.0156117 9.74219 0.136705 9.86328C0.257799 9.98438 0.435534 10.0293 0.599596 9.98242L2.94921 9.29102C3.2246 9.20899 3.47655 9.06055 3.67967 8.85742L8.23631 4.30273L5.69725 1.76367Z"/>
                    </svg>
                </Button>
            </>
        )
    }
    onEditModal(status = true, link){
        if(!status){
            this.loadExternalLinks();
            return;
        }
        this.setState({
            linkModal: status,
            linkName: status,
            editMode: status,
            linkDetail: {
                id: link.id,
                hash: link.hash,
                url: link.url,
                title: link.name,
                linkPassword: link.password,
                expire: link.expire_at,
                hideLink: Boolean(Number(link.hide_link)),
            },
        });
    }
    copyViewLink(link){
        helper.copyTextToClipboard(link.viewLink);
        this.setState({snackbarOpen: true});
    }
    closeSnackbar(){
        this.setState({snackbarOpen: false});
    }
    handleToggle(file) {
        if(this.loadingFolder){
            return;
        }
        // if(file.default){
        //     return;
        // }
        const { checked } = this.state;
        const currentIndex = checked.indexOf(file.id);
        const newChecked = [...checked];
    
        if (currentIndex === -1) {
            newChecked.push(file.id);
        } else {
            newChecked.splice(currentIndex, 1);
        }
    
        this.setState({
            checked: newChecked
        });
    }
    onDeleteModal(status = true, linkId = null){
        const { checked } = this.state;

        if(checked.length <= 0 && linkId === null && status === true){
            return;
        }
        this.setState({
            deleteModal: status,
            linkId: linkId
        });
    }
    onDeleteModalSuccess(){
        const { checked, response, linkId } = this.state;
        let id = [linkId];
        if(linkId === null){
            id = checked;
        }
        const source = axios.CancelToken.source();
        const requestData = {
            id: id,
        };
        Api.deleteExternalLinks(requestData, source).then(data => {
            this.loadExternalLinks();
        }).catch(err => {
            //Handle Error
        });

        let newResponse = [];
        response.map(link => {
            if(!checked.includes(link.id)){
                newResponse.push(link);
            }
            return null;
        });
        this.setState({
            response: newResponse,
            checked: [],
            deleteModal: false,
            deleteAll: false,
            checkedAll: false
        });
    }
    onTabChange(activeTab){
        if(activeTab === '0'){
            this.history.push('/user/links');
        }
    }
    onLinkDetailModal(status = false, linkDetail = null){
        const { checked } = this.state;
        if(checked.length <= 0 && linkDetail === null && status === true){
            return;
        }
        this.setState({
            linkDetailModal: status,
            linkDetail: linkDetail,
        });
    }
    onChangeExpiryDate(status = false, linkId = null, expire = null){
        const { checked } = this.state;
        if(checked.length <= 0 && linkId === null && status === true){
            return;
        }
        this.setState({
            changeExpiryDateModal: status,
            linkId: linkId,
            expire: expire
        });
    }
    onChangeExpiryDateSuccess(expire){
        let { linkId, checked } = this.state;
        let links = [linkId];
        if(linkId === null){
            links = checked;
        }
        const source = axios.CancelToken.source();
        this.setState({
            changingExpiryDate: true,
            cancelToken: source,
        });

        let orignalExpire = expire;
        const parsedDate = new Date(expire);

        if(expire !== '' && parsedDate !== "Invalid Date"){
            const day = parsedDate.getDate();
            const month = parsedDate.getMonth() + 1;
            const year = parsedDate.getFullYear();
            const hours = parsedDate.getHours();
            const mins = parsedDate.getMinutes();
            const secs = parsedDate.getSeconds();
            expire = day+"-"+month+"-"+year+" "+hours+":"+mins+":"+secs;
        }

        const requestData = {
            expire: expire,
            links: links
        };

        Api.changeExternalLinkExpiry(linkId, requestData, source).then(data => {
            if(this.state.linkDetail !== null){
                this.setState({
                    linkDetail: {
                        ...this.state.linkDetail,
                        expire_at:  orignalExpire
                    }
                })
            }

            this.loadExternalLinks();
        }).catch(err => {
            console.log(err);
        });
    }
    onChangeLinkPassword(status = false, linkId = null, linkPassword = null){
        const { checked } = this.state;
        if(checked.length <= 0 && linkId === null && status === true){
            return;
        }
        this.setState({
            changeLinkPasswordModal: status,
            linkId: linkId,
            linkPassword: linkPassword
        });
    }
    onChangeLinkPasswordSuccess(linkPassword){
        let { linkId, checked } = this.state;
        let links = [linkId];
        if(linkId === null){
            links = checked;
        }
        const source = axios.CancelToken.source();
        this.setState({
            changingLinkPassword: true,
            cancelToken: source,
        });

        const requestData = {
            linkPassword: linkPassword,
            links: links
        };

        Api.changeExternalLinkPassword(linkId, requestData, source).then(data => {
            if(this.state.linkDetail !== null){
                this.setState({
                    linkDetail: {
                        ...this.state.linkDetail,
                        link_password:  linkPassword
                    }
                })
            }

            this.loadExternalLinks();
        }).catch(err => {
            console.log(err);
        });
    }
    renderContent(){
        const { classes, sidebar } = this.props;
        const { linkModal, savingLinkModal, newLinkUploaded, errorMessage, linkName, externalLink, linkDetailModal,
            limit, loading, loadingMore, page, totalPages, deleteModal, checked, linkDetail, editMode, expire,
            changeExpiryDateModal, changingExpiryDate, changeLinkPasswordModal, changingLinkPassword, linkPassword } = this.state;
        const tableData = this.getTableData();
        return (
            <>
                {
                    sidebar ?
                        <div className={classes.controls}>
                            <GridItem className={classes.controlsTabs}>
                                <GridContainer className={classes.alignItems}>
                                    <GridItem xs={12} md={8}>
                                        <div className='sd-custom-tabs'>
                                            <div className={'sd-custom-tab'} onClick={() => this.onTabChange("0")}>
                                                <span>Internal Links</span>
                                            </div>
                                            <div className={'sd-custom-tab active'} onClick={() => this.onTabChange("1")}>
                                                <span>External Links</span>
                                            </div>
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        </div>
                    :
                        <></>
                }
                <GridItem className={classes.actionsContent}>
                    <Button color="custom" onClick={() => this.onLinkModal(true)} title="Add External Link">
                        <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.64285 1.375C9.64285 1.04844 9.35357 0.78125 9 0.78125C8.64643 0.78125 8.35714 1.04844 8.35714 1.375V7.90625H1.28571C0.932139 7.90625 0.642853 8.17344 0.642853 8.5C0.642853 8.82656 0.932139 9.09375 1.28571 9.09375H8.35714V15.625C8.35714 15.9516 8.64643 16.2188 9 16.2188C9.35357 16.2188 9.64285 15.9516 9.64285 15.625V9.09375H16.7143C17.0679 9.09375 17.3571 8.82656 17.3571 8.5C17.3571 8.17344 17.0679 7.90625 16.7143 7.90625H9.64285V1.375Z"/>
                        </svg> 
                        New External Link
                    </Button>
                    {
                        checked.length > 0 ?                                
                            <Button color="custom" onClick={() => this.onDeleteModal()} title="Delete">
                                <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.74308 1.125H10.2531C10.4618 1.125 10.6554 1.22344 10.7692 1.38867L11.3498 2.25H5.65022L6.2308 1.38867C6.34085 1.22344 6.53817 1.125 6.74688 1.125H6.74308ZM12.7842 2.25L11.8013 0.794531C11.4674 0.298828 10.883 0 10.2569 0H6.74308C6.11696 0 5.53259 0.298828 5.19866 0.794531L4.21585 2.25H2.43237H1.21429H0.607143C0.273214 2.25 0 2.50312 0 2.8125C0 3.12188 0.273214 3.375 0.607143 3.375H1.30156L2.2692 15.9117C2.35647 17.0895 3.41518 18 4.69018 18H12.3098C13.5848 18 14.6397 17.0895 14.7308 15.9117L15.6984 3.375H16.3929C16.7268 3.375 17 3.12188 17 2.8125C17 2.50312 16.7268 2.25 16.3929 2.25H15.7857H14.5676H12.7842ZM14.4842 3.375L13.5203 15.8309C13.4748 16.418 12.9473 16.875 12.3098 16.875H4.69018C4.05268 16.875 3.52522 16.418 3.47969 15.8309L2.51964 3.375H14.4804H14.4842Z"/>
                                </svg>
                                Delete
                            </Button>
                        :
                            <></>
                    }
                </GridItem>
                <GridItem>
                    <div className={classes.files}>
                        <div className={classes.filesContainer}>
                            {
                                loading ?
                                    <LoaderComponent color="custom" align="center" />
                                :
                                    <ReactTable
                                        columns={this.getColumns()}
                                        data={tableData}
                                        pageSizeOptions={[500]}
                                        defaultPageSize={limit}
                                        showPaginationTop={false}
                                        minRows={0}
                                        showPaginationBottom={false}
                                        className={"-striped -highlight "+classes.filesTable}
                                        getTrProps={(state, rowInfo) => this.getTrProps(state, rowInfo)}
                                        getTrGroupProps={(state, rowInfo) => this.getTrGroupProps(state, rowInfo)}
                                        getNoDataProps={() => this.getNoDataProps()}
                                    />
                            }
                            <div className={classes.loadMore}>
                                {
                                    tableData.length > 0 && loadingMore === false && page < totalPages ?
                                        <Button color="custom" id="contentViewMore" onClick={(e) => this.loadExternalLinks(true)}>
                                            <RefreshIcon className={classes.icons} />View More
                                        </Button>
                                        
                                    :
                                        loadingMore === true ?
                                            <LoaderComponent color="custom" align="center" />
                                        :
                                        <></>
                                }
                            </div>
                        </div>
                    </div>
                </GridItem>
                {
                    linkModal ?
                        <ExternalLinkModal
                            open={linkModal}
                            saving={savingLinkModal}
                            onClose={() => this.onLinkModal(false)} 
                            onSuccess={() => this.onLinkModalSuccess()}
                            onLinkName={(data, editMode) => this.onExternalLinkModalSuccess(data, editMode)}
                            loadingMessage="Please wait while we prepare your external link. It may take few minutes depending on size and number of file(s)."
                            successMessage={ newLinkUploaded === true ? 'Your external link is processing. Please check "Status" in the Link Manager in a few moments. Once "Ready", you may copy the link, and share anywhere.' : 'Link Ready for Sharing' }
                            errorMessage={errorMessage}
                            externalLink={externalLink}
                            linkName={linkName}
                            editMode={editMode}
                            linkDetail={linkDetail}
                            store={this.store}
                        />
                    :
                        <></>
                }
                {
                    deleteModal ?
                        <DeleteExternalLinkModal
                            deleteNote={true}
                            confirm={true}
                            deleteLinks={checked}
                            open={deleteModal}
                            onClose={() => this.onDeleteModal(false)}
                            onSuccess={() => this.onDeleteModalSuccess()}
                        />
                    :
                        <></>
                }
                {
                    linkDetailModal ?
                        <ExternalLinkDetailModal 
                            open={linkDetailModal} 
                            onClose={() => this.onLinkDetailModal(false)}
                            linkDetail={linkDetail}
                            onChangeLinkPassword={(status, linkId, linkPassword) => this.onChangeLinkPassword(status, linkId, linkPassword)}
                            onChangeExpiryDate={(status, linkId, expire) => this.onChangeExpiryDate(status, linkId, expire)}
                            onDeleteLink={(status, linkId) => this.onDeleteModal(status, linkId)}
                        />
                    :
                        <></>
                }
                {
                    changeExpiryDateModal ?
                        <ChangeExpiryDateModal 
                            open={changeExpiryDateModal} 
                            saving={changingExpiryDate} 
                            onClose={() => this.onChangeExpiryDate(false)} 
                            onSuccess={(expire) => this.onChangeExpiryDateSuccess(expire)}
                            confirmationMessage="Are you sure you want to change the expiry?"
                            expire={expire}
                        />
                    :
                        <></>
                }
                {
                    changeLinkPasswordModal ?
                        <ChangeLinkPasswordModal 
                            open={changeLinkPasswordModal} 
                            saving={changingLinkPassword} 
                            onClose={() => this.onChangeLinkPassword(false)} 
                            onSuccess={(linkPassword) => this.onChangeLinkPasswordSuccess(linkPassword)}
                            confirmationMessage="Are you sure you want to change the password?"
                            linkPassword={linkPassword}
                        />
                    :
                    <></>
                }
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    className={classes.snackbar}
                    open={this.state.snackbarOpen}
                    autoHideDuration={1000}
                    onClose={this.closeSnackbar}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">Your link has been copied</span>}
                    action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={this.closeSnackbar}
                    >
                        <CloseIcon />
                    </IconButton>,
                    ]}
                />
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </>
        )
    }
    render() {
        const { classes, sidebar } = this.props;
        const { authorized } = this.store.getState();
        if(!authorized){
            return (
                <div className={classes.main}>
                    <BackgroundSlider store={this.store} />
                    <Helmet>
                        <title>{process.env.REACT_APP_TITLE}</title>
                    </Helmet>
                </div>
            )
        }

        if(!sidebar){
            return this.renderContent();
        }
        
        return (
            <div className={classes.main}>
                <div className={classes.container}>
                    <div className={classes.content}>
                        {this.renderContent()}
                    </div>
                </div>
            </div>
        )
    }
}

ExternalLinks.defaultProps = {
    sidebar: true
}
ExternalLinks.propTypes = {
    classes: PropTypes.object,
    sidebar: PropTypes.bool
};

export default withStyles(linksPageStyle)(ExternalLinks);
