import React from 'react'
import moment from 'moment';
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import linkDetailModalStyle from "../../../assets/jss/user/linkDetailModalStyle.jsx";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { helper } from '../../../assets/js/utils/Element.js';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const LinkDetailModal = class extends React.Component {
    constructor(props){
        super(props);
        
        this.state = {
            viewPasswords: [],
            showAllRecipents: false,
            showAllAttachments: false,
        };
    }
    onViewPassword(link){
        const { viewPasswords } = this.state;
        const currentIndex = viewPasswords.indexOf(link.id);
        const newViewPasswords = [...viewPasswords];
    
        if (currentIndex === -1) {
            newViewPasswords.push(link.id);
        } else {
            newViewPasswords.splice(currentIndex, 1);
        }

        this.setState({
            viewPasswords: newViewPasswords
        });
    }
    getType(link){
        let label = "Direct Upload";
        if(link.upload_type.length <= 0){
            link.upload_type = link.type;
        }
        switch(link.upload_type){
            case 'web':
                label = 'Web';
                break;
            case 'my_docs':
                label = 'My Docs';
                break;
            case 'direct_upload':
                label = 'Direct Upload';
                break;
            case 'outlook':
                label = 'Outlook';
                break;
            case 'desktop':
                label = 'Desktop';
                break;
            case 'mobile':
                label = 'Mobile';
                break;
            // case 'share':
            //     label = 'Shared';
            //     break;
            // case 'manager_upload':
            //     label = 'File Manager Upload';
            //     break;
            default:
                if(link.path.indexOf("Web")){
                    label = "Web";
                }else if(link.path.indexOf("Outlook")){
                    label = "Outlook";
                }else if(link.path.indexOf("My Docs")){
                    label = "Desktop";
                }
                break;
        }
        return label;
    }
    getFormatedExpiry(link){
        if(!link.expire_at || link.expire_at.length <= 0){
            return (
                <>
                    <Button color="transparent" className="text-button icon" onClick={() => this.props.onChangeExpiryDate(true, link.id, link.expire_at)}>
                        Add Expiry
                        <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.1875 0C2.35938 0 2.5 0.140625 2.5 0.3125V1.25H6.25V0.3125C6.25 0.140625 6.39062 0 6.5625 0C6.73438 0 6.875 0.140625 6.875 0.3125V1.25H7.5C8.18945 1.25 8.75 1.81055 8.75 2.5V3.125V3.75V8.75C8.75 9.43945 8.18945 10 7.5 10H1.25C0.560547 10 0 9.43945 0 8.75V3.75V3.125V2.5C0 1.81055 0.560547 1.25 1.25 1.25H1.875V0.3125C1.875 0.140625 2.01562 0 2.1875 0ZM8.125 3.75H0.625V8.75C0.625 9.0957 0.904297 9.375 1.25 9.375H7.5C7.8457 9.375 8.125 9.0957 8.125 8.75V3.75ZM7.5 1.875H1.25C0.904297 1.875 0.625 2.1543 0.625 2.5V3.125H8.125V2.5C8.125 2.1543 7.8457 1.875 7.5 1.875ZM6.25 6.5625C6.25 6.73438 6.10938 6.875 5.9375 6.875H4.6875V8.125C4.6875 8.29688 4.54688 8.4375 4.375 8.4375C4.20312 8.4375 4.0625 8.29688 4.0625 8.125V6.875H2.8125C2.64062 6.875 2.5 6.73438 2.5 6.5625C2.5 6.39062 2.64062 6.25 2.8125 6.25H4.0625V5C4.0625 4.82812 4.20312 4.6875 4.375 4.6875C4.54688 4.6875 4.6875 4.82812 4.6875 5V6.25H5.9375C6.10938 6.25 6.25 6.39062 6.25 6.5625Z"/>
                        </svg>
                    </Button>
                </>
            );
        }
        return (
            <>
                { moment(link.expire_at).format("MM/DD/YYYY h:mm A") }
                <Button justIcon color="transparent" onClick={() => this.props.onChangeExpiryDate(true, link.id, link.expire_at)}>
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.08397 0.376953L6.13866 1.32227L8.67772 3.86133L9.62303 2.91602C10.1113 2.42773 10.1113 1.63672 9.62303 1.14844L8.8535 0.376953C8.36522 -0.111328 7.57421 -0.111328 7.08592 0.376953H7.08397ZM5.69725 1.76367L1.14452 6.31836C0.941393 6.52148 0.792955 6.77344 0.710924 7.04883L0.019518 9.39844C-0.0293102 9.56445 0.0156117 9.74219 0.136705 9.86328C0.257799 9.98438 0.435534 10.0293 0.599596 9.98242L2.94921 9.29102C3.2246 9.20899 3.47655 9.06055 3.67967 8.85742L8.23631 4.30273L5.69725 1.76367Z"/>
                    </svg>
                </Button>
            </>
        )
    }
    getLinkPassword(link){
        const { viewPasswords } = this.state;

        return (
            <>
                {
                    link.link_password ?
                        <div className="sd-password-field">
                            <div>
                                { 
                                    viewPasswords.includes(link.id) ?
                                        link.link_password
                                    :
                                        <div className="sd-passwords" dangerouslySetInnerHTML={{__html: this.convertStringToPass(link.link_password)}}></div>
                                }
                            </div>
                            <Button justIcon className="eye-icon" color="transparent" onClick={() => this.onViewPassword(link)} title={"View Password"}>
                                { 
                                    viewPasswords.includes(link.id) ?
                                        <VisibilityOffIcon />
                                    :
                                        <VisibilityIcon />
                                }
                            </Button>
                            <Button justIcon color="transparent" onClick={() => this.props.onChangeLinkPassword(true, link.id, link.link_password)} title={"Link Password"}>
                                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.08397 0.376953L6.13866 1.32227L8.67772 3.86133L9.62303 2.91602C10.1113 2.42773 10.1113 1.63672 9.62303 1.14844L8.8535 0.376953C8.36522 -0.111328 7.57421 -0.111328 7.08592 0.376953H7.08397ZM5.69725 1.76367L1.14452 6.31836C0.941393 6.52148 0.792955 6.77344 0.710924 7.04883L0.019518 9.39844C-0.0293102 9.56445 0.0156117 9.74219 0.136705 9.86328C0.257799 9.98438 0.435534 10.0293 0.599596 9.98242L2.94921 9.29102C3.2246 9.20899 3.47655 9.06055 3.67967 8.85742L8.23631 4.30273L5.69725 1.76367Z"/>
                                </svg>
                            </Button>
                        </div>
                    :
                        <Button className="text-button icon" color="transparent" onClick={() => this.props.onChangeLinkPassword(true, link.id, link.link_password)} title={"Link Password"}>
                            Add Password
                            <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.5 2.5V3.75H6.25V2.5C6.25 1.46484 5.41016 0.625 4.375 0.625C3.33984 0.625 2.5 1.46484 2.5 2.5ZM1.875 3.75V2.5C1.875 1.11914 2.99414 0 4.375 0C5.75586 0 6.875 1.11914 6.875 2.5V3.75H7.1875C8.05078 3.75 8.75 4.44922 8.75 5.3125V8.4375C8.75 9.30078 8.05078 10 7.1875 10H1.5625C0.699219 10 0 9.30078 0 8.4375V5.3125C0 4.44922 0.699219 3.75 1.5625 3.75H1.875ZM0.625 5.3125V8.4375C0.625 8.95508 1.04492 9.375 1.5625 9.375H7.1875C7.70508 9.375 8.125 8.95508 8.125 8.4375V5.3125C8.125 4.79492 7.70508 4.375 7.1875 4.375H1.5625C1.04492 4.375 0.625 4.79492 0.625 5.3125Z"/>
                            </svg>
                        </Button>
                }
            </>
        );
    }
    getFormatedDownloadDate(date){
        if(!date || date.length <= 0){
            return "";
        }
        return moment(date).format("MM/DD/YYYY h:mm A");
    }
    convertStringToPass(password){
        let string = "";
        for (let i = 0; i < password.length; i++) {
            string +="<span></span>";
        }

        return string;
    }
    toggleRecipients(){
        this.setState({
            showAllRecipents: !this.state.showAllRecipents
        });
    };
    getRecipientsData(recipients){
        const { showAllRecipents } = this.state;
        const displayedRecipients = showAllRecipents ? recipients : recipients.slice(0, 2);

        const remainingCount = recipients.length - 2;

        return (
            <div>
                {
                    displayedRecipients.map((email, index) => (
                        <span key={index}>
                            <Button justIcon color="transparent">
                                <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.59375 0C0.713867 0 0 0.713867 0 1.59375C0 2.09512 0.235742 2.5666 0.6375 2.86875L7.8625 8.2875C8.24102 8.56973 8.75898 8.56973 9.1375 8.2875L16.3625 2.86875C16.7643 2.5666 17 2.09512 17 1.59375C17 0.713867 16.2861 0 15.4062 0H1.59375ZM0 3.71875V10.625C0 11.7971 0.95293 12.75 2.125 12.75H14.875C16.0471 12.75 17 11.7971 17 10.625V3.71875L9.775 9.1375C9.01797 9.70527 7.98203 9.70527 7.225 9.1375L0 3.71875Z"/>
                                </svg>
                            </Button>
                            {email}
                        </span>
                    ))
                }
                {
                    !showAllRecipents && remainingCount > 0 ?
                        <span className='others' onClick={() => this.toggleRecipients()}>
                            +{remainingCount} others
                        </span>
                    :
                        <></>
                }
            </div>
        )
    }
    toggleAttachments(){
        this.setState({
            showAllAttachments: !this.state.showAllAttachments
        });
    };
    getAttachmentsData(attachments){
        const { classes  } = this.props;
        const { showAllAttachments } = this.state;
        const displayedAttachments = showAllAttachments ? attachments : attachments.slice(0, 2);

        const remainingCount = attachments.length - 2;

        return (
            <div>
                {
                    displayedAttachments.map((file, index) => (
                        <span key={index}>
                            { helper.getFileIcon(file, classes, true) }
                            {file.name}
                        </span>
                    ))
                }
                {
                    !showAllAttachments && remainingCount > 0 ?
                        <span className='others' onClick={() => this.toggleAttachments()}>
                            +{remainingCount} others
                        </span>
                    :
                        <></>
                }
            </div>
        )
    }
    getData(linkDetail){
        let link = linkDetail;

        let fileArray = {
            id: link.id,
            type: this.getType(link),
            account: link.account,
            server: "Sendlinx",
            sender_email: (
                <div title={ link.sender_email }>
                    { link.sender_email }
                </div>
            ),
            message: (
                <div dangerouslySetInnerHTML={{__html: link.message}}></div>
            ),
            subject: link.subject,
            created_at: link.created_at,
            expire_at: (
                <div className={(link.status === 'expire' ? 'expire-link' : 'active-link')}>
                    {this.getFormatedExpiry(link)}
                </div>
            ),
            last_downloaded: this.getFormatedDownloadDate(link.download_date),
            status: (
                link.state === 'uploaded' || link.state === 'processing' ?
                    <div className='orange'>
                        Processing ...
                    </div>
                :
                    <div>
                        Ready
                    </div>
            ),
            path: (
                <div title={ link.path }>
                    { link.path }
                </div>
            ),
            title: link.title,
            link_password: this.getLinkPassword(link),
            webLink: link.webLink,
            shared_via: (
                link.shared_via && link.shared_via !== '' ?
                    link.shared_via
                :
                    "N/A"
            ),
            recipients: this.getRecipientsData(link.recipients),
            attachments: this.getAttachmentsData(link.files)
        };
            
        return fileArray;
    }
    render() {
        const { classes, linkDetail } = this.props;
        let link = this.getData(linkDetail);

        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="attachments-modal-slide-title"
                aria-describedby="attachments-modal-slide-description"
            >
                <DialogTitle
                    id="attachments-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                >
                    <Button
                        simple
                        className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.props.onClose()}
                    >
                        <Close className={classes.modalClose} />
                    </Button>
                    <h4 className={classes.modalTitle}>{this.props.title}</h4> 
                </DialogTitle>
                <DialogContent id="link-detail-modal-slide-description" className={classes.modalBody}>
                    {
                        linkDetail ?
                            <>
                                <ul>
                                    <li><span>Title</span>{link.title}</li>
                                    <li><span>Url</span>{link.webLink}</li>
                                    <li className='expire'><span>Expiry Date</span>{link.expire_at}</li>
                                    <li className='password'><span>Password</span>{link.link_password}</li>
                                    <li><span>Status</span>{link.status}</li>
                                    <li><span>Type</span>{link.type}</li>
                                    <li><span>Shared Via</span>{link.shared_via}</li>
                                    <li><span>Path</span>{link.path}</li>
                                    <li><span>Sender</span>{link.account}</li>
                                    <li><span>Date Created</span>{link.created_at}</li>
                                    <li><span>Subject</span>{link.subject}</li>
                                    <li><span>Last Downloaded</span>{link.last_downloaded}</li>
                                    <li className='list'><span>
                                        Attachment(s) 
                                        <Button justIcon color="transparent">
                                            <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.15 9C1.41094 9 0 7.70424 0 6.10714C0 4.84554 0.879375 3.77277 2.10437 3.37701C2.10219 3.32277 2.1 3.26853 2.1 3.21429C2.1 1.43839 3.66625 0 5.6 0C6.89719 0 8.02812 0.646875 8.63406 1.61116C8.96656 1.40625 9.36906 1.28571 9.8 1.28571C10.9594 1.28571 11.9 2.14955 11.9 3.21429C11.9 3.45937 11.8497 3.69241 11.76 3.90937C13.0375 4.14643 14 5.18504 14 6.42857C14 7.84888 12.7466 9 11.2 9H3.15ZM4.87812 5.64509L6.62812 7.25223C6.83375 7.44107 7.16625 7.44107 7.36969 7.25223L9.11969 5.64509C9.32531 5.45625 9.32531 5.15089 9.11969 4.96406C8.91406 4.77723 8.58156 4.77522 8.37813 4.96406L7.525 5.74754V3.05357C7.525 2.78638 7.29094 2.57143 7 2.57143C6.70906 2.57143 6.475 2.78638 6.475 3.05357V5.74754L5.62187 4.96406C5.41625 4.77522 5.08375 4.77522 4.88031 4.96406C4.67688 5.1529 4.67469 5.45826 4.88031 5.64509H4.87812Z"/>
                                            </svg>
                                        </Button>
                                        </span>
                                        {link.attachments}
                                    </li>
                                    <li className='list'><span>Recipent(s)</span>{link.recipients}</li>
                                </ul>
                            </>
                        :
                            <></>
                    }
                </DialogContent>
                <DialogActions className={classes.modalFooter}>
                    <div>
                        <Button color="border" onClick={() => this.props.onChangeLink(true, link.id)}>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.71094 7.22656C2.50781 3.94141 5.46875 1.5 9 1.5C11.7773 1.5 14.1992 3.00781 15.4961 5.25H13.375C13.0312 5.25 12.75 5.53125 12.75 5.875C12.75 6.21875 13.0312 6.5 13.375 6.5H16.5273H17.125C17.4688 6.5 17.75 6.21875 17.75 5.875V2.125C17.75 1.78125 17.4688 1.5 17.125 1.5C16.7812 1.5 16.5 1.78125 16.5 2.125V4.49219C14.9687 1.94922 12.1836 0.25 9 0.25C4.84375 0.25 1.36328 3.14844 0.472656 7.03125C0.386719 7.40625 0.683594 7.75 1.07031 7.75C1.37891 7.75 1.64062 7.52734 1.71484 7.22656H1.71094ZM16.2891 10.7773C15.4922 14.0586 12.5312 16.5 9 16.5C6.22266 16.5 3.80078 14.9922 2.50391 12.75H4.63281C4.97656 12.75 5.25781 12.4688 5.25781 12.125C5.25781 11.7812 4.97656 11.5 4.63281 11.5H1.47266H0.882812C0.539062 11.5 0.257812 11.7812 0.257812 12.125V15.875C0.257812 16.2188 0.539062 16.5 0.882812 16.5C1.22656 16.5 1.50781 16.2188 1.50781 15.875V13.5234C3.03906 16.0547 5.82422 17.75 9 17.75C13.1562 17.75 16.6367 14.8516 17.5273 10.9688C17.6133 10.5938 17.3164 10.25 16.9297 10.25C16.6211 10.25 16.3594 10.4727 16.2852 10.7734L16.2891 10.7773Z"/>
                            </svg>
                            Regenerate Link
                        </Button>
                        <Button color="border" onClick={() => this.props.onDeleteLink(true, link.id)}>
                            <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.94141 1.25H10.5547C10.7695 1.25 10.9688 1.35938 11.0859 1.54297L11.6836 2.5H5.81641L6.41406 1.54297C6.52734 1.35938 6.73047 1.25 6.94531 1.25H6.94141ZM13.1602 2.5L12.1484 0.882812C11.8047 0.332031 11.2031 0 10.5586 0H6.94141C6.29688 0 5.69531 0.332031 5.35156 0.882812L4.33984 2.5H2.50391H1.25H0.625C0.28125 2.5 0 2.78125 0 3.125C0 3.46875 0.28125 3.75 0.625 3.75H1.33984L2.33594 17.6797C2.42578 18.9883 3.51562 20 4.82812 20H12.6719C13.9844 20 15.0703 18.9883 15.1641 17.6797L16.1602 3.75H16.875C17.2188 3.75 17.5 3.46875 17.5 3.125C17.5 2.78125 17.2188 2.5 16.875 2.5H16.25H14.9961H13.1602ZM14.9102 3.75L13.918 17.5898C13.8711 18.2422 13.3281 18.75 12.6719 18.75H4.82812C4.17188 18.75 3.62891 18.2422 3.58203 17.5898L2.59375 3.75H14.9062H14.9102Z"/>
                            </svg>
                            Delete
                        </Button>
                    </div>
                    <Button color="custom" onClick={() => this.props.onClose()}>
                        Done
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

LinkDetailModal.defaultProps = {
    open: false,
    title: "Link Details"
}
LinkDetailModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    title: PropTypes.string
}
export default withStyles(linkDetailModalStyle)(LinkDetailModal);