import Config from '../../../../../Config';
import axios from 'axios';

const ApiHelper = {
    getDriveFiles(){
        return new Promise(function(resolve, reject){
            let oneDriveToken = localStorage.getItem("one_drive_token");
            let oneDriveConnect = localStorage.getItem("one_drive_connected");
            let redirectUrlOneDrive = Config.getRedirectUrlOneDrive();
            let clientIdOneDrive = Config.getClientIdOneDrive();
            const scope = Config.getOnedriveScope();
            if(oneDriveToken !=null){
                const headers = {
                    Authorization: `Bearer `+oneDriveToken};
                axios({
                    url: 'https://graph.microsoft.com/v1.0/me/drive/root/children',
                    method: "GET",
                    headers: headers,
                }).then(res => {
                    if(res.data){
                        resolve(res.data);
                    }else{
                        reject("Invalid response from api.");
                    }
                }).catch(err => {
                    if (err.response && err.response.data.error && err.response.data.error.code === "notAllowed") {
                        reject(err.response.data.error.code);
                        return;
                    }
                    if (oneDriveConnect) {
                        localStorage.setItem("one_drive_auth_flow", 1);
                        const URL ='https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id='+clientIdOneDrive+'&scope='+scope+'&response_type=token&redirect_uri='+redirectUrlOneDrive+'&prompt=select_account';
                        window.location.replace(URL);
                    }
                    reject(err);
                });
             }else{
                if (oneDriveConnect) {
                    localStorage.setItem("one_drive_auth_flow", 1);
                    const URL ='https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id='+clientIdOneDrive+'&scope='+scope+'&response_type=token&redirect_uri='+redirectUrlOneDrive+'&prompt=select_account';
                    window.location.replace(URL);
                }
                reject("add Account");
             }
        });
    },
    getOneDriveNestedFiles(Id,folderId){
        return new Promise(function(resolve, reject){
            let oneDriveToken = localStorage.getItem("one_drive_token");
            let oneDriveConnect = localStorage.getItem("one_drive_connected");
            let redirectUrlOneDrive = Config.getRedirectUrlOneDrive();
            let clientIdOneDrive = Config.getClientIdOneDrive();
            const scope = Config.getOnedriveScope();
             if(oneDriveToken !=null){
                const headers = {
                    Authorization: `Bearer `+oneDriveToken};
                axios({
                    url: `https://graph.microsoft.com/v1.0/drives/${folderId}/items/${Id}/children`,
                    method: "GET",
                    headers: headers,
                }).then(res => {
                    if(res.data){
                        resolve(res.data);
                    }else{
                        reject("Invalid response from api.");
                    }
                }).catch(err => {
                    if (oneDriveConnect) {
                        localStorage.setItem("one_drive_auth_flow", 1);
                        const URL ='https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id='+clientIdOneDrive+'&scope='+scope+'&response_type=token&redirect_uri='+redirectUrlOneDrive+'&prompt=select_account';
                        window.location.replace(URL);
                    }
                    reject(err);
                });
             }else{
                console.log('Login  from api.')
                reject("Login.");
             }
        });
    },
    getOneDriveEmail(){
        return new Promise(function(resolve, reject){
            let oneDriveToken = localStorage.getItem("one_drive_token");
            let oneDriveConnect = localStorage.getItem("one_drive_connected");
            let redirectUrlOneDrive = Config.getRedirectUrlOneDrive();
            let clientIdOneDrive = Config.getClientIdOneDrive();
            const scope = Config.getOnedriveScope();
             if(oneDriveToken !=null){
                const headers = {
                    Authorization: `Bearer `+oneDriveToken
                };
                axios({
                    url: `https://graph.microsoft.com/v1.0/me`,
                    method: "GET",
                    headers: headers,
                }).then(res => {
                    if(res.data){
                        resolve(res.data);
                    }else{
                        reject("Invalid response from api.");
                    }
                }).catch(err => {
                    if (oneDriveConnect) {
                        localStorage.setItem("one_drive_auth_flow", 1);
                        const URL ='https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id='+clientIdOneDrive+'&scope='+scope+'&response_type=token&redirect_uri='+redirectUrlOneDrive+'&prompt=select_account';
                        window.location.replace(URL);
                    }
                    reject(err);
                });
             }else{
                reject("Login.");
             }
        });
    },
    getOneDriveFilesStatus(Id,folderId){
        return new Promise(function(resolve, reject){
            let oneDriveToken = localStorage.getItem("one_drive_token");
            let oneDriveConnect = localStorage.getItem("one_drive_connected");
            let redirectUrlOneDrive = Config.getRedirectUrlOneDrive();
            let clientIdOneDrive = Config.getClientIdOneDrive();
            const scope = Config.getOnedriveScope();
             if(oneDriveToken !=null){
                const headers = {
                    Authorization: `Bearer `+oneDriveToken};
                    axios({
                        url: `https://graph.microsoft.com/v1.0/drives/${folderId}/items/${Id}`,
                        method: "GET",
                        headers: headers,
                    }).then(res => {
                        if(res.data){
                            resolve(res.data);
                        }else{
                            reject("Invalid response from api.");
                        }
                    }).catch(err => {
                        if (err === "Error: Request failed with status code 404") {
                            resolve("data not founded");
                    }else{
                        if (oneDriveConnect) {
                            localStorage.setItem("one_drive_auth_flow", 1);
                            const URL ='https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id='+clientIdOneDrive+'&scope='+scope+'&response_type=token&redirect_uri='+redirectUrlOneDrive+'&prompt=select_account';
                            window.location.replace(URL);
                        }
                        reject(err);
                    }
                });
             }else{
                console.log('Login  from api.')
                reject("Login.");
             }
        });
    },
    getDataAllFolderOneDrive(parentId, id){
        return new Promise(function(resolve, reject){
            let oneDriveToken = localStorage.getItem("one_drive_token");
            let oneDriveConnect = localStorage.getItem("one_drive_connected");
            let redirectUrlOneDrive = Config.getRedirectUrlOneDrive();
            let clientIdOneDrive = Config.getClientIdOneDrive();
            const scope = Config.getOnedriveScope();
             if(oneDriveToken !=null){
                const headers = {
                    Authorization: `Bearer `+oneDriveToken};
                axios({
                    // url: `https://graph.microsoft.com/v1.0/drive/items/${id}/children`,
                    url: `https://graph.microsoft.com/v1.0/drives/${parentId}/items/${id}/children`,
                    method: "GET",
                    headers: headers,
                }).then(res => {
                    if(res.data){
                        resolve(res.data);
                    }else{
                        reject("Invalid response from api.");
                    }
                }).catch(err => {
                    console.log('err  from api.',err)
                    if (oneDriveConnect) {
                        localStorage.setItem("one_drive_auth_flow", 1);
                        const URL ='https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id='+clientIdOneDrive+'&scope='+scope+'&response_type=token&redirect_uri='+redirectUrlOneDrive+'&prompt=select_account';
                        window.location.replace(URL);
                    }
                    reject(err);
                });
             }else{
                console.log('Login  from api.')
                reject("Login.");
             }
        });
    },
    checkFolderOneDriveModifi(folderId){
        return new Promise(function(resolve, reject){
            let oneDriveToken = localStorage.getItem("one_drive_token");
            let oneDriveConnect = localStorage.getItem("one_drive_connected");
            let redirectUrlOneDrive = Config.getRedirectUrlOneDrive();
            let clientIdOneDrive = Config.getClientIdOneDrive();
            const scope = Config.getOnedriveScope();
             if(oneDriveToken !=null){
                const headers = {
                    Authorization: `Bearer `+oneDriveToken};
                axios({
                    url: `https://graph.microsoft.com/v1.0/drive/items/${folderId}`,
                    method: "GET",
                    headers: headers,
                }).then(res => {
                    if(res.data){
                        resolve(res.data);
                    }else{
                        reject("Invalid response from api.");
                    }
                }).catch(err => {
                    console.log('err  from api.',err)
                    if (oneDriveConnect) {
                        localStorage.setItem("one_drive_auth_flow", 1);
                        const URL ='https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id='+clientIdOneDrive+'&scope='+scope+'&response_type=token&redirect_uri='+redirectUrlOneDrive+'&prompt=select_account';
                        window.location.replace(URL);
                    }
                    reject(err);
                });
             }else{
                console.log('Login  from api.')
                reject("Login.");
             }
        });
    },
}
export default ApiHelper;
export { ApiHelper };