import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import LoaderComponent from "../Loader";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import confirmationModalStyle from "../../../assets/jss/user/confirmationModalStyle.jsx";
import { helper } from '../../../assets/js/utils/Element.js';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const ChangeExtensionsModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;

        this.state = {
            extensions: {
                adobesign: {
                    status: false
                },
                docusign: {
                    status: false
                },
                ms_office: {
                    status: false
                },
                google_drive: {
                    status: false
                },
                one_drive: {
                    status: false
                },
                sharepoint: {
                    status: false
                }
            }
        };
    }
    handleCheckbox(e, name){
        this.setState({
            extensions: {
                ...this.state.extensions,
                [name]: {
                    'status': e.target.checked ? 1 : 0
                }
            }
        });
    }
    render() {
        const { classes, saving, confirmationMessage } = this.props;
        const { extensions } = this.state;
        const { user } = this.store.getState();

        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="confirmationModal-modal-slide-title"
                aria-describedby="confirmationModal-modal-slide-description"
            >
                <DialogTitle
                    id="confirmationModal-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                >
                    <Button
                        simple
                        className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.props.onClose()}
                    >
                        <Close className={classes.modalClose} />
                    </Button>
                    {
                        saving ?
                            <></>
                        :
                        <h4 className={classes.modalTitle}>{confirmationMessage}</h4> 
                    }
                </DialogTitle>
                <DialogContent
                    id="confirmationModal-modal-slide-description"
                    className={classes.modalBody}
                    >
                        {
                            saving ?
                                <LoaderComponent color="custom" align="center" />
                            :
                                <>  
                                    {
                                        helper.isExtensionAllowed(user, 'adobesign') ?
                                            <div className={classes.alignLeft}>
                                                <FormControlLabel
                                                    classes={{label: classes.label}}
                                                    control={
                                                        <Switch
                                                            checked={(extensions.adobesign.status ? true : false)}
                                                            onChange={(e) => this.handleCheckbox(e, 'adobesign')}
                                                            value="adobesign"
                                                            classes={{
                                                                switchBase: classes.switchBase,
                                                                checked: classes.switchChecked,
                                                                thumb: classes.switchIcon,
                                                                track: classes.switchBar
                                                            }}
                                                        />
                                                    }
                                                    label={helper.getExtensionsData(user, 'adobesign', 'extension')}
                                                />
                                            </div>
                                        :
                                            <></>
                                    }
                                    {
                                        helper.isExtensionAllowed(user, 'docusign') ?
                                            <div className={classes.alignLeft}>
                                                <FormControlLabel
                                                    classes={{label: classes.label}}
                                                    control={
                                                        <Switch
                                                            checked={(extensions.docusign.status ? true : false)}
                                                            onChange={(e) => this.handleCheckbox(e, 'docusign')}
                                                            value="docusign"
                                                            classes={{
                                                                switchBase: classes.switchBase,
                                                                checked: classes.switchChecked,
                                                                thumb: classes.switchIcon,
                                                                track: classes.switchBar
                                                            }}
                                                        />
                                                    }
                                                    label={helper.getExtensionsData(user, 'docusign', 'extension')}
                                                />
                                            </div>
                                        :
                                            <></>
                                    }
                                    {
                                        helper.isExtensionAllowed(user, 'ms_office') ?
                                            <div className={classes.alignLeft}>
                                                <FormControlLabel
                                                    classes={{label: classes.label}}
                                                    control={
                                                        <Switch
                                                            checked={(extensions.ms_office.status ? true : false)}
                                                            onChange={(e) => this.handleCheckbox(e, 'ms_office')}
                                                            value="ms_office"
                                                            classes={{
                                                                switchBase: classes.switchBase,
                                                                checked: classes.switchChecked,
                                                                thumb: classes.switchIcon,
                                                                track: classes.switchBar
                                                            }}
                                                        />
                                                    }
                                                    label={helper.getExtensionsData(user, 'ms_office', 'extension')}
                                                />
                                            </div>
                                        :
                                            <></>
                                    }
                                    {
                                        helper.isExtensionAllowed(user, 'google_drive') ?
                                            <div className={classes.alignLeft}>
                                                <FormControlLabel
                                                    classes={{label: classes.label}}
                                                    control={
                                                        <Switch
                                                            checked={(extensions.google_drive.status ? true : false)}
                                                            onChange={(e) => this.handleCheckbox(e, 'google_drive')}
                                                            value="google_drive"
                                                            classes={{
                                                                switchBase: classes.switchBase,
                                                                checked: classes.switchChecked,
                                                                thumb: classes.switchIcon,
                                                                track: classes.switchBar
                                                            }}
                                                        />
                                                    }
                                                    label={helper.getExtensionsData(user, 'google_drive', 'extension')}
                                                />
                                            </div>
                                        :
                                            <></>
                                    }
                                    {
                                        helper.isExtensionAllowed(user, 'one_drive') ?
                                            <div className={classes.alignLeft}>
                                                <FormControlLabel
                                                    classes={{label: classes.label}}
                                                    control={
                                                        <Switch
                                                            checked={(extensions.one_drive.status ? true : false)}
                                                            onChange={(e) => this.handleCheckbox(e, 'one_drive')}
                                                            value="one_drive"
                                                            classes={{
                                                                switchBase: classes.switchBase,
                                                                checked: classes.switchChecked,
                                                                thumb: classes.switchIcon,
                                                                track: classes.switchBar
                                                            }}
                                                        />
                                                    }
                                                    label={helper.getExtensionsData(user, 'one_drive', 'extension')}
                                                />
                                            </div>
                                        :
                                            <></>
                                    }
                                    {
                                        helper.isExtensionAllowed(user, 'sharepoint') ?
                                            <div className={classes.alignLeft}>
                                                <FormControlLabel
                                                    classes={{label: classes.label}}
                                                    control={
                                                        <Switch
                                                            checked={(extensions.sharepoint.status ? true : false)}
                                                            onChange={(e) => this.handleCheckbox(e, 'sharepoint')}
                                                            value="sharepoint"
                                                            classes={{
                                                                switchBase: classes.switchBase,
                                                                checked: classes.switchChecked,
                                                                thumb: classes.switchIcon,
                                                                track: classes.switchBar
                                                            }}
                                                        />
                                                    }
                                                    label={helper.getExtensionsData(user, 'sharepoint', 'extension')}
                                                />
                                            </div>
                                        :
                                            <></>
                                    }
                                </>
                        }
                </DialogContent>
                <DialogActions className={classes.modalFooter}>
                    {
                        saving ?
                            <></>
                        :
                            <Button color="custom" className={classes.changeButton} onClick={() => this.props.onSuccess(extensions)}>
                                Change
                            </Button>
                    }
                </DialogActions>
            </Dialog>
        )
    }
}

ChangeExtensionsModal.defaultProps = {
    open: false,
    confirmationMessage: "",
    saving: false
}
ChangeExtensionsModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    confirmationMessage: PropTypes.string,
    saving: PropTypes.bool
}
export default withStyles(confirmationModalStyle)(ChangeExtensionsModal);