import React from 'react';
import axios from 'axios';
import Api from "../../../assets/js/utils/Api";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Wizard from '../Wizard/Wizard.jsx';
import Account from "./SignSteps/Account.jsx";
import Documents from './SignSteps/Documents.jsx';
import Recipients from "./SignSteps/Recipients.jsx";
import Email from "./SignSteps/Email.jsx";
import LoaderComponent from "../Loader";
import Close from "@material-ui/icons/Close";

import signModalStyle from "../../../assets/jss/docusign/signModalStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const SignModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;

        this.state = {
            cancelToken: null,
            loading: false,
            userinfo: null,
            saving: false,
            successMessage: ''
        };
    }
    componentDidMount(){
        this.checkAdobesignUser();
    }
    onFinish(allStates){
        let requestData = {
            recipients: allStates.recipients.recipients,
            subject: allStates.message.subject,
            message: allStates.message.message,
        };
        let files = [];
        allStates.documents.files.map((file) => {
            files.push(file.id);
            return null;
        });
        requestData['files'] = files;

        const source = axios.CancelToken.source();
        this.setState({cancelToken: source, saving: true});
        Api.signAdobesign(requestData, source).then(data => {
            this.setState({
                saving: false,
                successMessage: data.message
            });
            // this.props.onSignSuccess(data);
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.setState({cancelToken: null, saving: false});
            }
        });
    }
    checkAdobesignUser(){
        const source = axios.CancelToken.source();
        this.setState({cancelToken: source, loading: true});
        Api.getAdobesignUserInfo({}, source).then(data => {
            this.setState({cancelToken: null, loading: false, userinfo: data});

            let name = this.getName(data);
            localStorage.setItem('adobesign_name', name);

            localStorage.removeItem("sendlinx_home_state");
            localStorage.removeItem("sendlinx_extension_state");
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.setState({cancelToken: null, loading: false});
            }

            localStorage.removeItem('adobesign_name');
            localStorage.removeItem("sendlinx_home_state");
            localStorage.removeItem("sendlinx_extension_state");
        });
    }
    getName(userinfo){
        let name = "";
        if(userinfo.hasOwnProperty('firstName')){
            name = userinfo.firstName;
        }
        if(userinfo.hasOwnProperty('lastName')){
            name += " "+userinfo.lastName;
        }
        name += " ("+userinfo.email+")";

        return name;
    }
    getSteps(){
        const { files } = this.props;
        const { userinfo } = this.state;

        let steps = [
            { 
                stepName: "Account",
                stepComponent: Account,
                stepId: "account",
                props: {
                    userinfo: userinfo,
                    onAdobesignConnect: this.props.onAdobesignConnect
                }
            },
            {
                stepName: "Documents",
                stepComponent: Documents,
                stepId: "documents",
                props: {
                    files: files
                }
            },
            {
                stepName: "Recipients",
                stepComponent: Recipients,
                stepId: "recipients",
                props: {
                    files: files,
                    store: this.store,
                    userinfo: userinfo,
                }
            },
            {
                stepName: "Message",
                stepComponent: Email,
                stepId: "message",
                props: {
                    files: files
                }
            }
        ];

        if(this.props.type === 'extension'){
            steps = [
                { 
                    stepName: "Account",
                    stepComponent: Account,
                    stepId: "account",
                    props: {
                        userinfo: userinfo,
                        onAdobesignConnect: this.props.onAdobesignConnect
                    }
                }
            ];
        }

        return steps;
    }
    render() {
        const { classes, type } = this.props;
        const { loading, saving, successMessage } = this.state;
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="signDocument-modal-slide-title"
                aria-describedby="signDocument-modal-slide-description"
            >   
                <DialogTitle
                    id="newFolderModal-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                        <Button
                            simple
                            className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                            key="close"
                            aria-label="Close"
                            onClick={() => this.props.onClose()}
                        >
                            {" "}
                            <Close className={classes.modalClose} />
                        </Button>
                </DialogTitle>
                <DialogContent
                    id="signDocument-modal-slide-description"
                    className={classes.modalBody}
                >
                    {
                        saving ?
                            <LoaderComponent color="custom" align="center" />
                        :
                            null
                    }
                    {
                        loading ?
                            <LoaderComponent color="custom" align="center" />
                        : successMessage !== '' ?
                            <p className='successMessage'>{successMessage}</p>
                        :
                            <Wizard
                                validate
                                steps={this.getSteps()}
                                title="AdobeSign eSignature for Sendlinx"
                                finishButtonText={type === 'extension' ? "Close" : "Sign"}
                                finishButtonClick={type === 'extension' ? () => this.props.onClose() : allStates => this.onFinish(allStates)}
                                color="custom"
                                className={classes.wizard+" "+(saving ? classes.savingWizard : "")}
                            />
                    }
                </DialogContent>
                {
                    successMessage !== '' ? 
                        <DialogActions className={classes.modalFooter}>
                            <Button color="custom" className={classes.changeButton} onClick={() => this.props.onSignSuccess('')}>
                                Close
                            </Button>
                        </DialogActions>
                    :
                        <></>
                }
            </Dialog>
        )
    }
}

SignModal.defaultProps = {
    open: false,
    loadingMessage: "",
    successMessage: "",
    saving: false,
    errorMessage: null,
    files: [],
    type: ''
}
SignModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    loadingMessage: PropTypes.string,
    saving: PropTypes.bool,
    errorMessage: PropTypes.string,
    files: PropTypes.array,
    onAdobesignConnect: PropTypes.func,
    type: PropTypes.string,
}
export default withStyles(signModalStyle)(SignModal);