import {
    whiteColor,
    grayColor,
    infoColor,
    dangerColor,
    successColor,
    tableHeadColumns,
} from "./material-kit-pro-react.jsx"; 

import userHomePageStyle from "./user/userHomePageStyle.jsx";
const linkTableViewStyle = theme => ({
    ...userHomePageStyle(theme),
    filesTable: {
        ...tableHeadColumns(theme),
        "&.mobile-view-table": {
            "& .rt-thead, .rt-tbody": {
                minWidth: "100% !important"
            }
        },
        "& .rt-thead": {
            position: "sticky",
            zIndex: "1",
            top: "0",
            "& .rt-tr": {
                backgroundColor: theme.customize.tableHeadBackgroundColor,                
            }
        },
        "& .rt-thead .rt-th": {
            fontSize: "12px",
            color: theme.customize.tableHeadTextColor,
            fontWeight: "bold",
            backgroundColor: theme.customize.tableHeadBackgroundColor,
            paddingTop: "10px",
            paddingBottom: "10px",
            "&.hd_show_head": {
                width: "100px !important",
                maxWidth: "100px !important",
                minWidth: "100px !important",
                flex: "0 !important",
                zIndex: "1",
                "&.hd_attachments": {
                    width: "110px !important",
                    maxWidth: "110px !important",
                    minWidth: "110px !important",
                },
                "&>div": {
                    zIndex: "1",
                    position: "relative"
                }
            },
            "&.hd_hide": {
                // display: 'none'
                marginLeft: "-100px !important",
                "&.hd_attachments": {
                    marginLeft: "-110px !important"
                },
                "& .rt-resizable-header-content": {
                    display: "none"
                }
            },
            "& .rt-resizable-header-content": {
                "&:after": {
                    color: theme.customize.tableHeadTextColor+" !important",
                }
            },
            "&.hd_check": {
                "& .MuiCheckbox-root": {
                    padding: "0px",
                }
            }
        },
        "& .rt-tbody .rt-td, & .rt-tbody .rt-td a": {
            fontSize: "12px",
            color: theme.customize.color,
        },
        "& .rt-tbody": {
            overflow: "unset",
            minHeight: "390px",
            "& .rt-tr-group": {
                flexGrow: "0"
            }
        },
        "& .rt-tbody .rt-td": {
            padding: "0px 5px",
            margin: "5px 0px",
            "& svg": {
                fill: theme.customize.tableRowActionsIconsColor,
                color: theme.customize.tableRowActionsIconsColor,
                "&.change-link": {
                    width: "22px",
                    height: "22px"
                }
            },
            "& button": {
                padding: "0px",
                height: "17px",
                width: "30px",
                minWidth: "0px",
                margin: "0px",
                color: theme.customize.color,
            },
            "&.td_hide": {
                display: "none"
            }
        },
        "& .rt-expander": {
            "&:after": {
                content: "'+'",
                color: theme.customize.color,
                border: "none",
                fontWeight: "bold",
                fontSize: "20px",
                top: "0%",
                transform: "none !important",
            },
            "&.-open": {
                "&:after": {
                    content: "'-'",
                }
            }
        },
        "& .hd_check": {
            width: "30px !important",
            textAlign: "center !important",
            flex: "30 0 auto !important",
            "&.hd_mobile_view": {
                flex: "20 0 auto !important",
                width: "20px !Important"
            }
        },
        "& .td_check": {
            "& .MuiButtonBase-root": {
                padding: "0px"
            },
            "& svg": {
                fill: whiteColor+" !important"
            }
        },
        "& .hd_type": {
            textAlign: "left !Important"
        },
        "& .hd_account": {
            width: "10% !important",
            textAlign: "center !important",
        },
        "& .hd_server": {
            width: "5% !important",
            textAlign: "center !important",
        },
        "& .hd_sender": {
            width: "10% !important",
            textAlign: "left !important",
            "& div": {
                textOverflow: 'ellipsis',
                overflow: 'hidden',
            }
        },
        "& .hd_last_download": {
            width: "10% !important",
            textAlign: "left !important",
        },
        "& .hd_recipients": {
            width: "15% !important",
            textAlign: "left !important",
            "& div": {
                width: "100%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
            },
            "&.hd_mobile_view": {
            }
        },
        "& .hd_message": {
            width: "15% !important",
            textAlign: "left !important",
        },
        "& .hd_path": {
            width: "12% !important",
            textAlign: "left !important",
            whiteSpace: "nowrap",
            "& div": {
                textOverflow: 'ellipsis',
                overflow: 'hidden',
            }
        },
        "& .hd_title": {
            textAlign: "left !important",
        },
        "& .hd_created": {
            width: "10% !important",
            textAlign: "left !important",
            "&.hd_mobile_view": {
            }
        },
        "& .hd_attachments": {
            width: "15% !important",
            textAlign: "left !important",
        },
        "& .hd_link_password": {
            width: "8% !important",
            textAlign: "left !important",
            display: "flex",
            alignItems: "center",
            "& button": {
                height: "auto",
                paddingTop: "0px",
                paddingBottom: "0px"
            }
        },
        "& .hd_expire": {
            width: "12% !important",
            textAlign: "left !important",
            "& .expire-link": {
                color: dangerColor[0]
            }
        },
        "& .hd_status": {
            width: "7% !important",
            textAlign: "center !important",
            "& p": {
                color: successColor[0],
                "&.orange": {
                    color: infoColor[0]
                }
            }
        },
        "& .hd_action": {
            width: "7% !important",
            textAlign: "center !important",
        },
        "& .hd_mobile_icon": {
            width: "20px !important",
            flex: "20 0 auto !important",
            textAlign: "center !important",
        },
        "& .rt-thead .rt-th.-cursor-pointer > div:first-of-type:after": {
            color: grayColor[17],
            opacity: "1 !important"
        },
        "& .rt-td": {
            fontSize: "12px",
            "&.td_attachments": {
                wordBreak: "break-all",
                "& a+a": {
                    "&:before": {
                        content: "','",
                        display: "inline-block",
                        marginLeft: "5px",
                        marginRight: "5px",
                    }
                },
                "& button": {
                    width: "auto !important",
                    height: "auto !important",
                    textTransform: "none",
                    "&:hover": {
                        textDecoration: "underline",
                    }
                }
            }
        },
        "& .rt-tr": {
            cursor: "pointer"
        },
        [theme.breakpoints.down("xs")]: {
            padding: "10px"
        },
        "& .sd-password-field": {
            "& .sd-passwords": {
                display: "flex",
                "& span:before": {
                    content: '""',
                    width: "3px",
                    height: "3px",
                    display: "block",
                    borderRadius: "100%",
                    backgroundColor: theme.customize.color,
                    marginRight: "2px"
                }
            }
        }
    },
    subfilesTable: {
        background: theme.customize.opacityColor,
        "& .rt-tbody": {
            minHeight: "auto !important",
            marginBottom: "10px"
        },
    },
    UserRecentActivitySection: {
        paddingLeft: '35px',
        paddingTop: '10px',
        "&>p": {
            color: '#659DFA',
            marginBottom: '0px',
            marginLeft: '5px',
        },
        "& .csv-file-icon": {
            textAlign: "right",
            padding: "10px",
            "& img": {
                maxWidth: "24px"
            }
        }
    },
    UserRecentActivity: {
        background: 'transparent',
        "& .rt-thead": {
            backgroundColor: 'transparent',
            "& .rt-tr": {
                backgroundColor: "transparent"
            },
            "& .rt-th": {
                color: infoColor[0]+" !important",
                textAlign: "left !important",
                backgroundColor: 'transparent',
            },
        },
        "& .rt-tbody": {
            minHeight: "auto !important",
            marginBottom: "10px",
            "& .rt-td": {
                textAlign: "left !important",
            },
            "& .td_action p": {
                textOverflow: "ellipsis",
                width: "100%",
                overflow: "hidden",
                whiteSpace: 'nowrap',
                display: 'block !important',
                fontSize: '12px',
                marginBottom: '0px',
            },
            "& .td_name p": {
                textOverflow: "ellipsis",
                width: "100%",
                overflow: "hidden",
                whiteSpace: 'nowrap',
                display: 'block !important',
                fontSize: '12px',
                marginBottom: '0px',
            }
        },
        "& .hd_date_time": {
            width: '5% !important',
        },
        "& .hd_action": {
            width: "30% !important",
            "& button": {
                width: "auto !important",
            }
        },
        "& .hd_name": {
            width: "10% !Important"
        }
    }
});

export default linkTableViewStyle;
