import Config from '../../../../../Config';
import axios from 'axios';
import { helper } from '../Element';

const ApiHelper = {
    getSites(requestData){
        return new Promise(function(resolve, reject){
            let sharepointToken = localStorage.getItem("sharepoint_token");
            let sharepointConnect = localStorage.getItem("sharepoint_connected");
            let redirectUrlOneDrive = Config.getRedirectUrlOneDrive();
            let clientIdOneDrive = Config.getClientIdOneDrive();
            const scope = Config.getOnedriveScope();
             if(sharepointToken != null){
                const headers = {
                    Authorization: `Bearer `+sharepointToken,
                    Accept: 'application/json'
                };
                axios({
                    url: 'https://graph.microsoft.com/v1.0/sites?'+helper.serialize(requestData),
                    method: "GET",
                    headers: headers,
                }).then(res => {
                    if(res.data){
                        resolve(res.data);
                    }else{
                        reject("Invalid response from api.");
                    }
                }).catch(err => {
                    if (err.response && err.response.data.error && err.response.data.error.message === "Unable to find target address") {
                        reject(err.response.data.error.code);
                        return;
                    }
                    if (sharepointConnect) {
                        localStorage.setItem("sharepoint_auth_flow", 1);
                        const URL ='https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id='+clientIdOneDrive+'&scope='+scope+'&response_type=token&redirect_uri='+redirectUrlOneDrive+'&prompt=select_account';
                        window.location.replace(URL);
                    }
                    reject(err);
                });
             }else{
                if (sharepointConnect) {
                    localStorage.setItem("sharepoint_auth_flow", 1);
                    const URL ='https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id='+clientIdOneDrive+'&scope='+scope+'&response_type=token&redirect_uri='+redirectUrlOneDrive+'&prompt=select_account';
                    window.location.replace(URL);
                }
                reject("add Account");
             }
        });
    },
    getDriveFiles(){
        return new Promise(function(resolve, reject){
            let sharepointToken = localStorage.getItem("sharepoint_token");
            let sharepointConnect = localStorage.getItem("sharepoint_connected");
            const sharepointSite = localStorage.getItem("sharepoint_site");
            let redirectUrlOneDrive = Config.getRedirectUrlOneDrive();
            let clientIdOneDrive = Config.getClientIdOneDrive();
            const scope = Config.getOnedriveScope();
             if(sharepointToken != null){
                const headers = {
                    Authorization: `Bearer `+sharepointToken,
                    Accept: 'application/json'
                };
                axios({
                    url: 'https://graph.microsoft.com/v1.0/sites/'+sharepointSite+'/drive/root/children',
                    method: "GET",
                    headers: headers,
                }).then(res => {
                    if(res.data){
                        resolve(res.data);
                    }else{
                        reject("Invalid response from api.");
                    }
                }).catch(err => {
                    if (err.response && err.response.data.error && err.response.data.error.code === "invalidRequest") {
                        reject(err.response.data.error.code);
                        return;
                    }
                    if (sharepointConnect) {
                        localStorage.setItem("sharepoint_auth_flow", 1);
                        const URL ='https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id='+clientIdOneDrive+'&scope='+scope+'&response_type=token&redirect_uri='+redirectUrlOneDrive+'&prompt=select_account';
                        window.location.replace(URL);
                    }
                    reject(err);
                });
             }else{
                if (sharepointConnect) {
                    localStorage.setItem("sharepoint_auth_flow", 1);
                    const URL ='https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id='+clientIdOneDrive+'&scope='+scope+'&response_type=token&redirect_uri='+redirectUrlOneDrive+'&prompt=select_account';
                    window.location.replace(URL);
                }
                reject("add Account");
             }
        });
    },
    getOneDriveNestedFiles(Id, folderId){
        return new Promise(function(resolve, reject){
            let sharepointToken = localStorage.getItem("sharepoint_token");
            let sharepointConnect = localStorage.getItem("sharepoint_connected");
            const sharepointSite = localStorage.getItem("sharepoint_site");
            let redirectUrlOneDrive = Config.getRedirectUrlOneDrive();
            let clientIdOneDrive = Config.getClientIdOneDrive();
            const scope = Config.getOnedriveScope();
             if(sharepointToken !=null){
                const headers = {
                    Authorization: `Bearer `+sharepointToken,
                    Accept: 'application/json'
                };
                axios({
                    url: `https://graph.microsoft.com/v1.0/sites/`+sharepointSite+`/drives/${folderId}/items/${Id}/children`,
                    method: "GET",
                    headers: headers,
                }).then(res => {
                    if(res.data){
                        resolve(res.data);
                    }else{
                        reject("Invalid response from api.");
                    }
                }).catch(err => {
                    if (sharepointConnect) {
                        localStorage.setItem("sharepoint_auth_flow", 1);
                        const URL ='https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id='+clientIdOneDrive+'&scope='+scope+'&response_type=token&redirect_uri='+redirectUrlOneDrive+'&prompt=select_account';
                        window.location.replace(URL);
                    }
                    reject(err);
                });
             }else{
                console.log('Login  from api.')
                reject("Login.");
             }
        });
    },
    getEmail(){
        return new Promise(function(resolve, reject){
            let sharepointToken = localStorage.getItem("sharepoint_token");
            let sharepointConnect = localStorage.getItem("sharepoint_connected");
            let redirectUrlOneDrive = Config.getRedirectUrlOneDrive();
            let clientIdOneDrive = Config.getClientIdOneDrive();
            const scope = Config.getOnedriveScope();
             if(sharepointToken != null){
                const headers = {
                    Authorization: `Bearer `+sharepointToken,
                    Accept: 'application/json'
                };
                axios({
                    url: `https://graph.microsoft.com/v1.0/me`,
                    method: "GET",
                    headers: headers,
                }).then(res => {
                    if(res.data){
                        resolve(res.data);
                    }else{
                        reject("Invalid response from api.");
                    }
                }).catch(err => {
                    if (sharepointConnect) {
                        localStorage.setItem("sharepoint_auth_flow", 1);
                        const URL ='https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id='+clientIdOneDrive+'&scope='+scope+'&response_type=token&redirect_uri='+redirectUrlOneDrive+'&prompt=select_account';
                        window.location.replace(URL);
                    }
                    reject(err);
                });
             }else{
                console.log('Login  from api.')
                reject("Login.");
             }
        });
    },
    getDriveFilesStatus(Id, folderId){
        return new Promise(function(resolve, reject){
            let sharepointToken = localStorage.getItem("sharepoint_token");
            let sharepointConnect = localStorage.getItem("sharepoint_connected");
            const sharepointSite = localStorage.getItem("sharepoint_site");
            let redirectUrlOneDrive = Config.getRedirectUrlOneDrive();
            let clientIdOneDrive = Config.getClientIdOneDrive();
            const scope = Config.getOnedriveScope();
             if(sharepointToken !=null){
                const headers = {
                    Authorization: `Bearer `+sharepointToken,
                    Accept: 'application/json'
                };
                axios({
                    url: `https://graph.microsoft.com/v1.0/sites/`+sharepointSite+`/drives/${folderId}/items/${Id}`,
                    method: "GET",
                    headers: headers,
                }).then(res => {
                    if(res.data){
                        resolve(res.data);
                    }else{
                        reject("Invalid response from api.");
                    }
                }).catch(err => {
                     if (err === "Error: Request failed with status code 404") {
                        resolve("data not founded");
                    }else{
                        if (sharepointConnect) {
                            localStorage.setItem("sharepoint_auth_flow", 1);
                            const URL ='https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id='+clientIdOneDrive+'&scope='+scope+'&response_type=token&redirect_uri='+redirectUrlOneDrive+'&prompt=select_account';
                            window.location.replace(URL);
                        }
                        reject(err);
                    }
                });
             }else{
                console.log('Login  from api.')
                reject("Login.");
             }
        });
    },
    getDataAllFolderOneDrive(parentId, id){
        return new Promise(function(resolve, reject){
            let sharepointToken = localStorage.getItem("sharepoint_token");
            let sharepointConnect = localStorage.getItem("sharepoint_connected");
            const sharepointSite = localStorage.getItem("sharepoint_site");
            let redirectUrlOneDrive = Config.getRedirectUrlOneDrive();
            let clientIdOneDrive = Config.getClientIdOneDrive();
            const scope = Config.getOnedriveScope();
             if(sharepointToken !=null){
                const headers = {
                    Authorization: `Bearer `+sharepointToken,
                    Accept: 'application/json'
                };
                axios({
                    url: `https://graph.microsoft.com/v1.0/sites/`+sharepointSite+`/drives/${parentId}/items/${id}/children`,
                    method: "GET",
                    headers: headers,
                }).then(res => {
                    if(res.data){
                        resolve(res.data);
                    }else{
                        reject("Invalid response from api.");
                    }
                }).catch(err => {
                    console.log('err  from api.',err)
                    if (sharepointConnect) {
                        localStorage.setItem("sharepoint_auth_flow", 1);
                        const URL ='https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id='+clientIdOneDrive+'&scope='+scope+'&response_type=token&redirect_uri='+redirectUrlOneDrive+'&prompt=select_account';
                        window.location.replace(URL);
                    }
                    reject(err);
                });
             }else{
                console.log('Login  from api.')
                reject("Login.");
             }
        });
    },
    checkFolderOneDriveModifi(folderId){
        return new Promise(function(resolve, reject){
            let sharepointToken = localStorage.getItem("sharepoint_token");
            let sharepointConnect = localStorage.getItem("sharepoint_connected");
            const sharepointSite = localStorage.getItem("sharepoint_site");
            let redirectUrlOneDrive = Config.getRedirectUrlOneDrive();
            let clientIdOneDrive = Config.getClientIdOneDrive();
            const scope = Config.getOnedriveScope();
             if(sharepointToken !=null){
                const headers = {
                    Authorization: `Bearer `+sharepointToken,
                    Accept: 'application/json'
                };
                axios({
                    url: `https://graph.microsoft.com/v1.0/sites/`+sharepointSite+`/drive/items/${folderId}`,
                    method: "GET",
                    headers: headers,
                }).then(res => {
                    if(res.data){
                        resolve(res.data);
                    }else{
                        reject("Invalid response from api.");
                    }
                }).catch(err => {
                    console.log('err  from api.',err)
                    if (sharepointConnect) {
                        localStorage.setItem("sharepoint_auth_flow", 1);
                        const URL ='https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id='+clientIdOneDrive+'&scope='+scope+'&response_type=token&redirect_uri='+redirectUrlOneDrive+'&prompt=select_account';
                        window.location.replace(URL);
                    }
                    reject(err);
                });
             }else{
                console.log('Login  from api.')
                reject("Login.");
             }
        });
    },
}
export default ApiHelper;
export { ApiHelper };