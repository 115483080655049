import React from "react";
import Api from "../../../assets/js/utils/Api";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Close from "@material-ui/icons/Close";
import LoaderComponent from '../Loader';
import PasswordMatch from "../../../assets/js/utils/PasswordMatch";
import GridItem from "../../components/Grid/GridItem.jsx";
import CustomSelect from "../../components/CustomInput/CustomSelect";
import { helper } from '../../../assets/js/utils/Element';
import { Checkbox, Switch } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Check from "@material-ui/icons/Check";
import DialogActions from "@material-ui/core/DialogActions";
import axios from "axios";
import CustomInput from "../CustomInput/CustomInput.jsx";

import addUserModelStyle from "../../../assets/jss/user/addUserModelStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const AddUserModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        const { userDetails, editMode } = this.props;
        
        this.state = {
            userDetails: userDetails,
            firstName: userDetails.firstName,
            lastName: userDetails.lastName,
            email: userDetails.email,
            password: userDetails.password,
            passwordConfirm: userDetails.passwordConfirm,
            quota: userDetails.quota,
            timezone: userDetails.timezone,
            enable_2fa: userDetails.enable_2fa,
            cell: userDetails.cell,
            accountType: userDetails.accountType,
            extensions: userDetails.extensions,
            saving: false,
            response: null,
            success: false,
            cancelToken: null,
            showError: false,
            errorMessage: "",
            validation: {
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                passwordConfirm: "",
                quota: "",
                timezone: "",
                accountType: "",
                isValid: false
            },
            requireLowerletter: false,
            requireUpperletter: false,
            requireNumber: false,
            requireSymbol: false,
            requireLength: false,
            editMode: editMode
        };

        this.updateUserDetails = this.updateUserDetails.bind(this);
        this.storageSizes = ['1 GB', '2 GB', '3 GB', '4 GB', '5 GB', '10 GB', '15 GB', '20 GB'];
        this.timezoneOptions = Api.getTimezoneOptions();
        this.accountTypes = [
            {
                id: 2,
                label: "Standard",
            },
            {
                id: 3,
                label: "Pro",
            }
        ];
    }
    updateUserDetails(){
        const isValid = this.validateForm();
        if(!isValid || this.state.loading){
            return;
        }

        const { userDetails, firstName, lastName, email, password, timezone, editMode, enable_2fa, cell, accountType, extensions } = this.state;
        let quota = helper.getBytesSize(this.state.quota);
        const requestData = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            password: password,
            quota: quota,
            timezone: timezone,
            enable_2fa: enable_2fa,
            cell: cell,
            accountType: accountType,
            extensions: extensions,
        };
        
        if(userDetails.hasOwnProperty("id")){
            requestData['id'] = userDetails.id;
        }
    
        const source = axios.CancelToken.source();
        this.setState({
            saving: true,
            cancelToken: source,
            showError: false,
        });

        const that = this;
        if(editMode === true) {
            Api.editUser(requestData, source).then(data => {
                that.setState({
                    success: true,
                    saving: false
                });
            }).catch(err => {
                if(typeof(err) === "object" && err.hasOwnProperty("message") && err.message !== 'Request Cancelled'){
                    this.setState({
                        saving: false,
                        showError: true,
                        errorMessage: err.message
                    });
                }
            });
        }else{
            Api.addUser(requestData, source).then(data => {
                that.setState({
                    success: true,
                    saving: false
                });
            }).catch(err => {
                if(typeof(err) === "object" && err.hasOwnProperty("message") && err.message !== 'Request Cancelled'){
                    this.setState({
                        saving: false,
                        showError: true,
                        errorMessage: err.message
                    });
                }
            });
        }
    }
    renderErrorMessages(){
        const { errorMessage } = this.state;
        if(typeof(errorMessage) === "object"){
            let errorMessages = [];
            let key = 0;
            for(const attrib in errorMessage){
                const message = errorMessage[attrib];
                errorMessages.push(<GridItem key={key} className={"passwordCheck-notValid-customizable"}>
                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                    <span className="checkPasswordText-lowerletter">{message}</span>
                </GridItem>);
                key++;
            }
            return errorMessages;
        }
        return <GridItem className={"passwordCheck-notValid-customizable"}>
            <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
            <span className="checkPasswordText-lowerletter">{errorMessage}</span>
        </GridItem>;
    }
    handleChange(e, name){
        let state = {};
        state[name] = e.target.value;
        this.setState(state);
    }
    handle2FaCheckbox(e, name){
        this.setState({
            [name]:  e.target.checked ? 1 : 0
        });
    }
    handleCheckbox(e, name){
        this.setState({
            extensions: {
                ...this.state.extensions,
                [name]: {
                    'status': e.target.checked ? 1 : 0
                }
            }
        });
    }
    validateForm(){
        let validation = {
            firstName: "success",
            lastName: "success",
            email: "success",
            password: "success",
            passwordConfirm: "success",
            quota: "success",
            timezone: "success",
            cell: "success",
            accountType: "success",
            isValid: true
        };
        const { firstName, lastName, email, password, passwordConfirm, quota, editMode, timezone, enable_2fa, cell, accountType } = this.state;
        if(firstName.length <= 0){
            validation.firstName = "error";
            validation.isValid = false;
        }
        if(lastName.length <= 0){
            validation.lastName = "error";
            validation.isValid = false;
        }
        var emailRegex = /\S+@\S+\.\S+/;
        if(email.length <= 0 || !emailRegex.test(email)){
            validation.email = "error";
            validation.isValid = false;
        }

        
        const that = this;
        let check = true;
        if(editMode === false){
            check = PasswordMatch.check(password,function(requireLowerletter, requireUpperletter, requireNumber, requireSymbol, requireLength){
                that.setState({
                    showPasswordErrors: true,
                    requireLowerletter: requireLowerletter,
                    requireUpperletter: requireUpperletter,
                    requireNumber: requireNumber,
                    requireSymbol: requireSymbol,
                    requireLength: requireLength
                });
            });
        }

        if((password.length <= 0 && editMode === false) || (password.length > 0 && check === false)){
            validation.password = "error";
            validation.isValid = false;
        }
        if((passwordConfirm.length <= 0 && editMode === false) || password !== passwordConfirm){
            validation.passwordConfirm = "error";
            validation.isValid = false;
        }

        if(quota.length <= 0){
            validation.quota = "error";
            validation.isValid = false;
        }
        
        if(timezone.length <= 2){
            validation.timezone = "error";
            validation.isValid = false;
        }

        if(enable_2fa && cell.length <= 9){
            validation.cell = "error";
            validation.isValid = false;
        }

        if(accountType.length <= 2){
            validation.accountType = "error";
            validation.isValid = false;
        }

        this.setState({validation: validation});
        return validation.isValid;
    }
    handleCellChange(e, name) {
        const { cell } = this.state;
        this.tryValidation = true;
        let state = {};
        const value = e.target.value;
        state[name] = value;
        const regex = /^\d+$/;
        if((value.length > 0 && !regex.test(value)) || value.length > 16){
            state[name] = cell;
        }
        this.setState({
            [name]: state[name]
        });
    }
    renderForm(){
        const { firstName, lastName, email, password, passwordConfirm, quota, timezone, validation, requireLowerletter, requireUpperletter, 
        requireLength, requireNumber, requireSymbol, showPasswordErrors, enable_2fa, cell, extensions, accountType } = this.state;
        const { classes } = this.props;
        const { user } = this.store.getState();

        return(
            <div className={classes.addUserContainer}>
                <div className={classes.addUserForm}>
                    <div>
                        <CustomInput
                            success={validation.firstName === "success"}
                            error={validation.firstName === "error"}
                            id="input-firstName"
                            labelText="First Name"
                            inputProps={{
                                onChange: (e) => this.handleChange(e,'firstName'),
                                value: firstName,
                                name: "firstName",
                                type: "text",
                            }}
                            color="custom"
                            formControlProps={{
                                fullWidth: true,
                                className: "custom-input m-zero"
                            }}
                        />
                        <CustomInput
                            success={validation.lastName === "success"}
                            error={validation.lastName === "error"}
                            id="input-lastName"
                            labelText="Last Name"
                            inputProps={{
                                onChange: (e) => this.handleChange(e,'lastName'),
                                value: lastName,
                                name: "lastName",
                                type: "text",
                            }}
                            color="custom"
                            formControlProps={{
                                fullWidth: true,
                                className: "custom-input"
                            }}
                        />
                        <CustomInput
                            success={validation.email === "success"}
                            error={validation.email === "error"}
                            id="input-email"
                            labelText="Email"
                            inputProps={{
                                onChange: (e) => this.handleChange(e,'email'),
                                value: email,
                                name: "email",
                                type: "email",
                            }}
                            color="custom"
                            formControlProps={{
                                fullWidth: true,
                                className: "custom-input"
                            }}
                        />
                        <CustomInput
                            formControlProps={{
                                fullWidth: true,
                                className: "custom-input"
                            }}
                            success={validation.password === "success"}
                            error={validation.password === "error"}
                            id="input-password"
                            labelText="Password"
                            passwordInput={true}
                            inputProps={{
                                onChange: (e) => this.handleChange(e,'password'),
                                name: "password",
                                type: "text",
                                value: password,
                                autoComplete: 'off',
                            }}
                        />
                        {
                            showPasswordErrors ?
                                <GridItem>
                                    <div>
                                        <div className={(requireLowerletter?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-lowerletter"}>
                                            <span aria-hidden="true" className="validation-error-symbol check-lowerletter">{requireLowerletter? '\u2713' : '\u2716' }</span>
                                            <span className="checkPasswordText-lowerletter">Password must contain a lower case letter</span>
                                        </div>
                                        <div className={(requireUpperletter?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-upperletter"}>
                                            <span aria-hidden="true" className="validation-error-symbol check-upperletter">{requireUpperletter? '\u2713' : '\u2716' }</span>
                                            <span className="checkPasswordText-upperletter">Password must contain an upper case letter</span>
                                        </div>
                                        <div className={(requireNumber?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-numbers"}>
                                            <span aria-hidden="true" className="validation-error-symbol check-symbols">{requireNumber? '\u2713' : '\u2716' }</span>
                                            <span className="checkPasswordText-symbols">Password must contain a number</span>
                                        </div>
                                        <div className={(requireSymbol?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-symbols"}>
                                            <span aria-hidden="true" className="validation-error-symbol check-numbers">{requireSymbol? '\u2713' : '\u2716' }</span>
                                            <span className="checkPasswordText-numbers">Password must contain a special character</span>
                                        </div>
                                        <div className={(requireLength?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-length"}>
                                            <span aria-hidden="true" className="validation-error-symbol check-length">{requireLength? '\u2713' : '\u2716' }</span>
                                            <span className="checkPasswordText-length">Password must contain at least 8 characters</span>
                                        </div>
                                    </div>
                                </GridItem>
                            :
                            <></>
                        }
                        <CustomInput
                            success={validation.passwordConfirm === "success"}
                            error={validation.passwordConfirm === "error"}
                            id="input-signupPasswordConfirm"
                            labelText="Type Password Again"
                            passwordInput={true}
                            inputProps={{
                                required: true,
                                onChange: (e) => this.handleChange(e,'passwordConfirm'),
                                name: "passwordConfirm",
                                type: "text",
                                value: passwordConfirm,
                                autoComplete: 'off',
                            }}                                    
                            formControlProps={{
                                fullWidth: true,
                                className: "custom-input"
                            }}
                        />
                        <CustomSelect 
                            success={validation.quota === "success"}
                            error={validation.quota === "error"}
                            formControlProps={{
                                fullWidth: true,
                                className: "custom-input"
                            }}
                            labelText="Storage Size"
                            selectProps={{
                                onChange: (e) => this.handleChange(e,"quota"),
                                value: quota
                            }}
                            inputProps={{
                                name: "quota",
                                id: "input-quota",
                                className: classes.alignLeft
                            }}
                            items={this.storageSizes}
                            itemLabel="label"
                            itemValue="id"
                        />
                        <CustomSelect 
                            success={validation.timezone === "success"}
                            error={validation.timezone === "error"}
                            formControlProps={{
                                fullWidth: true,
                                className: "custom-input"
                            }}
                            labelText="Timezone"
                            selectProps={{
                                onChange: (e) => this.handleChange(e,"timezone"),
                                value: timezone,
                            }}
                            inputProps={{
                                name: "timezone",
                                id: "input-timezone",
                                className: classes.alignLeft,
                            }}
                            items={this.timezoneOptions}
                            itemLabel="value"
                            itemValue="key"
                        />
                        <CustomSelect 
                            success={validation.accountType === "success"}
                            error={validation.accountType === "error"}
                            formControlProps={{
                                fullWidth: true,
                                className: "custom-input"
                            }}
                            labelText="Account Type"
                            selectProps={{
                                onChange: (e) => this.handleChange(e,"accountType"),
                                value: accountType
                            }}
                            inputProps={{
                                name: "accountType",
                                id: "input-accountType",
                                className: classes.alignLeft
                            }}
                            items={this.accountTypes}
                            itemLabel="label"
                            itemValue="id"
                        />
                        <CustomInput
                            success={validation.cell === "success"}
                            error={validation.cell === "error"}
                            formControlProps={{
                                fullWidth: true,
                                className: "custom-input"
                            }}
                            id="input-cell"
                            labelText="Cell"
                            inputProps={{
                                onChange: (e) => this.handleCellChange(e, 'cell'),
                                name: "cell",
                                value: cell,
                            }}
                        />
                        <div className={classes.alignLeft}>
                            <FormControlLabel
                                classes={{label: classes.label}}
                                control={
                                    <Checkbox                                                
                                        tabIndex={-1}
                                        onClick={(e) => this.handle2FaCheckbox(e, "enable_2fa")}
                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                        icon={<Check className={classes.uncheckedIcon} />}
                                        classes={{checked: classes.checked, root: classes.checkRoot}}
                                        checked={enable_2fa}
                                    />
                                }
                                label={"Enable Two Factor Authentication"}
                            />
                        </div>
                        <div className={classes.alignLeft+" "+classes.extensionsTitle}>
                            <h6>Extensions</h6>
                        </div>
                        {
                            helper.isExtensionAllowed(user, 'adobesign') ?
                                <div className={classes.alignLeft}>
                                    <FormControlLabel
                                        classes={{label: classes.label}}
                                        control={
                                            <Switch
                                                checked={(extensions.adobesign.status ? true : false)}
                                                onChange={(e) => this.handleCheckbox(e, 'adobesign')}
                                                value="adobesign"
                                                classes={{
                                                    switchBase: classes.switchBase,
                                                    checked: classes.switchChecked,
                                                    thumb: classes.switchIcon,
                                                    track: classes.switchBar
                                                }}
                                            />
                                        }
                                        label={helper.getExtensionsData(user, 'adobesign', 'extension')}
                                    />
                                </div>
                            :
                                <></>
                        }
                        {
                            helper.isExtensionAllowed(user, 'docusign') ?
                                <div className={classes.alignLeft}>
                                    <FormControlLabel
                                        classes={{label: classes.label}}
                                        control={
                                            <Switch
                                                checked={(extensions.docusign.status ? true : false)}
                                                onChange={(e) => this.handleCheckbox(e, 'docusign')}
                                                value="docusign"
                                                classes={{
                                                    switchBase: classes.switchBase,
                                                    checked: classes.switchChecked,
                                                    thumb: classes.switchIcon,
                                                    track: classes.switchBar
                                                }}
                                            />
                                        }
                                        label={helper.getExtensionsData(user, 'docusign', 'extension')}
                                    />
                                </div>
                            :
                                <></>
                        }
                        {
                            helper.isExtensionAllowed(user, 'ms_office') ?
                                <div className={classes.alignLeft}>
                                    <FormControlLabel
                                        classes={{label: classes.label}}
                                        control={
                                            <Switch
                                                checked={(extensions.ms_office.status ? true : false)}
                                                onChange={(e) => this.handleCheckbox(e, 'ms_office')}
                                                value="ms_office"
                                                classes={{
                                                    switchBase: classes.switchBase,
                                                    checked: classes.switchChecked,
                                                    thumb: classes.switchIcon,
                                                    track: classes.switchBar
                                                }}
                                            />
                                        }
                                        label={helper.getExtensionsData(user, 'ms_office', 'extension')}
                                    />
                                </div>
                            :
                                <></>
                        }
                        {
                            helper.isExtensionAllowed(user, 'google_drive') ?
                                <div className={classes.alignLeft}>
                                    <FormControlLabel
                                        classes={{label: classes.label}}
                                        control={
                                            <Switch
                                                checked={(extensions.google_drive.status ? true : false)}
                                                onChange={(e) => this.handleCheckbox(e, 'google_drive')}
                                                value="google_drive"
                                                classes={{
                                                    switchBase: classes.switchBase,
                                                    checked: classes.switchChecked,
                                                    thumb: classes.switchIcon,
                                                    track: classes.switchBar
                                                }}
                                            />
                                        }
                                        label={helper.getExtensionsData(user, 'google_drive', 'extension')}
                                    />
                                </div>
                            :
                                <></>
                        }
                        {
                            helper.isExtensionAllowed(user, 'one_drive') ?
                                <div className={classes.alignLeft}>
                                    <FormControlLabel
                                        classes={{label: classes.label}}
                                        control={
                                            <Switch
                                                checked={(extensions.one_drive.status ? true : false)}
                                                onChange={(e) => this.handleCheckbox(e, 'one_drive')}
                                                value="one_drive"
                                                classes={{
                                                    switchBase: classes.switchBase,
                                                    checked: classes.switchChecked,
                                                    thumb: classes.switchIcon,
                                                    track: classes.switchBar
                                                }}
                                            />
                                        }
                                        label={helper.getExtensionsData(user, 'one_drive', 'extension')}
                                    />
                                </div>
                            :
                                <></>
                        }
                        {
                            helper.isExtensionAllowed(user, 'sharepoint') ?
                                <div className={classes.alignLeft}>
                                    <FormControlLabel
                                        classes={{label: classes.label}}
                                        control={
                                            <Switch
                                                checked={(extensions.sharepoint.status ? true : false)}
                                                onChange={(e) => this.handleCheckbox(e, 'sharepoint')}
                                                value="sharepoint"
                                                classes={{
                                                    switchBase: classes.switchBase,
                                                    checked: classes.switchChecked,
                                                    thumb: classes.switchIcon,
                                                    track: classes.switchBar
                                                }}
                                            />
                                        }
                                        label={helper.getExtensionsData(user, 'sharepoint', 'extension')}
                                    />
                                </div>
                            :
                                <></>
                        }
                    </div>
                </div>
            </div>
        )
    }
    render() {
        const { saving, success, showError, editMode } = this.state;
        const { classes } = this.props;

        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="userDetails-slide-title"
                aria-describedby="userDetails-slide-description"
            >
                <DialogTitle
                    id="userDetails-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                    <Button
                        simple
                        className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.props.onClose()}
                    >
                        <Close className={classes.modalClose} />
                    </Button>
                    <h4 className={classes.modalTitle}>
                        {
                                editMode ?
                                    "Edit User"
                                :
                                    "Add User"
                        }
                    </h4>
                </DialogTitle>
                <DialogContent id="userDetails-slide-description" className={classes.modalBody+" "+(success ? 'success' : '')}>
                        {
                            showError ?
                                this.renderErrorMessages()
                            :
                                <></>
                        }
                        {
                            success ?
                                editMode ?
                                    <p>User updated successfully.</p>
                                :
                                    <p>User added successfully.</p>
                            :
                                this.renderForm()
                        }
                </DialogContent>      
                <DialogActions className={classes.modalFooter}>
                    {
                        success ?
                            <Button color="custom" onClick={() => this.props.onClose()}>
                                Close
                            </Button>
                        :
                            <Button color="custom" onClick={this.updateUserDetails} type="button">
                                {
                                    saving ?
                                        <LoaderComponent color="white" align="left" saving={true} />
                                    :
                                        "Save Changes"
                                }
                            </Button>
                    }
                </DialogActions>
            </Dialog>
        );
    }
};

AddUserModal.defaultProps = {
    open: false,
    editMode: false,
};
AddUserModal.propTypes = {
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    open: PropTypes.bool,
    userDetails: PropTypes.object.isRequired,
    editMode: PropTypes.bool
};
export default withStyles(addUserModelStyle)(AddUserModal);
