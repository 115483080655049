/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { whiteColor, dangerColor } from "../material-kit-pro-react.jsx";
import customCheckboxRadioSwitchStyle from "../material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";
const modalStyle = theme => ({
    ...customCheckboxRadioSwitchStyle(theme),
    modalRoot: {
        overflow: "auto",
        display: "block"
    },
    modal: {
        [theme.breakpoints.up("sm")]: {
        maxWidth: "500px",
        margin: "auto"
        },
        borderRadius: "6px",
        overflow: "visible",
        maxHeight: "unset",
        width: "100%",
        marginTop: "130px !important",
        backgroundColor: theme.customize.modalBackground,
    },
    modalHeader: {
        borderBottom: "none",
        paddingTop: "24px",
        paddingRight: "24px",
        paddingBottom: "0",
        paddingLeft: "24px",
        minHeight: "16.43px"
    },
    modalTitle: {
        margin: "0",
        lineHeight: "23px",
        fontSize: "20px",
        color: theme.customize.modalTitleColor,
    },
    modalCloseButton: {
        "&, &:hover": {
        color: theme.customize.whiteBlackColor,
        },
        cursor: "pointer",
        padding: "1rem",
        margin: "-1rem -1rem -1rem auto",
        backgroundColor: "transparent",
        border: "0",
        WebkitAppearance: "none",
        float: "right",
        fontSize: "1.5rem",
        fontWeight: "500",
        lineHeight: "1",
        textShadow: "0 1px 0 " + whiteColor,
        opacity: ".5"
    },
    modalClose: {
        width: "16px",
        height: "16px",
    },
    modalBody: {
        paddingTop: "24px",
        paddingRight: "24px",
        paddingBottom: "16px",
        paddingLeft: "24px",
        position: "relative",
        overflow: "visible",
        "& p, & h1, & h2, & h3, & h4, & h5, & h6": {
            color: theme.customize.whiteBlackColor,
            // opacity: "0.8",
            "&$danger": {
                color: dangerColor[0],
                opacity: "1",
            },
            "&.input-note": {
                marginBottom: "0px"
            }
        },
        "& span:not(.MuiButton-label):not(.MuiTab-wrapper)": {
            color: theme.customize.whiteBlackColor,
            // opacity: "0.8",
        },
        "& li": {
            color: theme.customize.whiteBlackColor,
            // opacity: "0.8",
        },
        "& .MuiSwitch-root": {
            "& span": {
                opacity: "1"
            }
        },
        "& .passwordCheck-notValid-customizable": {
            color: dangerColor[0],
            "& span": {
                color: dangerColor[0]+" !important",
            }
        },
    },
    modalFooter: {
        padding: "15px",
        textAlign: "right",
        paddingTop: "0",
        margin: "0"
    },
});

export default modalStyle;
