import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../../components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Check from "@material-ui/icons/Check";
import CheckCircle from "@material-ui/icons/CheckCircle";
import NewLinkModal from "../User/NewLinkModal";
import axios from "axios";
import Cookie from "../../../assets/js/utils/Cookie";
import Api from "../../../assets/js/utils/Api";
import { helper } from '../../../assets/js/utils/Element';
import CustomFileUpload from "../CustomFileUpload/CustomFileUpload.jsx";
import CustomInput from "../CustomInput/CustomInput.jsx";
import Danger from "../../components/Typography/Danger";
import GridItem from "../../components/Grid/GridItem";
import successGif from '../../../assets/img/success.gif';
import MaterialIcon from "@mdi/react";
import { mdiFileCheckOutline, mdiCheck, mdiUndoVariant } from '@mdi/js';
import DateTimePicker from "../CustomInput/DateTimePicker";
import InputAdornment from "@material-ui/core/InputAdornment";
import ReactTable from "react-table";
import GroupMembersModal from "./GroupMembersModal";
import AddIcon from "@material-ui/icons/Add";
import DragNDrop from "../../components/Drag/Drop";
import DuplicateFileModal from  "../../components/User/DuplicateFileModal";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Switch from "@material-ui/core/Switch";
import uploadFileModalStyle from "../../../assets/jss/user/uploadFileModalStyle";
import LoaderComponent from "../Loader.js";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const UploadFileModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        this.editor = React.createRef();
        let yourEmail = Cookie.read("sendlinx_your_email");
        if(!yourEmail || yourEmail === "null"){
            yourEmail = "";
        }
        const { authorized, user, /*customState */ } = this.store.getState();
        if(authorized){
            yourEmail = user.email;
        }

        let notifySender = false;
        if(this.props.ownerNotification === 'mandatory'){
            notifySender = true;
        }
        let groupNotifySender = false;
        let usersChecked = [];
        if(this.props.groupNotification === 'mandatory'){
            groupNotifySender = true;
            if(this.props.sharedWith.length > 0){
                usersChecked = this.getSharedWithArray(this.props.sharedWith)
            }
        }

        let response = null;
        let expire = "";
        let zipPassword = "";
        let totalFiles = 0;
        // if(customState !== null && customState.hasOwnProperty("type") && customState.type === 'upload'){
        //     response = customState.hasOwnProperty("response") ? customState.response : response;
        //     expire = customState.hasOwnProperty("expire") ? customState.expire : expire;
        //     zipPassword = customState.hasOwnProperty("zipPassword") ? customState.zipPassword : zipPassword;
        //     totalFiles = customState.hasOwnProperty("totalFiles") ? customState.totalFiles : totalFiles;
        // }

        this.state = {
            emailTo: "",
            recipients: [],
            yourEmail: yourEmail,
            message: "",
            transferType: "link",
            showTransfer: false,
            files: this.props.files,
            scrollTo: 0,
            zipPassword: zipPassword,
            expire: expire,
            validation: {
                emailTo: "",
                yourEmail: ""                
            },
            uploading: false,
            response: response,
            success: false,
            cancelToken: null,
            totalSize: this.getTotalSize(this.props.files),
            totalSent: 0,
            singleSent: 0,
            snackbarOpen: false,
            totalFiles: totalFiles,
            showError: false,
            errorMessage: "",
            chunkSize: (1048576 * 15), //15 MB by default
            path: this.props.path,
            zipUpload: false,
            zipName: "SL_"+this.getDateString()+".zip",
            resumable: false,
            resumableKey: 0,
            startTime: null,
            notifySender: notifySender,
            groupNotifySender: groupNotifySender,
            duplicatesFound: false,
            duplicates: null,
            duplicateAction: null,
            selectivePendingAction: null,
            selectiveUploadedAction: null,
            keepPendingFiles: [],
            keepUploadedFiles: [],
            sharedWith: this.props.sharedWith,
            sharedFileId: this.props.sharedFileId,
            usersChecked: usersChecked,
            groupMembersModal: false,
            duplicateFileModal: false,
            viewPassword: false,
            maxFileUploadSize: 107374182400,
            section: 'files',
            generateLink: false,
            ownerNotification: this.props.ownerNotification,
            groupNotification: this.props.groupNotification,
            uploadType: this.props.uploadType
        };

        this.partNumber = 0;
        this.chunksSent = 0;
        this.onFilesSelect = this.onFilesSelect.bind(this);
        this.sendFiles = this.sendFiles.bind(this);
        this.cancelUpload = this.cancelUpload.bind(this);
        this.sendAnother = this.sendAnother.bind(this);
        this.onUploadProgress = this.onUploadProgress.bind(this);
        this.onFileDelete = this.onFileDelete.bind(this);
        this.resumeUpload = this.resumeUpload.bind(this);
        this.beforeUnload = this.beforeUnload.bind(this);
    }
    componentWillUnmount(){
        window.onbeforeunload = null;
    }
    getSharedWithArray(sharedWith){
        let usersChecked = [];
        sharedWith.map((user) => {
            usersChecked.push(user.user_id);

            return null;
        });

        return usersChecked;
    }
    beforeUnload(){
        try{
            const { response } = this.state;
            const requestData = {
                id: response.hash
            };
            const source = axios.CancelToken.source();
            Api.browserExit(source, requestData).then(data => {
                
            }).catch(err => {
                
            });
        }catch(e){ }
        return "Are you sure you want to cancel this upload?"
    }
    getDateString(){
        const date = new Date();
        const dateString = (date.getMonth() + 1)+"_"+date.getDate()+"_"+date.getFullYear()+"_"+date.getHours()+"_"+date.getMinutes()+"_"+date.getSeconds();
        return dateString;
    }
    onFilesSelect(files){
        let state = {files: files};
        let fileSize = 0;
        files.map((item) => {
            if(item.type === "folder"){
                item.files.map((subFile) => {
                    if(subFile.size > fileSize){
                        fileSize = subFile.size;
                    }
                    return null;
                });
            }else if(item.file.size > fileSize){
                fileSize = item.file.size;
            }
            return null;
        });
        const bigFileSizeInMbs = fileSize/(1024*1024);
        const bigFileSizeInGbs = fileSize/(1024*1024*1024);
        let chunkSize = this.state.chunkSize;
        if(bigFileSizeInGbs >= 1 && bigFileSizeInGbs < 3){
            chunkSize = (parseInt(bigFileSizeInMbs/50))*1024*1024;
        }else if(bigFileSizeInGbs >= 3 && bigFileSizeInGbs < 10){
            chunkSize = (parseInt(bigFileSizeInMbs/100))*1024*1024;
        }else if(bigFileSizeInGbs >= 10 && bigFileSizeInGbs < 15){
            chunkSize = (parseInt(bigFileSizeInMbs/120))*1024*1024;
        }else if(bigFileSizeInGbs >= 15){
            chunkSize = (parseInt(bigFileSizeInMbs/150))*1024*1024;
        }
        state['chunkSize'] = chunkSize;
        // if(files.length > 1){
        //     state['zipUpload'] = true;
        // }
        this.setState(state);
    }
    onScroll(e){
        if(e.target && e.target.scrollTop <= 0){
            this.setState({
                showTransfer: false,
                scrollTo: 0
            });
        }
    }
    sendFiles(){
        Cookie.write("sendlinx_your_email", this.state.yourEmail);
        const { zipUpload, zipName, zipPassword, notifySender, sharedFileId } = this.state;
        const requestData = {
            recipients: [],
            from: this.state.yourEmail,
            message: this.state.message,
            transferType: this.state.transferType,
            path: this.state.path,
            expire: this.state.expire,
            uploadType: "direct_upload",
            checkDuplicates: 1,
            zipUpload: (zipUpload ? 1 : 0),
            zipName: zipName,
            zipPassword: zipPassword,
            notifySender: (notifySender ? 1 : 0)
        };

        if(sharedFileId !== null){
            requestData['sharedFileId'] = sharedFileId;
        }

        const path = helper.getParam("dir");
        if(typeof(path) === "string" && path.indexOf("/Web/My Docs") !== -1){
            requestData['uploadType'] = "my_docs";
        }
        window.onbeforeunload = this.beforeUnload;
        
        if(this.props.isShared === true){
            requestData['isShared'] = true;
        }
        const { duplicateAction, files, keepPendingFiles, duplicates, keepUploadedFiles } = this.state;
        let filteredFiles = files;
        if(duplicateAction !== null){
            requestData['duplicateAction'] = duplicateAction;
            let duplicatesArray = [];
            duplicates.map((file) => {
                duplicatesArray.push(file.pending.name);
                return null;
            });

            // replace skip selective
            if(duplicateAction === "skip"){
                filteredFiles = [];
                files.map((file) => {
                    if(file.type === "folder"){
                        if(duplicatesArray.includes(file.name)){
                            return null;
                        }
                    }else{
                        if(duplicatesArray.includes(file.file.name)){
                            return null;
                        }
                    }
                    filteredFiles.push(file);
                    return null;
                });
            }else if(duplicateAction === "selective"){
                filteredFiles = [];
                files.map((file) => {
                    if(file.type === "folder"){
                        if(keepPendingFiles.includes(file.name) && keepUploadedFiles.includes(file.name) && duplicatesArray.includes(file.name)){
                            file['duplicate'] = 1;
                            filteredFiles.push(file);
                            return null;
                        }
                        if(keepPendingFiles.includes(file.name) || !duplicatesArray.includes(file.name)){
                            filteredFiles.push(file);
                        }
                    }else{
                        if(keepPendingFiles.includes(file.file.name) && keepUploadedFiles.includes(file.file.name) && duplicatesArray.includes(file.file.name)){
                            file['duplicate'] = 1;
                            filteredFiles.push(file);
                            return null;
                        }
                        if(keepPendingFiles.includes(file.file.name) || !duplicatesArray.includes(file.file.name)){
                            filteredFiles.push(file);
                        }
                    }
                    return null;
                });
            }
        }
        if(filteredFiles.length <= 0){
            this.props.onClose();
            return;
        }

        const source = axios.CancelToken.source();
        this.setState({
            uploading: true,
            recipients: [],
            emailTo: "",
            totalFiles: this.getFilesCount(filteredFiles),
            cancelToken: source,
            showError: false,
            totalSize: this.getTotalSize(filteredFiles),
            startTime: new Date(),
            duplicatesFound: false,
            files: filteredFiles,
            userFiles: files
        });

        const that = this;
        Api.sendFiles(requestData, filteredFiles, source, true).then(data => {
            that.setState({
                response: data,
            });
            that.uploadFiles(data.files);
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message") && err.message !== 'Request Cancelled'){
                if(err.hasOwnProperty("duplicates") && err.duplicates.length > 0){
                    this.setState({
                        showError: true,
                        uploading: false,
                        duplicatesFound: true,
                        duplicates: err.duplicates,
                        errorMessage: err.message,
                        duplicateAction: null,
                        selectivePendingAction: null,
                        selectiveUploadedAction: null,
                        keepPendingFiles: [],
                        keepUploadedFiles: [],
                        files: this.state.userFiles
                    });
                    return;
                }
                this.setState({
                    showError: true,
                    errorMessage: err.message,
                    files: this.state.userFiles
                });
            }
        });
    }
    cancelUpload(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
        
        this.setState({
            uploading: false,
            success: false,
            section: 'files',
            cancelToken: null,
            totalSent: 0,
            singleSent: 0,
            duplicatesFound: false,
            duplicates: null,
            duplicateAction: null,
            selectivePendingAction: null,
            selectiveUploadedAction: null,
            keepPendingFiles: [],
            keepUploadedFiles: [],
            showError: false,
            errorMessage: ""
        });

        const { response } = this.state;
        if(response === null){
            return;
        }

        const requestData = {
            id: response.id
        }

        Api.cancelFiles(requestData).then(data => {
            //Silent
        }).catch(err => {
            //Silent
        });
    }
    resumeUpload(){
        this.setState({
            singleSent: 0,
        });
        this.partNumber = this.partNumber - 1;
        if(this.partNumber < 0){
            this.partNumber = 0;
        }
        const { response, resumableKey } = this.state;
        const fileData = this.getNextFile(response.files, resumableKey);
        const source = axios.CancelToken.source();
        this.uploadMultiPart(response.files, fileData, resumableKey, source);
        this.partNumber = this.partNumber + 1;
    }
    sendAnother(){
        this.setState({
            section: 'files',
            zipUpload: false,
            success: false,
            files: [],
            singleSent: 0,
            totalSent: 0,
            duplicatesFound: false,
            duplicates: null,
            duplicateAction: null,
            selectivePendingAction: null,
            selectiveUploadedAction: null,
            keepPendingFiles: [],
            keepUploadedFiles: []
        });
    }
    getTotalSize(files = null){
        if(files === null){
            files = this.state.files;
        }
        let totalSize = 0;
        files.map(item => {
            if(item.type === "folder"){
                item.files.map(subItem => {
                    let file = subItem;
                    if(item.hasOwnProperty("checkRelativePath")){
                        file = subItem.file;
                    }
                    totalSize += file.size;
                    return null;
                });
            }else{
                totalSize += item.file.size;
                return false;
            }
            return null;
        });
        return totalSize;
    }
    getFilesCount(files = null){
        let totalFiles = 0;
        if(files === null){
            files = this.state.files;
        }
        files.map(item => {
            if(item.type === "folder"){
                totalFiles += item.files.length;
            }else{
                totalFiles++;
            }
            return null;
        });
        return totalFiles;
    }
    getRemainingSize(){
        let totalSize = 0;
        let maxSize = 2147483648;
        const { authorized, user } = this.store.getState();
        if(authorized){
            maxSize = user.quota - user.usage;
        }
        this.state.files.map(item => {
            if(item.type === "folder"){
                item.files.map(subItem => {
                    let file = subItem;
                    if(item.hasOwnProperty("checkRelativePath")){
                        file = subItem.file;
                    }
                    totalSize += file.size;
                    return null;
                });
            }else{
                totalSize += item.file.size;
                return false;
            }
            return null;
        });
        return maxSize - totalSize;
    }
    isMultiPartSupported(file){
        if(file === null){
            return false;
        }
        const chunks = this.getTotalChunks(file);
        if(chunks < 2){
            return false;
        }
        const slice = file.slice || file.mozSlice || file.webkitSlice;
        if(typeof(slice) === "function"){
            return true;
        }
        return false;
    }
    getPartContent(file){
        let { chunkSize } = this.state;
        const slice = file.slice || file.mozSlice || file.webkitSlice;
        let end = this.chunksSent + chunkSize;
        if ((file.size - end) < 0) {
            end = file.size;
        }
        
        const blob = slice.call(file, this.chunksSent, end);
        // this.chunksSent = this.chunksSent + chunkSize;
        return blob;
    }
    getTotalChunks(file){
        const { chunkSize } = this.state;
        var chunks = Math.ceil(file.size/chunkSize,chunkSize);
        return chunks;
    }
    uploadFiles(files, key = 0){
        const fileData = this.getNextFile(files, key);
        const that = this;
        if(fileData === null){
            window.onbeforeunload = null;
            if(this.state.files.length > 0){
                const source = axios.CancelToken.source();
                that.setState({
                    cancelToken: source
                });
                const { zipUpload, response, zipName, zipPassword, notifySender, path, groupNotifySender, usersChecked, sharedFileId } = this.state;
                const requestData = {
                    zipUpload: (zipUpload ? 1 : 0)
                };
                if(zipName !== null){
                    requestData['zipName'] = zipName;
                }
                if(zipPassword !== null){
                    requestData['zipPassword'] = zipPassword;
                }
                if(notifySender){
                    requestData['notifySender'] = (notifySender ? 1 : 0);
                }
                if(sharedFileId !== null || sharedFileId !== ''){
                    requestData['sharedFileId'] = sharedFileId;
                }
                if(groupNotifySender && usersChecked.length > 0){
                    requestData['groupNotifySender'] = usersChecked;
                }
                if(path){
                    requestData['path'] = path;
                }

                Api.processMedia(response.hash, source, true, requestData).then(data => {
                    if(data.hasOwnProperty("user") && this.props.isHome){
                        this.setState({
                            uploading: false,
                            success: true
                        }, () => {
                            localStorage.setItem("sendlinx_userinfo", JSON.stringify(data.user));
                            let newUser = Api.prepareMemberObject(data.user);
                            this.store.dispatch({ type: 'UPDATE_STATE', state: {
                                user: newUser,
                                customState: {
                                    response: this.state.response,
                                    expire: this.state.expire, 
                                    zipPassword: this.state.zipPassword,
                                    files: this.state.files,
                                    sharedWith: this.state.sharedWith,
                                    sharedFileId: this.state.sharedFileId,
                                    type: "upload",
                                    totalFiles: this.state.totalFiles
                                }
                            }});
                        });
                    }else{
                        this.setState({
                            uploading: false,
                            success: true
                        })
                    }
                }).catch(err => {
                    this.setState({
                        uploading: false,
                        success: true
                    });
                });
            }else{
                this.setState({
                    uploading: false,
                    success: true
                });
            }
            return;
        }

        if(this.isMultiPartSupported(fileData.localFile)){
            try{
                this.partNumber = 0;
                this.chunksSent = 0;
                const source = axios.CancelToken.source();
                this.uploadMultiPart(files, fileData, key, source);
                this.partNumber = this.partNumber + 1;
            }catch(e){
                console.log(e);
            }
        }else{
            const source = axios.CancelToken.source();
            that.setState({
                cancelToken: source,
                showError: false,
                errorMessage: ""
            });
            Api.createMedia(fileData.localFile, fileData.uploadedFile, source, this.onUploadProgress, true).then(data => {
                this.updateFileStatus(fileData);

                const totalSent = that.state.totalSent + fileData.localFile.size;
                that.setState({
                    totalSent: totalSent,
                    singleSent: 0,
                });
                that.uploadFiles(files, ++key);
            }).catch(err => {
                if(typeof(err) === "object" && err.hasOwnProperty("message") && err.message !== 'Request Cancelled'){
                    this.setState({
                        showError: true,
                        errorMessage: err.message
                    });
                }
            });
        }
    }
    uploadMultiPart(files, fileData, key, source){
        const blob = this.getPartContent(fileData.localFile);
        const requestData = {
            partNumber: (this.partNumber + 1),
            chunkSize: blob.size,
            id: fileData.uploadedFile.id
        };
        this.setState({
            cancelToken: source,
            showError: false,
            errorMessage: "",
            resumableKey: key,
            resumable: false,
            uploading: true
        });
        
        const that = this;
        var chunks = this.getTotalChunks(fileData.localFile);
        const { chunkSize } = this.state;
        Api.getPartUrl(fileData.localFile, blob, requestData, source, this.onUploadProgress, true).then(data => {
            this.chunksSent = this.chunksSent + chunkSize;
            if(this.partNumber < chunks){
                source = axios.CancelToken.source();
                that.setState({
                    totalSent: that.state.totalSent + blob.size,
                    singleSent: 0,
                    cancelToken: source,
                    showError: false,
                    errorMessage: ""
                });
                this.uploadMultiPart(files, fileData, key, source);
                this.partNumber = this.partNumber + 1;
            }else{
                const source = axios.CancelToken.source();
                that.setState({
                    totalSent: that.state.totalSent + blob.size,
                    singleSent: 0,
                    cancelToken: source,
                    showError: false,
                    errorMessage: ""
                });
                that.finalizeMultiPart(files, fileData, key, source);
            }
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message") && err.message !== 'Request Cancelled'){
                let state = {
                    showError: true,
                    errorMessage: err.message
                };
                if(err.message === "Network Error"){
                    state['resumable'] = true;
                }
                this.setState(state);
            }
        });
    }
    finalizeMultiPart(files, fileData, key, source){
        const requestData = {
            chunks: this.getTotalChunks(fileData.localFile),
            id: fileData.uploadedFile.id
        };
        Api.finalizeMultiPart(requestData, source, true).then(data => {
            this.updateFileStatus(fileData);
            this.uploadFiles(files, ++key);
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message") && err.message !== 'Request Cancelled'){
                this.setState({
                    showError: true,
                    errorMessage: err.message
                });
            }
        });
    }
    getNextFile(files, key){
        if(!files.hasOwnProperty(key)){
            return null;
        }

        const uploadedFile = files[key];
        let localFile = null;

        this.state.files.map(item => {
            if(item.type === "folder"){
                item.files.map(subItem => {
                    let file = subItem;
                    if(item.hasOwnProperty("checkRelativePath")){
                        file = subItem.file;
                    }
                    let filePath = file.webkitRelativePath;
                    if(item.hasOwnProperty("checkRelativePath")){
                        filePath = subItem.webkitRelativePath;
                    }
                    if(filePath === uploadedFile.path+"/"+uploadedFile.localName){
                        localFile = file;
                        return false;
                    }
                    return null;
                });
            }else if(item.file.name === uploadedFile.localName){
                localFile = item.file;
                return false;
            }
            return null;
        });
        const fileObject = {
            localFile: localFile,
            uploadedFile: uploadedFile
        }
        return fileObject;
    }
    updateFileStatus(fileData){
        if(fileData === null){
            return;
        }

        this.state.files.map(item => {
            if(item.type === "folder"){
                item.files.map(subItem => {
                    let file = subItem;
                    if(item.hasOwnProperty("checkRelativePath")){
                        file = subItem.file;
                    }
                    let filePath = file.webkitRelativePath;
                    if(item.hasOwnProperty("checkRelativePath")){
                        filePath = subItem.webkitRelativePath;
                    }
                    if(filePath === fileData.uploadedFile.path+"/"+fileData.uploadedFile.localName){
                        file.uploaded = true;
                        return false;
                    }

                    return null;
                });
            }else if(item.file.name === fileData.uploadedFile.localName){
                item.file.uploaded = true;
                return false;
            }
            return null;
        });
    }
    onFileDelete(key){
        let filesArray = this.state.files;
        filesArray.splice(key, 1);
        this.onFilesSelect(filesArray);
        this.setState({
            files: filesArray
        });
    }
    getDownloadLink(){
        const { response } = this.state;
        const location = window.location;
        let downloadLink = location.protocol+"//"+location.host+"/downloads";
        if(response !== null){
            downloadLink += "/"+response.hash;
        }
        return downloadLink;
    }
    onUploadProgress(e){
        this.setState({
            singleSent: e.loaded
        });
    }
    handleCheckbox(e, name){
        const { groupNotifySender } = this.state;
        let state = {};
        state[name] = e.target.checked;
        if(name === 'groupNotifySender' && groupNotifySender === false){
            state['groupMembersModal'] = true;
        }else if(name === 'groupNotifySender' && groupNotifySender === true){
            state['usersChecked'] = []
        }

        if(name === 'generateLink'){
            state['expire'] = '';
        }

        this.setState(state);
    }
    handleChange(e, name){
        let state = {};
        state[name] = e.target.value;
        this.setState(state);
    }
    handleDateChange(date, name) {
        try{
            let parsedDate = new Date(date);
            if(parsedDate === "Invalid Date"){
                return;
            }
            this.setState({
                [name]: parsedDate.toISOString()
           });
        }catch(e){ console.log(e); }        
    }
    renderFile(item, key, type = 'selected'){     
        const { classes } = this.props;

        let  folderSize = 0;
        if(item.type === "folder"){
            item.files.map(subItem => {
                let file = subItem;
                if(item.hasOwnProperty("checkRelativePath")){
                    file = subItem.file;
                }
                return folderSize += file.size;
            });
        }
        if(item.type === "file"){
            return <li key={key}>
                <div className="file">
                    <div className="file-header">
                        <div className="file-details">
                            <span className={"file-icon"}>
                                { helper.getFileIcon(item.file, classes, true) }
                            </span>
                            <span className="file-detail">
                                <span className="file-name">{item.file.name}</span>
                                <span className="file-info">
                                    <span className="file-type"></span>
                                    <span className="file-size">{helper.getFormatedSize(item.file.size)}</span>
                                </span>
                            </span>
                        </div>
                        <div className="file-actions">
                            <div className={"filelist-action filelist-action-delete "+(type === 'uploading' ? 'uploading-action' : '')}>
                                {
                                    type === 'uploading' ?
                                        item.file.hasOwnProperty('uploaded') && item.file.uploaded ?
                                            <CheckCircle className={classes.icons}/>
                                        :
                                            <LoaderComponent color="custom" align="center" size={20} />
                                    :
                                        <svg onClick={() => this.onFileDelete(key)} width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.74308 1.125H10.2531C10.4618 1.125 10.6554 1.22344 10.7692 1.38867L11.3498 2.25H5.65022L6.2308 1.38867C6.34085 1.22344 6.53817 1.125 6.74688 1.125H6.74308ZM12.7842 2.25L11.8013 0.794531C11.4674 0.298828 10.883 0 10.2569 0H6.74308C6.11696 0 5.53259 0.298828 5.19866 0.794531L4.21585 2.25H2.43237H1.21429H0.607143C0.273214 2.25 0 2.50312 0 2.8125C0 3.12188 0.273214 3.375 0.607143 3.375H1.30156L2.2692 15.9117C2.35647 17.0895 3.41518 18 4.69018 18H12.3098C13.5848 18 14.6397 17.0895 14.7308 15.9117L15.6984 3.375H16.3929C16.7268 3.375 17 3.12188 17 2.8125C17 2.50312 16.7268 2.25 16.3929 2.25H15.7857H14.5676H12.7842ZM14.4842 3.375L13.5203 15.8309C13.4748 16.418 12.9473 16.875 12.3098 16.875H4.69018C4.05268 16.875 3.52522 16.418 3.47969 15.8309L2.51964 3.375H14.4804H14.4842Z" />
                                        </svg>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </li>;
        }
        return <li key={key}>
            <div className="file">
                <div className="file-header">
                    <div className="file-details">
                        <span className={"file-icon"}>
                            <Button justIcon color="transparent" className={classes.fileIcon}>
                                { helper.getFolderIcon() }
                            </Button>
                        </span>
                        <span className="file-detail">
                            <span className="file-name">{item.name} ({item.files.length} items)</span>
                            <span className="file-info">
                                <span className="file-type"></span>
                                <span className="file-size">{helper.getFormatedSize(folderSize)}</span>
                            </span>
                        </span>
                    </div>
                    <div className="file-actions">
                        <div className={"filelist-action filelist-action-delete "+(type === 'uploading' ? 'uploading-action' : '')}>
                            {
                                type === 'uploading' ?
                                    item.files.every(file => file.file?.uploaded === true) ?
                                        <CheckCircle className={classes.icons}/>
                                    :
                                        <LoaderComponent color="custom" align="center" size={20} />
                                :
                                    <svg onClick={() => this.onFileDelete(key)} width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.74308 1.125H10.2531C10.4618 1.125 10.6554 1.22344 10.7692 1.38867L11.3498 2.25H5.65022L6.2308 1.38867C6.34085 1.22344 6.53817 1.125 6.74688 1.125H6.74308ZM12.7842 2.25L11.8013 0.794531C11.4674 0.298828 10.883 0 10.2569 0H6.74308C6.11696 0 5.53259 0.298828 5.19866 0.794531L4.21585 2.25H2.43237H1.21429H0.607143C0.273214 2.25 0 2.50312 0 2.8125C0 3.12188 0.273214 3.375 0.607143 3.375H1.30156L2.2692 15.9117C2.35647 17.0895 3.41518 18 4.69018 18H12.3098C13.5848 18 14.6397 17.0895 14.7308 15.9117L15.6984 3.375H16.3929C16.7268 3.375 17 3.12188 17 2.8125C17 2.50312 16.7268 2.25 16.3929 2.25H15.7857H14.5676H12.7842ZM14.4842 3.375L13.5203 15.8309C13.4748 16.418 12.9473 16.875 12.3098 16.875H4.69018C4.05268 16.875 3.52522 16.418 3.47969 15.8309L2.51964 3.375H14.4804H14.4842Z" />
                                    </svg>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </li>;
    }
    handleToggle(user) {
        const { usersChecked } = this.state;
        const currentIndex = usersChecked.indexOf(user.id);
        const newChecked = [...usersChecked];
    
        if (currentIndex === -1) {
            newChecked.push(user.id);
        } else {
            newChecked.splice(currentIndex, 1);
        }
    
        this.setState({
            usersChecked: newChecked,
        });
    }
    getTrProps(state, rowInfo){
        if (rowInfo && rowInfo.row) {
            return {
                onClick: (e) => this.handleToggle(rowInfo.original),
            }
        }else{
            return {}
        }
    }
    getNoDataProps(){
        return { style: { display: 'none' } };
    }
    getColumns(){
        let columns = [
            {
                Header: "",
                accessor: "check",
                sortable: false,
                filterable: false,
                headerClassName: "hd_check",
                className: "hd_check td_check",
                resizable: false,
            },
            {
                Header: "First Name",
                accessor: "firstName",
                headerClassName: "hd_name",
                className: "hd_name td_name",
            },
            {
                Header: "Last Name",
                accessor: "lastname",
                headerClassName: "hd_name",
                className: "hd_name td_name",
            },
            {
                Header: "Email Address",
                accessor: "email",
                headerClassName: "hd_email",
                className: "hd_email td_email",
            }
        ];

        return columns;
    }
    getTableData(){
        const { classes } = this.props;
        const { sharedWith, usersChecked } = this.state;

        if(sharedWith === null){
            return [];
        }
        let tableData = [];

        sharedWith.map(user => {
            if(!usersChecked.includes(user.user_id)){
                return null;
            }
            let userArray = {
                id: user.user_id,
                check: (
                    <Checkbox
                        tabIndex={-1}
                        checked={usersChecked.includes(user.user_id)}
                        className={classes.positionAbsolute}
                        onClick={() => this.handleToggle(user)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                        }}
                    />
                ),
                firstName: user.first_name,
                lastName: user.last_name,
                email: user.email,
            };
            
            tableData.push(userArray);
            return null;
        });
        return tableData;
    }
    onViewPassword(){
        this.setState({
            viewPassword: !this.state.viewPassword
        })
    }
    renderForm(){
        const { files, maxFileUploadSize, notifySender, groupNotifySender, usersChecked, sharedFileId, ownerNotification, 
            groupNotification, uploadType } = this.state;
        const { classes } = this.props;
        const { user } = this.store.getState();

        const filesCount = this.getFilesCount();
        const remainingSize = this.getRemainingSize();
        return(
            <div className={classes.uploadContainer}>
                <div className={classes.fileSendingForm}>
                    <div className={classes.dragndropSection}>
                        <DragNDrop onDrop={(files) => this.onDrop(files)}>
                            <CustomFileUpload
                                id="outlined-fileUpload"
                                multiple={true}
                                onChange={(files) => this.onFilesSelect(files)}
                                files={files}
                                store={this.store}
                                uploadFileModal={true}
                                uploadType={uploadType}
                            />
                        </DragNDrop>
                    </div>
                    {
                        files.length > 0 ?
                            <div className={classes.uploaderList+" uploader-list"}>
                                <span className="uploader-add-files-count">
                                    <span>{filesCount} {filesCount > 1 ? "items" : "item"} added</span>
                                    <span className="remaining">{helper.getFormatedSize(remainingSize)} remaining</span>
                                </span>
                                <ul className={classes.filesList}>
                                    <p className="title">Uploaded Files</p>
                                    {
                                        files.map((item, key) => (
                                            this.renderFile(item, key)
                                        ))
                                    }
                                </ul>
                            </div>
                        :
                            <></>
                    }
                    {
                        sharedFileId !== null ?
                            <>
                                <div>
                                    {
                                        ownerNotification === 'optional' || ownerNotification === 'mandatory' ?
                                            <FormControlLabel
                                                classes={{label: classes.label}}
                                                control={
                                                    <Checkbox
                                                        tabIndex={-1}
                                                        onClick={(e) => this.handleCheckbox(e, "notifySender")}
                                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                                        icon={<Check className={classes.uncheckedIcon} />}
                                                        classes={{checked: classes.checked, root: classes.checkRoot}}
                                                        checked={notifySender}
                                                        disabled={(ownerNotification === 'mandatory' ? true : false)}
                                                    />
                                                }
                                                label="Notify owner"
                                            />
                                        :
                                            <></>
                                    }
                                    {
                                        groupNotification === 'optional' || groupNotification === 'mandatory' ?
                                            <FormControlLabel
                                                classes={{label: classes.label}}
                                                control={
                                                    <Checkbox
                                                        tabIndex={-1}
                                                        onClick={(e) => this.handleCheckbox(e, "groupNotifySender")}
                                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                                        icon={<Check className={classes.uncheckedIcon} />}
                                                        classes={{checked: classes.checked, root: classes.checkRoot}}
                                                        checked={groupNotifySender}
                                                        disabled={(groupNotification === 'mandatory' ? true : false)}
                                                    />
                                                }
                                                label="Notify Group Members"
                                            />
                                        :
                                            <></>
                                    }
                                </div>
                                <div>
                                    {
                                        groupNotification === 'optional' && groupNotifySender === true ?
                                            <Button color="white" className={classes.addBtn} onClick={() => this.onGroupMembersModal(true)}>
                                                <AddIcon />
                                                Add Members
                                            </Button>
                                        :
                                            <></>
                                    }
                                    {
                                        groupNotification === 'optional' && usersChecked.length > 0 ?
                                            <ReactTable
                                                columns={this.getColumns()}
                                                data={this.getTableData()}
                                                pageSizeOptions={[500]}
                                                defaultPageSize={50000000000}
                                                showPaginationTop={false}
                                                minRows={0}
                                                showPaginationBottom={false}
                                                className={"-striped -highlight "+classes.filesTable}
                                                getTrProps={(state, rowInfo) => this.getTrProps(state, rowInfo)}
                                                getNoDataProps={() => this.getNoDataProps()}
                                            />
                                        :
                                            <></>
                                    }
                                </div>
                            </>
                        :
                        <></>
                    }
                    {
                        (user.quota - user.usage) < this.getTotalSize() ?
                            <div className={classes.storageExceedMessage}>
                                <p>Your upload exceeds the maximum allowed quota of {helper.getFormatedSize(user.quota)}. Your current remaining storage is {helper.getFormatedSize(user.quota - user.usage)}.</p>
                            </div>
                        :
                            <></>
                    }
                    {
                        this.getTotalSize() > maxFileUploadSize ?
                            <div className={classes.storageExceedMessage}>
                                <p>Your file exceeds to {helper.getFormatedSize(maxFileUploadSize)}. Max limit per file size is {helper.getFormatedSize(maxFileUploadSize)}.</p>
                            </div>
                        :
                            <></>
                    }
                </div>
            </div>
        )
    }
    renderZipFileForm(){
        const { zipName, zipUpload, zipPassword, expire, viewPassword, generateLink } = this.state;
        const { classes } = this.props;

        return(
            <div className={classes.uploadContainer}>
                <div className={classes.fileSendingForm}>
                    {
                        zipUpload ?
                            <>
                                <CustomInput
                                    formControlProps={{
                                        fullWidth: true,
                                        className: "custom-input m-zero"
                                    }}
                                    id="input-zipName"
                                    labelText="Zip Name"
                                    inputProps={{
                                        onChange: (e) => this.handleChange(e,'zipName'),
                                        name: "zipName",
                                        type: "text",
                                        value: zipName,
                                    }}
                                />
                                <CustomInput
                                    formControlProps={{
                                        fullWidth: true,
                                        className: "custom-input"
                                    }}
                                    id="input-zipPassword"    
                                    passwordInput={viewPassword ? false : true}   
                                    labelText="Zip Password"
                                    inputProps={{
                                        onChange: (e) => this.handleChange(e,'zipPassword'),
                                        name: "zipPassword",
                                        type: "text",
                                        value: zipPassword,
                                        autoComplete: 'off',
                                        endAdornment: (
                                            <InputAdornment 
                                                className={classes.inputAdornment+' viewPasswordIcon'} 
                                                onClick={() => this.onViewPassword() }
                                                position='end'
                                            >
                                                {
                                                    zipPassword ?
                                                        viewPassword ?
                                                            <VisibilityOffIcon className={classes.inputAdornmentIcon}/>
                                                        :
                                                            <VisibilityIcon className={classes.inputAdornmentIcon}/>
                                                    :
                                                        <></>
                                                }
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <p className="note">Anyone who downloads this file will need the password to open it on their computer.</p>
                                <FormControlLabel
                                    classes={{label: classes.label}}
                                    control={
                                        <Switch
                                            checked={generateLink}
                                            onChange={(e) => this.handleCheckbox(e, "generateLink")}
                                            classes={{
                                                switchBase: classes.switchBase,
                                                checked: classes.switchChecked,
                                                thumb: classes.switchIcon,
                                                track: classes.switchBar
                                            }}
                                        />
                                    }
                                    label={"Generate Link"}
                                /> 
                                <p className="note">Turn this on to create a shareable link for the ZIP file you're uploading.</p>
                                {
                                    generateLink ? 
                                        <DateTimePicker
                                            labelText="Expiry Date/Time"
                                            id="input-expire"
                                            value={expire}
                                            onChange={(date) => this.handleDateChange(date, 'expire')}
                                            formControlProps={{
                                                fullWidth: true,
                                                className: 'custom-input'
                                            }}
                                            disablePast={true}
                                        />
                                    :
                                        <></>
                                }
                            </>
                        :
                            <></>
                    }
                </div>
            </div>
        )
    }
    formatEstimatedTime(expectedTime){
        if(expectedTime <= 2){
            return "Few Seconds";
        }
        if(expectedTime < 60){
            expectedTime = Math.trunc(expectedTime);
            return expectedTime+" Seconds";
        }
        let minutes = expectedTime/60;
        if(minutes < 60){
            const seconds = Math.round(expectedTime%60);
            minutes = Math.trunc(minutes);
            return (minutes+" Minutes"+(seconds ? (" "+seconds+" Seconds") : ""));
        }
        expectedTime = expectedTime/60;
        let hours = expectedTime/60;
        minutes = Math.round(expectedTime%60);
        hours = Math.trunc(hours);
        return (hours+" Hours"+(minutes ? (" "+minutes+" Minutes") : ""));
    }
    getEstimatedTimeLeft(){
        const { startTime, totalSize, totalSent, singleSent } = this.state;
        const currentTime = new Date();
        const timeElapsed = (currentTime - startTime)/1000;
        const totalUploaded = totalSent + singleSent;
        if(totalUploaded <= 0){
            return null;
        }
        let uploadRate = (totalUploaded/timeElapsed);
        const expectedTime = (totalSize - totalUploaded)/uploadRate;
        const timeEstimate = this.formatEstimatedTime(expectedTime);
        
        return timeEstimate;
    }
    renderUploading(){
        const { totalSize, totalSent, singleSent, totalFiles, showError, errorMessage, resumable, files } = this.state;
        const { classes } = this.props;
        
        const totalUploaded = totalSent + singleSent;
        let progress = 0;
        if(totalSize > 0){
            progress = (totalUploaded/totalSize)*100;
        }
        const roundedValue = Math.round(progress);
        const estimateTimeLeft = this.getEstimatedTimeLeft();
        return (
            <div className={classes.uploadFilesModal}>
                <div className={classes.modalHeader}>
                    <Button
                        simple
                        className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                        key="close"
                        aria-label="Close"
                        onClick={this.cancelUpload}
                    >
                        {" "}
                        <Close className={classes.modalClose} />
                    </Button>
                    <h4 className={classes.modalTitle}>
                        Uploading {totalFiles}{totalFiles > 1 ? ' Files' : ' File'}
                    </h4>
                </div>
                {
                    showError ?
                        <GridItem>
                            <Danger>{errorMessage}</Danger>
                        </GridItem>
                    :
                        <>
                            <div className="upload-files-section">
                                <div className={'files-listings'}>
                                    <ul className={classes.filesList}>
                                        {
                                            files.map((item, key) => (
                                                this.renderFile(item, key, 'uploading')
                                            ))
                                        }
                                    </ul>
                                </div>
                                {
                                    resumable ?
                                        <Button color="custom" className="transfer-button" type="button" onClick={this.resumeUpload} >Resume</Button>
                                    :
                                        <></>
                                }
                            </div>
                            <div className="time-left-section">
                                <div className="time-left-content">
                                    {
                                        estimateTimeLeft !== null ?
                                            <p>{estimateTimeLeft} Left</p>
                                        :
                                            <p>Calculating</p>
                                    }
                                    <p>{`${roundedValue}%`}</p>
                                </div>
                                <div className="progress-bar">
                                    <span className="progress-width" style={{'width' : `${roundedValue}%`}}></span>
                                </div>
                            </div>
                        </>
                }
            </div>
        );
    }
    renderSuccess(){
        const { classes } = this.props;
        const { response, expire, zipPassword, totalFiles } = this.state;
        
        if(expire.length > 0){
            const downloadLink = "/downloads/"+response.hash;
            return (
                <NewLinkModal 
                    open={true} 
                    saving={false} 
                    onClose={() => this.props.onClose()} 
                    onSuccess={() => this.props.onClose()}
                    onLinkName={(name, linkPassword) => this.props.onClose()}
                    loadingMessage="Please wait while we prepare your download link. It may take a few minutes depending on the size and number of files."
                    successMessage={'Link Ready for Sharing' }
                    errorMessage={null}
                    downloadLink={downloadLink}
                    linkName={response.title}
                    store={this.store}
                    linkHash={response.hash}
                    expire={expire}
                    linkPassword={zipPassword}
                    uploadFileModal={true}
                    totalFiles={totalFiles}
                    sendAnother={() => this.sendAnother()}
                />
            );
        }
        
        return (
            <div className={classes.uploadFilesModal}>
                <div className={classes.modalHeader}>
                    <Button
                        simple
                        className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.props.onClose()}
                    >
                        {" "}
                        <Close className={classes.modalClose} />
                    </Button>
                    <div className="success-title-section">
                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_1023_5761)">
                                <path d="M14 28C17.713 28 21.274 26.525 23.8995 23.8995C26.525 21.274 28 17.713 28 14C28 10.287 26.525 6.72601 23.8995 4.10051C21.274 1.475 17.713 0 14 0C10.287 0 6.72601 1.475 4.10051 4.10051C1.475 6.72601 0 10.287 0 14C0 17.713 1.475 21.274 4.10051 23.8995C6.72601 26.525 10.287 28 14 28ZM20.1797 11.4297L13.1797 18.4297C12.6656 18.9438 11.8344 18.9438 11.3258 18.4297L7.82578 14.9297C7.31172 14.4156 7.31172 13.5844 7.82578 13.0758C8.33984 12.5672 9.17109 12.5617 9.67969 13.0758L12.25 15.6461L18.3203 9.57031C18.8344 9.05625 19.6656 9.05625 20.1742 9.57031C20.6828 10.0844 20.6883 10.9156 20.1742 11.4242L20.1797 11.4297Z" fill="#53E13C"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_1023_5761">
                                    <rect width="28" height="28" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                        <div className="success-title">
                            <h4 className={classes.modalTitle}>
                                Success!
                            </h4>
                            <p>{totalFiles} of {totalFiles} uploades complete</p>
                        </div>
                    </div>
                </div>
                <div className={classes.uploaderComplete}>
                    <div className={classes.uploaderCompleteContent}>
                        <div className="success-image">
                            <img src={successGif} alt="success"></img>
                        </div>
                    </div>
                    <div className={classes.uploadingSuccessFooter}>
                        <Button color="white" className="transfer-button" type="button" onClick={this.sendAnother} >Upload another</Button>
                        <Button color="custom" className="transfer-button" type="button" onClick={() => this.props.onClose()} >Done</Button>
                    </div>
                </div>
            </div>
        );
    }
    handleSelectiveAction(value, name){
        let { duplicates, selectivePendingAction, selectiveUploadedAction } = this.state;
        let state = {};
        state[name] = value;
        if(value === "overwrite"){
            state['keepPendingFiles'] = [];
            if(selectivePendingAction === value){
                state[name] = null;
            }else{
                duplicates.map((file) => {
                    state['keepPendingFiles'].push(file.pending.name);
                    return null;
                });
            }
        }else{
            state['keepUploadedFiles'] = [];
            if(selectiveUploadedAction === value){
                state[name] = null;
            }else{
                duplicates.map((file) => {
                    state['keepUploadedFiles'].push(file.uploaded.name);
                    return null;
                });
            }
        }
        this.setState(state);
    }
    handleFileAction(type, file){
        const { keepPendingFiles, keepUploadedFiles, duplicates } = this.state;
        const pendingIndex = keepPendingFiles.indexOf(file.pending.name);
        const uploadedIndex = keepUploadedFiles.indexOf(file.uploaded.name);
        let state = {};
        if(type === "pending"){
            if (pendingIndex === -1) {
                keepPendingFiles.push(file.pending.name);
            } else {
                keepPendingFiles.splice(pendingIndex, 1);
                state['selectivePendingAction'] = null;
            }
            if(keepPendingFiles.length === duplicates.length){
                state['selectivePendingAction'] = "overwrite";
            }
        }else{
            if (uploadedIndex === -1) {
                keepUploadedFiles.push(file.uploaded.name);
            } else {
                keepUploadedFiles.splice(uploadedIndex, 1);
                state['selectiveUploadedAction'] = null;
            }
            if(keepUploadedFiles.length === duplicates.length){
                state['selectiveUploadedAction'] = "skip";
            }
        }
        this.setState({
            ...state,
            keepPendingFiles: keepPendingFiles,
            keepUploadedFiles: keepUploadedFiles
        });
    }
    getFormatedDateTime(date){
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        let hours = date.getHours();
        const mins = date.getMinutes();
        var a = ( hours >= 12 ) ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        const dateTime = year+"-"+month+"-"+day+" "+hours+":"+mins+" "+a;
        return dateTime;
    }
    onSelectionCancel(){
        this.setState({
            duplicateAction: null,
        });
    }
    onSelectionConfirm(){
        const { keepPendingFiles, keepUploadedFiles, duplicates } = this.state;
        let isValid = true;
        duplicates.map((file) => {
            if(!keepPendingFiles.includes(file.pending.name) && !keepUploadedFiles.includes(file.uploaded.name)){
                isValid = false;
            }
            return null;
        });

        if(!isValid){
            return;
        }

        this.sendFiles();
    }
    renderDuplicates(){
        const { duplicates, path, files, duplicateAction, selectivePendingAction, keepPendingFiles, keepUploadedFiles, selectiveUploadedAction } = this.state;
        const { classes } = this.props;
        let pathArray = [];
        let folderName = null;
        if(typeof(path) === "string" && path.trim().length){
            pathArray = path.split("/");
            folderName = pathArray[pathArray.length - 1];
        }
        const date = new Date();
        const dateTime = this.getFormatedDateTime(date);

        if(duplicates !== null && duplicateAction === "selective"){
            return (
                <div className={classes.duplicateSelection}>
                    <p>File(s) with that name already exist(s). Please select which one(s) you would like to keep.</p>
                    <p>If you select both versions, the copied file will have number added to its name.</p>
                    <div className={classes.selectiveCheckboxes}>
                        <FormControlLabel
                            classes={{label: classes.label}}
                            control={
                                <Checkbox
                                    tabIndex={-1}
                                    onClick={(e) => this.handleSelectiveAction("overwrite", "selectivePendingAction")}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    classes={{checked: classes.checked, root: classes.checkRoot}}
                                    checked={selectivePendingAction === "overwrite"}
                                />
                            }
                            label={"File(s) pending upload"}
                        />
                        <FormControlLabel
                            classes={{label: classes.label}}
                            control={
                                <Checkbox
                                    tabIndex={-1}
                                    onClick={(e) => this.handleSelectiveAction("skip", "selectiveUploadedAction")}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    classes={{checked: classes.checked, root: classes.checkRoot}}
                                    checked={selectiveUploadedAction === "skip"}
                                />
                            }
                            label={"File(s) already in folder ("+folderName+")"}
                        />
                    </div>
                    <ul>
                        {
                            duplicates.map((file, key) => {
                                return (
                                    <li key={key}>
                                        <div className={classes.duplicateFileName}>{file.pending.name}</div>
                                        <div className={classes.duplicateFile}>
                                            <FormControlLabel
                                                classes={{label: classes.label}}
                                                control={
                                                    <Checkbox
                                                        tabIndex={-1}
                                                        onClick={(e) => this.handleFileAction("pending", file)}
                                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                                        icon={<Check className={classes.uncheckedIcon} />}
                                                        classes={{checked: classes.checked, root: classes.checkRoot}}
                                                        checked={keepPendingFiles.includes(file.pending.name)}
                                                    />
                                                }
                                                label={(
                                                    <div>
                                                        {dateTime}
                                                        <div>{helper.getFormatedSize(file.pending.size)}</div>
                                                    </div>
                                                )}
                                            />
                                            <FormControlLabel
                                                classes={{label: classes.label}}
                                                control={
                                                    <Checkbox
                                                        tabIndex={-1}
                                                        onClick={(e) => this.handleFileAction("uploaded", file)}
                                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                                        icon={<Check className={classes.uncheckedIcon} />}
                                                        classes={{checked: classes.checked, root: classes.checkRoot}}
                                                        checked={keepUploadedFiles.includes(file.uploaded.name)}
                                                    />
                                                }
                                                label={(
                                                    <div>
                                                        {this.getFormatedDateTime(new Date(file.uploaded.updated_at))}
                                                        <div>{helper.getFormatedSize(file.uploaded.size)}</div>
                                                    </div>
                                                )}
                                            />
                                        </div>
                                    </li>
                                )
                            })
                        }
                    </ul>
                    <div className={classes.selectionButtons}>
                        <Button color="custom" onClick={() => this.onSelectionConfirm()}>Continue</Button>
                        <Button color="white" onClick={() => this.onSelectionCancel()}>Cancel</Button>
                    </div>
                </div>
            )
        }
        return (
            <div className={classes.duplicates}>
                <h4>
                    Uploading {files.length} files
                    {
                        folderName !== null ?
                            " to "+folderName
                        :
                        <></>
                    }
                </h4>
                <p>The destination has {files.length} file(s) or folder(s) with the same names</p>
                <ul>
                    <li className="active" onClick={() => this.onDuplicateFileAction("replace")}>
                        <MaterialIcon path={mdiCheck} className={classes.checkIcon+" MuiSvgIcon-root"} />
                        Replace the files in the destination
                    </li>
                    <li onClick={() => this.onDuplicateFileAction("skip")}>
                        <MaterialIcon path={mdiUndoVariant} className={classes.skipIcon+" MuiSvgIcon-root"} />
                        Skip these files
                    </li>
                    <li onClick={() => this.onDuplicateFileAction("selective")}>
                        <MaterialIcon path={mdiFileCheckOutline} className="MuiSvgIcon-root" />
                        Let me decide for each file
                    </li>
                </ul>
            </div>
        )
    }
    onDuplicateFileAction(action){
        if(action === "replace" || action === "skip"){
            this.setState({duplicateAction: action, duplicatesFound: false}, () => {
                this.sendFiles();
            });
            return;
        }

        this.setState({
            duplicateAction: action,
        });
    }
    onGroupMembersModal(status = false){
        this.setState({
            groupMembersModal: status,
        });
    }
    onGroupMembersModalSuccess(usersChecked){
        this.setState({
            usersChecked: usersChecked,
            groupMembersModal: false,
        })
    }
    hasFile(fileName, filesArray){
        let hasFile = false;
        if(filesArray.length > 0){
            filesArray.map((item, key) => {
                if(item.type === 'file' && item.file.name === fileName){
                    hasFile = true;
                }
                return null;
            })
        }

        return hasFile;
    }
    hasFolder(folderName, filesArray){
        let hasFolder = false;
        if(filesArray.length > 0){
            filesArray.map((item, key) => {
                if(item.type === 'folder' && item.name === folderName){
                    hasFolder = true;
                }
                return null;
            })
        }

        return hasFolder;
    }
    onDrop(filesArray){
        let { files } = this.state;
        let duplicateFileModal = false;

        filesArray.map(file => {
            let hasFile = false;
            let hasFolder = false;

            if(file.type === 'file'){
                hasFile = this.hasFile(file.file.name, files);
            }else if(file.type === 'folder'){
                hasFolder = this.hasFolder(file.name, files);
            }
            if(hasFile === true || hasFolder === true){
                duplicateFileModal = true;
                
                return null;
            }
            files.push(file);

            return null;
        });

        this.setState({
            files: files,
            duplicateFileModal: duplicateFileModal
        });
    }
    onDuplicateFileModal(status = false){
        this.setState({
            duplicateFileModal: status,
        })
    }
    showSection(name){
        this.setState({
            section: name
        })
    }
    render() {
        const { uploading, success, duplicatesFound, duplicates, duplicateAction, sharedWith, groupMembersModal, usersChecked,
        duplicateFileModal, files, zipUpload, maxFileUploadSize, section } = this.state;

        const { classes } = this.props;
        const { user } = this.store.getState();

        if(uploading){
            return this.renderUploading();
        }else if(success){
            return this.renderSuccess();
        }

        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal+" "+ ((duplicatesFound && duplicates !== null && duplicateAction === "selective") ? classes.modalDuplicates : "")
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="uploadFile-slide-title"
                aria-describedby="uploadFile-slide-description"
            >
                <DialogTitle
                    id="uploadFile-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                >
                    <Button
                        simple
                        className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.props.onClose()}
                    >
                        {" "}
                        {
                            uploading === false ?
                                <Close className={classes.modalClose} />
                            :
                                <></>
                        }
                    </Button>
                    <h4 className={classes.modalTitle}>
                        {
                            duplicatesFound && duplicates !== null ?
                                duplicateAction === "selective" ?
                                    duplicates.length+" Total File Conflict(s)"
                                :
                                "Replace or Skip Files"
                            :
                                "Upload Files"
                        }
                    </h4>
                </DialogTitle>
                <DialogContent
                    id="uploadFile-slide-description"
                    className={classes.modalBody}
                    >
                        {
                            duplicatesFound && duplicates !== null ?
                                this.renderDuplicates()
                            : section === 'zip' && zipUpload ?
                                this.renderZipFileForm()
                            :
                                this.renderForm()
                        }
                </DialogContent>
                {
                    (duplicatesFound && duplicates !== null) || uploading || success ?
                        <></>
                    :
                        <DialogActions className={classes.modalFooter}>
                            {
                                section === 'zip' && zipUpload ? 
                                    <Button color="white" onClick={() => this.showSection('files')} className={(files.length === 0 || (user.quota - user.usage) < this.getTotalSize() || (this.getTotalSize() > maxFileUploadSize) ? 'disable-btn': classes.sendButton)} type="button" disabled={(files.length === 0 || (user.quota - user.usage) < this.getTotalSize())}>
                                        <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.146875 5.64697C-0.046875 5.84072 -0.046875 6.15947 0.146875 6.35322L5.64688 11.8532C5.84063 12.047 6.15938 12.047 6.35313 11.8532C6.54688 11.6595 6.54688 11.3407 6.35313 11.147L1.70625 6.5001L13.5 6.5001C13.775 6.5001 14 6.2751 14 6.0001C14 5.7251 13.775 5.5001 13.5 5.5001L1.70625 5.5001L6.35313 0.853222C6.54688 0.659472 6.54688 0.340723 6.35313 0.146973C6.15938 -0.0467777 5.84063 -0.0467777 5.64688 0.146973L0.146875 5.64697Z"/>
                                        </svg>
                                        Back
                                    </Button>
                                :
                                    <FormControlLabel
                                        classes={{label: classes.label}}
                                        control={
                                            <Switch
                                                checked={zipUpload}
                                                onChange={(e) => this.handleCheckbox(e, "zipUpload")}
                                                classes={{
                                                    switchBase: classes.switchBase,
                                                    checked: classes.switchChecked,
                                                    thumb: classes.switchIcon,
                                                    track: classes.switchBar
                                                }}
                                            />
                                        }
                                        label={"Zip File"}
                                    />    
                            }
                            {
                                zipUpload && section === 'files' ? 
                                    <Button color="custom" onClick={() => this.showSection('zip')} className={(files.length === 0 || (user.quota - user.usage) < this.getTotalSize() || (this.getTotalSize() > maxFileUploadSize) ? 'disable-btn': classes.sendButton)} type="button" disabled={(files.length === 0 || (user.quota - user.usage) < this.getTotalSize())}>
                                        Next
                                        <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.8531 6.35303C14.0469 6.15928 14.0469 5.84053 13.8531 5.64678L8.35312 0.146777C8.15937 -0.0469727 7.84062 -0.0469727 7.64687 0.146777C7.45312 0.340527 7.45312 0.659278 7.64687 0.853028L12.2937 5.4999H0.5C0.225 5.4999 0 5.7249 0 5.9999C0 6.2749 0.225 6.4999 0.5 6.4999H12.2937L7.64687 11.1468C7.45312 11.3405 7.45312 11.6593 7.64687 11.853C7.84062 12.0468 8.15937 12.0468 8.35312 11.853L13.8531 6.35303Z"/>
                                        </svg>
                                    </Button>
                                :
                                    <Button color="custom" onClick={this.sendFiles} className={(files.length === 0 || (user.quota - user.usage) < this.getTotalSize() || (this.getTotalSize() > maxFileUploadSize) ? 'disable-btn': classes.sendButton)} type="button" disabled={(files.length === 0 || (user.quota - user.usage) < this.getTotalSize())}>
                                        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.35313 0.146899C7.15938 -0.0468506 6.84062 -0.0468506 6.64687 0.146899L2.64687 4.1469C2.45312 4.34065 2.45312 4.6594 2.64687 4.85315C2.84062 5.0469 3.15938 5.0469 3.35313 4.85315L6.5 1.70627V10.5C6.5 10.775 6.725 11 7 11C7.275 11 7.5 10.775 7.5 10.5V1.70627L10.6469 4.85315C10.8406 5.0469 11.1594 5.0469 11.3531 4.85315C11.5469 4.6594 11.5469 4.34065 11.3531 4.1469L7.35313 0.146899ZM1 10.5C1 10.225 0.775 10 0.5 10C0.225 10 0 10.225 0 10.5V13.5C0 14.8813 1.11875 16 2.5 16H11.5C12.8813 16 14 14.8813 14 13.5V10.5C14 10.225 13.775 10 13.5 10C13.225 10 13 10.225 13 10.5V13.5C13 14.3281 12.3281 15 11.5 15H2.5C1.67188 15 1 14.3281 1 13.5V10.5Z"/>
                                        </svg>
                                        Upload
                                    </Button>
                            }
                        </DialogActions>
                }
                {
                    duplicateFileModal === true ? 
                        <DuplicateFileModal 
                            open={duplicateFileModal} 
                            onClose={() => this.onDuplicateFileModal(false)} 
                            successMessage={"The File/folder has already been added to the list to be uploaded!"}
                        />
                    :
                        <></>
                }
                {
                    groupMembersModal ? 
                        <GroupMembersModal
                            open={groupMembersModal} 
                            store={this.store} 
                            onClose={() => this.onGroupMembersModal(false)} 
                            onSuccess={(usersChecked) => this.onGroupMembersModalSuccess(usersChecked)}
                            sharedWith={sharedWith}
                            usersChecked={usersChecked}
                        />
                    :
                        <></>
                }      
            </Dialog>
        );
    }
};

UploadFileModal.defaultProps = {
    open: false,
    path: "",
    files: [],
    sharedFileId: null,
    ownerNotification: '',
    groupNotification: '',
    sharedWith: [],
    isShared: false,
    uploadType: 'file'
};
UploadFileModal.propTypes = {
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    open: PropTypes.bool,
    path: PropTypes.string,
    files: PropTypes.array,
    ownerNotification: PropTypes.string,
    groupNotification: PropTypes.string,
    sharedWith: PropTypes.array,
    isShared: PropTypes.bool,
    uploadType: PropTypes.string,
};
export default withStyles(uploadFileModalStyle)(UploadFileModal);
