import React from 'react';
import { Helmet } from 'react-helmet';
import axios from "axios";
import Api from "../../../../assets/js/utils/Api";
import PropTypes from "prop-types";
import Auth from "../../../../assets/js/utils/Auth";
import Button from "../../../components/CustomButtons/Button";
import GridItem from "../../../components/Grid/GridItem";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import LoaderComponent from "../../../components/Loader";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
import CustomSelect from "../../../components/CustomInput/CustomSelect";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import CancelSubscriptionModal from "../../../components/User/CancelSubscriptionModal";
import RemovePaymentMethodModal from "../../../components/User/RemovePaymentMethodModal";
import PrimarySourceModal from "../../../components/User/PrimarySourceModal"; 
import BackupSourceModal from "../../../components/User/BackupSourceModal"; 

import withStyles from "@material-ui/core/styles/withStyles";
import PortalSectionsStyle from "../../../../assets/jss/user/portalSectionsStyle.jsx";

import LocationIcon from "@material-ui/icons/LocationOn";
import PaymentIcon from "@material-ui/icons/Payment";
import HistoryIcon from "@material-ui/icons/History";
import BackIcon from "@material-ui/icons/NavigateBefore";
import InfoIcon from "@material-ui/icons/Info";

const PortalSections = class extends React.Component {
    constructor(props){
        super(props);
        
        this.state = {
            subscriptionsResponse: null,
            subscriptionLoading: true,
            subscriptionPlans: null,
            subscriptionPlanId: "",
            subscriptionCardId: "",
            addressesResponse: null,
            addressesLoading: true,
            paymentMethodsResponse: null,
            paymentMethodsLoading: true,
            billingHistoryData: null,
            billingHistoryResponse: [],
            billingHistoryLoading: true,
            billingHistoryResponseLoading: false,
            section: 'subscriptions',
            subscriptionItem: null,
            paymentItem: null,
            card: {
                number: '',
                cvv: '',
                expiry: '',
                expiryYear: '',
                expiryMonth: ''
            },
            cardValidation: {
                number: '',
                cvv: '',
                expiry: '',
                isValid: false
            },
            cancellation: {
                reason: ''
            },
            couponCode: false,
            checkbox: false,
            countries: [],
            loadingCountries: false,
            shippingValidation: {
                first_name: '',
                last_name: '',
                line1: '',
                city: '',
                state: '',
                country: '',
                isValid: false
            },
            billingValidation: {
                first_name: '',
                last_name: '',
                line1: '',
                city: '',
                state: '',
                country: '',
                isValid: false
            },
            saving: false,
            showCancelationModal: false,
            showRemovePaymentMethodModal: false,
            showPrimarySourceModal: false,
            showBackupSourceModal: false,
            paymentRole: null,
            showError: false,
            errorMessage: "",
        }; 

        this.store = this.props.store;
        this.history = this.props.history;
        this.handlePaymentMethodChange = this.handlePaymentMethodChange.bind(this);
        this.handleCancellationChange = this.handleCancellationChange.bind(this);
        this.showCouponInput = this.showCouponInput.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleAddressesChange = this.handleAddressesChange.bind(this);
        this.handleAddressesSubmit = this.handleAddressesSubmit.bind(this);
        this.handlePrimaryRole = this.handlePrimaryRole.bind(this);
        this.subscriptionPlanItems = this.subscriptionPlanItems.bind(this);
        this.handleSubscriptionPlanChange = this.handleSubscriptionPlanChange.bind(this);
        this.handleSubscriptionCardChange = this.handleSubscriptionCardChange.bind(this);
    }
    componentDidMount(){
        const { authorized, user } = this.store.getState();
        if(!authorized && !Auth.hasAccessToken()){
            const location = this.history.location;
            const loginRequired = "/auth/login?return="+encodeURIComponent(location.pathname+location.search);
            this.history.push(loginRequired);
        }        
        if(authorized && parseInt(user.account_type) !== 1){
            this.getUserSubscriptions();
            this.getUserAddresses();
            this.getUserPaymentMethods();
            this.getUserInvoices();
            this.loadCountries();
        }else if(authorized){
            this.setState({
                subscriptionPlans: [],
                subscriptionsResponse: [],
                subscriptionLoading: false,
                section: "subscriptions"
            });
        }
    }
    loadCountries(){
        const that = this;
        const source = axios.CancelToken.source();
        this.cancelToken = source;
        that.setState({
            cancelToken: source,
            loadingCountries: true,
            countries: []
        });
        Api.getCountries(source).then(data => {
            that.setState({
                countries: data.response,
                loadingCountries: false
            });
        }).catch(err => {
            console.log(err);
        });
    }
    handleChange(e, name){

    }
    handleAddressesSubmit(e, type){
        e.preventDefault();
        const  { subscriptionItem, section, addressesResponse } = this.state;

        let typeResponse = null;
        let openSection = '';
        if(type === "shipping_address" && subscriptionItem !== null && section === 'update-shipping'){
            typeResponse = subscriptionItem[type];
            openSection = 'subscription-details';
            typeResponse.subscriptionId = subscriptionItem.id;
        }else{
            typeResponse = addressesResponse[type];
            openSection = 'billings';
        }

        const isValid = this.validateAddressesForm(type);
        if(!isValid){
            return;
        }

        const source = axios.CancelToken.source();
        this.setState({cancelToken: source, saving: true});

        const requestData = {
            ...typeResponse,
            firstName: typeResponse.first_name,
            lastName: typeResponse.last_name,
            addr: typeResponse.line1,
            extendedAddr: typeResponse.line2,
            type: type
        }
        
        Api.updateUserAddresses(requestData, source).then(data => {
            this.setState({
                saving: false,
                section: openSection,
            })
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.setState({cancelToken: null, saving: false});
            }  
        });
    }
    validateAddressesForm(type){
        const  { subscriptionItem, section, addressesResponse } = this.state;

        let validationType = '';
        if(type === 'shipping_address'){
            validationType= 'shippingValidation';
        }else if(type === 'billing_address'){
            validationType= 'billingValidation';
        }

        let typeResponse = null;
        if(type === "shipping_address" && subscriptionItem !== null && section === 'update-shipping'){
            typeResponse = subscriptionItem[type];
        }else{
            typeResponse = addressesResponse[type];
        }

        let validation = {
            first_name: 'success',
            last_name: 'success',
            line1: 'success',
            city: 'success',
            state: 'success',
            country: 'success',
            isValid: true
        };

        if(typeResponse.first_name.length <= 0){
            validation.first_name = "error";
            validation.isValid = false;
        }
        if(typeResponse.last_name.length <= 0){
            validation.last_name = "error";
            validation.isValid = false;
        }
        if(typeResponse.line1.length <= 0){
            validation.line1 = "error";
            validation.isValid = false;
        }
        if(typeResponse.city.length <= 0){
            validation.city = "error";
            validation.isValid = false;
        }
        if(typeResponse.state.length <= 0){
            validation.state = "error";
            validation.isValid = false;
        }
        if(typeResponse.country.length <= 0){
            validation.country = "error";
            validation.isValid = false;
        }

        this.setState({
            [validationType]: validation
        });
        return validation.isValid;
    }
    handlePaymentSourceSubmit(e, type = 'create'){
        e.preventDefault();

        const  { card, section, subscriptionItem, paymentItem } = this.state;

        let openSection = '';
        if(subscriptionItem !== null){
            openSection = 'choose-payment-method';
        }
        if(subscriptionItem === null && (section === 'add-payment-method-methods' || section === 'update-payment-method')){
            openSection = 'payment-methods';
        }

        const isValid = this.validatePaymentMethodForm(type);
        if(!isValid){
            return;
        }
        
        const source = axios.CancelToken.source();
        this.setState({showError: false, cancelToken: source, saving: true});
        
        let splitExpiry = card.expiry.split("/");
        let expiryMonth = splitExpiry[0];
        let expiryYear = splitExpiry[1];
        const requestData = {
            card: {
                number: card.number,
                cvv: card.cvv,
                expiryMonth: expiryMonth,
                expiryYear: expiryYear,
            }
        }

        if(type === 'create'){
            Api.createPaymentSource(requestData, source).then(data => {
                let response = data.body;
                let paymentMethodsResponse = this.state.paymentMethodsResponse;
                paymentMethodsResponse.push(response);
                
                this.setState({
                    saving: false,
                    section: openSection,
                    paymentMethodsResponse: paymentMethodsResponse
                })
            }).catch(err => {
                if(err && err.message !== "Request Cancelled"){
                    this.setState({
                        cancelToken: null, 
                        saving: false,
                        showError: true, 
                        errorMessage: err.message
                    });
                }  
            });
        }else if(type === 'update'){
            requestData['id'] = paymentItem.id;

            Api.updatePaymentSource(requestData, source).then(data => {
                let response = data.body;
                let paymentMethodsResponse = this.state.paymentMethodsResponse;

                const updatedResponse = paymentMethodsResponse.map(item => {
                    if (item.id === response.id) {
                        return {
                            ...item,
                            expiry: response.expiry,
                        };
                    }
                    return item;
                });

                this.setState({
                    saving: false,
                    section: openSection,
                    paymentMethodsResponse: updatedResponse
                })
            }).catch(err => {
                if(err && err.message !== "Request Cancelled"){
                    this.setState({
                        cancelToken: null, 
                        saving: false,
                        showError: true, 
                        errorMessage: err.message
                    });
                }  
            });
        }
    }
    validatePaymentMethodForm(type){
        const  { card } = this.state;

        let validation = {
            number: 'success',
            expiry: 'success',
            cvv: 'success',
            isValid: true
        };
        
        if(type === 'create'){
            if(card.number.length <= 0){
                validation.number = "error";
                validation.isValid = false;
            }
            if(card.cvv.length < 3 || card.cvv.length > 4){
                validation.cvv = "error";
                validation.isValid = false;
            }
        }
        if(card.expiry.length < 7 || card.expiry.length > 7){
            validation.expiry = "error";
            validation.isValid = false;
        }else{
            let splitExpiry = card.expiry.split("/");
            let expiryMonth = splitExpiry[0];
            let expiryYear = splitExpiry[1];
            let expiryDate = new Date(expiryYear, expiryMonth);
            let currentDate = new Date();
            
            if (expiryDate < currentDate) {
                validation.expiry = "error";
                validation.isValid = false;
            }
        }

        this.setState({
            cardValidation: validation
        });

        return validation.isValid;
    }
    handleAddressesChange(e, name, type) {
        const { subscriptionItem, section, addressesResponse } = this.state;

        if(type === "shipping_address" && subscriptionItem !== null && section === 'update-shipping'){
            this.setState({
                subscriptionItem: {
                    ...subscriptionItem,
                    [type]: {
                        ...subscriptionItem[type],
                        [name]: e.target.value
                    }
                }
            })
        }else{
            this.setState({
                addressesResponse: {
                    ...addressesResponse,
                    [type]: {
                        ...addressesResponse[type],
                        [name]: e.target.value
                    }
                }
            })
        }
    }
    handlePaymentMethodChange(event, name){
        let value = event.target.value;

        if(name === "expiry"){
            //let subStr = value.substr(2,1)
            if(value.length === 2){
                value = value+"/";
            }
        }
        this.setState({
            card: {
                ...this.state.card, 
                [name]: value
            }
        });
    }
    handleCancellationChange(event, name){
        this.setState({
            cancellation: {
                ...this.state.cancellation, 
                [name]: event.target.value
            }
        });
    }
    handleSubscriptionPlanChange(event, name){
        this.setState({
            [name]: event.target.value
        });
    }
    handleSubscriptionCardChange(event, name, value){
        let SubscriptionCardId = event.target.value;
        if(value){
            SubscriptionCardId = value;
        }
        this.setState({
            [name]: SubscriptionCardId
        });
    }
    handleCheckbox(e, name){
        this.setState({
            [name]: !this.state.checkbox
        })
    }
    handlePrimaryRole(event, value) {
        this.setState({
            paymentRole: value
        });
    }
    updatePaymentRole(paymentRoleSource = null){
        const { paymentItem, paymentRole, paymentMethodsResponse } = this.state;
        let paymentRoleState = paymentRole;
        if(paymentRoleSource !== null){
            paymentRoleState = paymentRoleSource;
        }
        const source = axios.CancelToken.source();
        this.setState({cancelToken: source, saving: true});
        
        const requestData = {
            id: paymentItem.id,
            role: paymentRoleState
        }

        Api.paymentSourceRole(requestData, source).then(data => {
            let paymentMethodsResponseObject = paymentMethodsResponse;
            let pendingItems = paymentMethodsResponseObject.filter(i => i.id !== paymentItem.id);
            let response = paymentMethodsResponseObject;

            if(paymentRoleState === "primary"){
                paymentItem.primary = true;

                let previousPrimaryItem = pendingItems.filter(i => i.id !== paymentItem.id && i.primary === true)[0];
                previousPrimaryItem.primary = false;

                response = pendingItems.filter(i => i.id !== previousPrimaryItem.id);
                response.push(previousPrimaryItem);

                response.push(paymentItem);
            }else if(paymentRoleState === "backup"){
                paymentItem.backup = true;

                let previousBackupItem = pendingItems.filter(i => i.id !== paymentItem.id && i.backup === true)[0];
                if(previousBackupItem){
                    previousBackupItem.backup = false;

                    response = pendingItems.filter(i => i.id !== previousBackupItem.id);
                    response.push(previousBackupItem);

                    response.push(paymentItem);
                }
            }else if(paymentRoleState === "none"){
                paymentItem.backup = false;
            }

            this.setState({
                saving: false,
                section: 'payment-methods',
                paymentMethodsResponse: response,
                paymentRole: null,
                showPrimarySourceModal: false,
                showBackupSourceModal: false
            })
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.setState({cancelToken: null, saving: false});
            }  
        });
    }
    updateSubscriptionPlan(){
        const  { subscriptionPlanId, subscriptionsResponse, subscriptionPlans, subscriptionItem } = this.state;

        const source = axios.CancelToken.source();
        this.setState({cancelToken: source, saving: true});
            
        const requestData = {
            planId: subscriptionPlanId,
            id: subscriptionItem.id
        }

        Api.updateSubscriptionPlan(requestData, source).then(data => {
            let plan = subscriptionPlans.filter(i => i.id === subscriptionPlanId);
            let subscriptionsResponseState = subscriptionsResponse.filter(i => i.id !== subscriptionItem.id);
            let subscriptionItemObj = Object.assign({}, subscriptionItem);
            delete subscriptionItemObj.plan;

            let subscriptionItemState = {
                ...subscriptionItemObj,
                'plan': plan[0]
            }
            subscriptionsResponseState.push(subscriptionItemState);

            this.setState({
                saving: false,
                section: 'subscription-details',
                subscriptionItem: subscriptionItemState,
                subscriptionsResponse: subscriptionsResponseState
            })
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.setState({cancelToken: null, saving: false});
            }  
        });
    }
    updateSubscriptionPaymentMethod(){
        const  { subscriptionCardId, subscriptionItem } = this.state;

        const source = axios.CancelToken.source();
        this.setState({cancelToken: source, saving: true});
            
        const requestData = {
            'paymentSourceId': subscriptionCardId,
        }

        Api.updateSubscriptionPaymentMethod(subscriptionItem.id, requestData, source).then(data => {
            let subscriptionItemObj = Object.assign({}, subscriptionItem);
            delete subscriptionItemObj.card;
            
            let subscriptionItemState = {
                ...subscriptionItemObj,
                'card': data.body.card
            }
            this.setState({
                saving: false,
                section: 'subscription-details',
                subscriptionItem: subscriptionItemState,
            })
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.setState({cancelToken: null, saving: false});
            }  
        });
    }
    showCouponInput(event){
        this.setState({
            couponCode: true
        });
    }
    getDateString(invoicedate){
        const date = new Date(invoicedate);
        const dateString = (this.getMonthName(date)+" "+date.getDate()+", "+date.getFullYear());
        return dateString;
    }
    getNextBillingDate(subscriptionItem){
        const date = new Date(subscriptionItem.next_billing_at);
        // date.setMonth(date.getMonth() + subscriptionItem.plan.period);
        const dateString = (this.getMonthName(date)+" "+date.getDate()+", "+date.getFullYear());
        return dateString;
    }
    getTrialEndDate(subscriptionItem){
        const date = new Date(subscriptionItem.trial_end*1000);
        // date.setMonth(date.getMonth() + subscriptionItem.plan.period);
        const dateString = (this.getMonthName(date)+" "+date.getDate()+", "+date.getFullYear());
        return dateString;
    }
    getMonthName(date){
        let monthList = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];
        return monthList[date.getMonth()];
    }
    getAddressTitle(address){
        if(!address){
            return <></>
        }
        let title = "";
        if(address.hasOwnProperty("first_name")){
            title += address.first_name;
        }
        if(address.hasOwnProperty("last_name")){
            title += " "+address.last_name;
        }
        return title;
    }
    getLocalAddress(address){
        if(!address){
            return <></>
        }
        let localaddress = "";
        if(address.hasOwnProperty("line1")){
            localaddress += address.line1;
        }
        if(address.hasOwnProperty("line2")){
            localaddress += " "+address.line2;
        }
        return localaddress;
    }
    getCityAddress(address){
        if(!address){
            return <></>
        }
        let cityaddress = "";
        if(address.hasOwnProperty("city")){
            cityaddress += address.city;
        }
        if(address.hasOwnProperty("zip")){
            cityaddress += " "+address.zip;
        }
        if(address.hasOwnProperty("state")){
            cityaddress += " "+address.state;
        }
        if(address.hasOwnProperty("country")){
            cityaddress += " "+address.country;
        }
        return cityaddress;
    }
    getUserSubscriptions(){
        Api.getUserSubscriptions().then(data => {
            let section = 'subscriptions';
            if(data.totalItemCount > 0 && this.props.section){
                section = this.props.section;
            }

            this.setState({
                subscriptionPlans: data.plans,
                subscriptionsResponse: data.response,
                subscriptionLoading: false,
                section: section
            },() => {
                if(section === 'subscription-details'){
                    if(this.state.subscriptionsResponse !== null && this.state.subscriptionsResponse.length > 0){
                        this.openSection(section, this.state.subscriptionsResponse[0])
                    }
                }
            });
        }).catch(err => {
            this.setState({ subscriptionLoading: false });
            console.log(err);
        });
    }
    subscriptionPlanItems(){
        const  { subscriptionPlans } = this.state;
        let subscriptionPlanItemsArr = [];

        if(subscriptionPlans === null){
            return subscriptionPlanItemsArr;
        }

        subscriptionPlans.map(function(plan, index){
            subscriptionPlanItemsArr[index] = {
                id: plan.id,
                name: plan.invoice_name
            }
            return null;
        })
        
        return subscriptionPlanItemsArr;
    }
    getUserAddresses(){
        Api.getUserAddresses().then(data => {
            this.setState({
                addressesResponse: data.response,
                addressesLoading: false
            });
        }).catch(err => {
            this.setState({ addressesLoading: false });
            console.log(err);
        });
    }
    getUserPaymentMethods(){
        Api.getUserPaymentMethods().then(data => {
            this.setState({
                paymentMethodsResponse: data.response,
                paymentMethodsLoading: false
            });
        }).catch(err => {
            this.setState({ paymentMethodsLoading: false });
            console.log(err);
        });
    }
    getUserInvoices(offset = null){
        let requestData = {};
        if(offset !== null){
            requestData = {
                'offset': offset
            }
            this.setState({
                billingHistoryResponseLoading: true
            })
        }
        Api.getUserInvoices(requestData).then(data => {
            const oldResponse = (offset !== null ? this.state.billingHistoryResponse:[]);
            const response = oldResponse.concat(data.response);
            this.setState({
                billingHistoryData: data,
                billingHistoryResponse: response,
                billingHistoryLoading: false,
                billingHistoryResponseLoading: false
            });
        }).catch(err => {
            this.setState({ billingHistoryLoading: false, billingHistoryResponseLoading: false });
            console.log(err);
        });
    }
    getInvoice(invoiceId){
        Api.getInvoice(invoiceId).then(data => {
            window.open(data.download_url);
        }).catch(err => {
            console.log(err);
        });
    }
    cancelSubscription(e){
        e.preventDefault();
        const { subscriptionItem, subscriptionsResponse } = this.state;

        const source = axios.CancelToken.source();
        this.setState({cancelToken: source, saving: true});

        let requestData = {}
        Api.cancelSubscription(subscriptionItem.id, requestData, source).then(data => {
            let response = data.body;

            let subscriptionsResponseState = subscriptionsResponse.filter(i => i.id !== subscriptionItem.id);

            let subscriptionResponse = {
                ...response
            }
            subscriptionsResponseState.push(subscriptionResponse);
            
            this.setState({
                section: 'subscription-details',
                saving: false,
                subscriptionsResponse: subscriptionsResponseState,
                subscriptionItem: {
                    ...subscriptionItem,
                    status: response.status
                }
            })
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.setState({cancelToken: null, saving: false});
            }  
        });
    }
    removeCancelation(){
        this.setState({
            showCancelationModal: !this.state.showCancelationModal
        })
    }
    onRemoveCancelation(){
        const { subscriptionItem, subscriptionsResponse } = this.state;

        const source = axios.CancelToken.source();
        this.setState({cancelToken: source, saving: true});

        let requestData = {}
        Api.resumeSubscription(subscriptionItem.id, requestData, source).then(data => {
            let response = data.body;

            let subscriptionsResponseState = subscriptionsResponse.filter(i => i.id !== subscriptionItem.id);

            let subscriptionResponse = {
                ...response
            }
            subscriptionsResponseState.push(subscriptionResponse);

            this.setState({
                section: 'subscription-details',
                saving: false,
                subscriptionsResponse: subscriptionsResponseState,
                showCancelationModal: false,
                subscriptionItem: {
                    ...subscriptionItem,
                    status: response.status
                }
            })
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.setState({cancelToken: null, saving: false});
            }  
        });
    }
    removePaymentMethod(){
        this.setState({
            showRemovePaymentMethodModal: !this.state.showRemovePaymentMethodModal
        })
    }
    onRemovePaymentMethod(){
        const { paymentMethodsResponse, paymentItem } = this.state;

        const source = axios.CancelToken.source();
        this.setState({cancelToken: source, saving: true});

        let requestData = {
            id: paymentItem.id
        }
        Api.removePaymentSource(requestData, source).then(data => {
            let paymentMethodsResponseObject = paymentMethodsResponse;
            const response = paymentMethodsResponseObject.filter(i => i.id !== paymentItem.id);

            this.setState({
                section: 'payment-methods',
                saving: false,
                showRemovePaymentMethodModal: false,
                paymentMethodsResponse: response
            })
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.setState({cancelToken: null, saving: false});
            }  
        });
    }
    primarySourceModel(){
        this.setState({
            showPrimarySourceModal: !this.state.showPrimarySourceModal
        })
    }
    backupSourceModel(){
        this.setState({
            showBackupSourceModal: !this.state.showBackupSourceModal
        })
    }
    openSection(type, subscriptionItem = null, paymentItem = null){
        let subscriptionPlanId = "";
        let subscriptionCardId = "";
        if(subscriptionItem){
            subscriptionPlanId = subscriptionItem.plan.id;
            subscriptionCardId = subscriptionItem.card.payment_source_id;
        }
        this.setState({
            section: type,
            subscriptionItem: subscriptionItem,
            paymentItem: paymentItem,
            subscriptionPlanId: subscriptionPlanId,
            subscriptionCardId: subscriptionCardId,
            card: {
                number: '',
                cvv: '',
                expiry: paymentItem !== null ? paymentItem.expiry : '',
                expiryYear: '',
                expiryMonth: ''
            },
            cardValidation: {
                number: '',
                cvv: '',
                expiry: '',
                isValid: false
            },
            showError: false,
            errorMessage: "",
        })
    }
    getSection(section){
        let sectionContent = "";
        switch(section){
            case "subscriptions" :
                sectionContent = <div className={"subscriptions-section"}>
                    { this.renderSubscriptions() }
                </div>
                break;
            case "subscription-details" :
                sectionContent = <div className={"subscription-detail-section inner-section"}>
                    { this.renderSubscriptionDetail() }
                </div>
                break;
            case "edit-subscription" :
                sectionContent = <div className={"edit-subscription-section inner-section"}>
                    { this.renderEditSubscriptionDetails() }
                </div>
                break;
            case "add-addons" :
                sectionContent = <div className={"add-addons-section inner-section"}>
                    { this.renderAddAddonsSection() }
                </div>
                break;
            case "order-summary" :
                sectionContent = <div className={"order-summary-section inner-section"}>
                    { this.rednderOrderSummarySection() }
                </div>
                break;
            case "cancel-subscription" :
                sectionContent = <div className={"cancel-subscription-section inner-section"}>
                    { this.renderCancelSubscriptionSection() }
                </div>
                break;
            case "choose-payment-method" :
                sectionContent = <div className={"subscription-detail-section inner-section"}>
                    { this.renderChoosePaymentMethodDetail() }
                </div>
                break;
            case "billings" :
                sectionContent = <div className={"billing-section inner-section"}>
                    { this.renderBillingSection() }
                </div>
                break;
            case "payment-methods" :
                sectionContent = <div className={"payment-methods-section inner-section"}>
                    { this.renderPaymentMethodsSection() }
                </div>
                break;
            case "payment-method-detail" :
                sectionContent = <div className={"payment-detail-section inner-section"}>
                    { this.renderPaymentMethodDetailSection() }
                </div>
                break;
            case "payment-source-role" :
                sectionContent = <div className={"payment-source-role inner-section"}>
                    { this.renderPaymentSourceRoleSection() }
                </div>
                break;
            case "update-shipping":
            case "update-shipping-address" :
                sectionContent = <div className={"update-shipping update-detail-section inner-section"}>
                    { this.renderShippingDetailsSection() }
                </div>
                break;
            case "update-billing-address" :
                sectionContent = <div className={"update-billing update-detail-section inner-section"}>
                    { this.renderBillingDetailsSection() }
                </div>
                break;
            case "billing-history" :
                sectionContent = <div className={"billing-history inner-section"}>
                    { this.renderBillingHistorySection() }
                </div>
                break;
            case "add-payment-method":
            case "add-payment-method-methods" :
                sectionContent = <div className={"add-payment-method inner-section"}>
                    { this.renderAddPaymentMethodSection() }
                </div>
                break;
            case "update-payment-method" :
                sectionContent = <div className={"update-payment-method inner-section"}>
                    { this.renderUpdatePaymentMethodSection() }
                </div>
                break;
            default:
                sectionContent = <></>;
                break;
        }
        return sectionContent;
    }
    renderPortalSections(){
        const { section } = this.state;
        return(
            <div className="portal-sections">
                { this.getSection(section) }
            </div>
        )
    }
    renderSubscriptions(){
        const { subscriptionsResponse, subscriptionLoading } = this.state;
        return (
            <>
                <div className="subscription-header">
                    <h4 className="subscription-title">Billing</h4>
                </div>
                <div className="subscription-body">
                    <div className="subscription-body-content">
                        {
                            subscriptionsResponse !== null && subscriptionsResponse.length > 0 ?
                                <>
                                    <div className="subscription-tabs">
                                        {
                                            subscriptionsResponse.map((item, key) => {
                                                return this.renderSubscriptionTab(item, key)
                                            })
                                        }
                                    </div>
                                    <div className="subscription-links">
                                        <div onClick={() => this.openSection('billings')}>
                                            <span className="link-icon"><LocationIcon /></span><span>Billing & Shipping Addresses</span>
                                        </div>
                                        <div onClick={() => this.openSection('payment-methods')}>
                                            <span className="link-icon"><PaymentIcon /></span><span>Payment Methods</span>
                                        </div>
                                        <div onClick={() => this.openSection('billing-history')}>
                                            <span className="link-icon"><HistoryIcon /></span><span>Billing History</span>
                                        </div>
                                    </div>
                                </>
                            :
                                <>
                                    {
                                        subscriptionLoading === true ?
                                            <LoaderComponent color="custom" align="center" />
                                        :
                                            <div className="zero-content">Only company admins and individual paid members can have access to customer portal.</div>
                                    }
                                </>
                        }
                    </div>
                </div>
            </>
        )
    }
    renderSubscriptionTab(item, key){
        let plan = item.plan;
        return(
            <div className="subscription-tab next-arrow" key={key} >
                <div className="subscription-tab-content"onClick={() => this.openSection('subscription-details', item)}>
                    {
                        item.status === "cancelled" ?
                            <span className="canceled">CANCELLED</span>
                        :
                            <></>
                    }
                    {
                        item.status === "in_trial" ?
                            <span className="current_plan">Current</span>
                        :
                            <></>
                    }
                    <h4>{plan.invoice_name}</h4>
                    <div className="tab-bar-content">
                        <div>
                            <span className="bar-price">${plan.price} </span>
                            {
                                item.status === "active" ?
                                    <span className="tab-bar-desc">{plan.period_unit}</span>
                                :
                                    <></>
                            }
                        </div>
                        {
                            this.state.subscriptionsResponse.length < 2 ? 
                                <div className="tab-bar-desc">{plan.description}</div>
                            :
                                <></>
                        }
                    </div>
                </div>
            </div>
        )
    }
    renderSubscriptionDetail(){
        const { subscriptionItem, showCancelationModal } = this.state;
        if(subscriptionItem === null){
            return (
                <></>
            );
        }
        let plan = subscriptionItem.plan;
        return(
            <>
                <div className="inner-header" onClick={() => this.openSection('subscriptions')}>
                    <h4 className="inner-title"><BackIcon />Manage Subscriptions</h4>
                </div>
                <div className="inner-body">
                    <div className="inner-body-content">
                        {
                            subscriptionItem.status === 'in_trial' ?
                                <div className="payments-notification">
                                    <div className="notification-icon">
                                        <InfoIcon />
                                    </div>
                                    <div className="notification-desc">
                                        You have {subscriptionItem.trial_left} of trial left and will end on {this.getTrialEndDate(subscriptionItem)}. After your trial expires, you’ll be moved to the {plan.name} plan
                                    </div>
                                </div>
                            :
                            <></>
                        }
                        {
                            subscriptionItem.status === 'non_renewing' ?
                                <div className="payments-notification">
                                    <div className="notification-icon">
                                        <InfoIcon />
                                    </div>
                                    <div className="notification-desc">
                                        Your subscription will be cancelled on {this.getDateString(subscriptionItem.cancelled_at)}
                                        <div className="link" onClick={() => this.removeCancelation()}>
                                            Remove scheduled cancellation
                                        </div>
                                    </div>
                                </div>
                            : subscriptionItem.status === 'cancelled' ?
                                <div className="payments-notification canceled">
                                    <div className="notification-icon">
                                        <InfoIcon />
                                    </div>
                                    <div className="notification-desc">
                                        CANCELLED. Your subscription has been cancelled. Contact us to reactivate your subscription
                                    </div>
                                </div>
                            :
                                <></>
                        }
                        <div className="subscription-detail">
                            <h4>{plan.invoice_name}</h4>
                            <div>
                                <span className="bar-price">${plan.price} </span>
                                <span className="tab-bar-desc">{plan.period_unit}</span>
                            </div>
                            <div className="next_billing_date">
                                <span className="bar-price">Next Billing Date:</span>
                                <span className="tab-bar-desc">{" "+this.getNextBillingDate(subscriptionItem)}</span>
                            </div>
                        </div>
                        <div className="shipping-card-content">
                            <div className="shipping-card-section address-section"  onClick={() => this.openSection('update-shipping',subscriptionItem)}>
                                <div className="address-bar next-arrow">
                                    <div className="address-content">
                                        <h4>Shipping</h4>
                                        <div className="address-title">{ this.getAddressTitle(subscriptionItem.shipping_address) }</div>
                                        <div className="address-detail">
                                            <div>{this.getLocalAddress(subscriptionItem.shipping_address)}</div>
                                            <div>{this.getCityAddress(subscriptionItem.shipping_address)}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="shipping-card-section address-section"  onClick={() => this.openSection('choose-payment-method',subscriptionItem)}>
                                <div className="address-bar next-arrow">
                                    <div className="address-content">
                                        <h4>Paid via</h4>
                                        <div className="payment-card">
                                            <div className="payment-card-section">
                                                <div className="payment-card-content">
                                                    <span className="card-name">
                                                        {
                                                            subscriptionItem.card.card_type === 'visa' ?
                                                                <i className="fab fa-cc-visa"></i>
                                                            :
                                                                <i className="fab fa-cc-visa"></i>
                                                        }
                                                    </span>
                                                    <span className="card-detail">
                                                        ending {subscriptionItem.card.last4}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="subscription-body-content">
                            <div className="subscription-links">
                                {
                                    subscriptionItem.status === 'non_renewing' ?
                                        <div onClick={() => this.removeCancelation()}>
                                            <span>Remove scheduled cancellation</span>
                                        </div>
                                    : subscriptionItem.status === 'cancelled' ?
                                        <div onClick={() => this.removeCancelation()}>
                                            <span>Reactivate Subscription</span>
                                        </div>
                                    :
                                        <>
                                            <div onClick={() => this.openSection('edit-subscription', subscriptionItem)}>
                                                <span>Edit Subscription</span>
                                            </div>
                                            <div onClick={() => this.openSection('cancel-subscription',subscriptionItem)}>
                                                <span>Cancel Subscription</span>
                                            </div>
                                        </>
                                }
                            </div>
                        </div>
                        {
                            showCancelationModal === true ?
                                <CancelSubscriptionModal 
                                    open={showCancelationModal} 
                                    onClose={() => this.removeCancelation()} 
                                    onSuccess={() => this.onRemoveCancelation()}
                                    confirmationMessage="Are you sure you want to remove the scheduled cancellation?"
                                />
                            :
                                <></>
                        }
                    </div>
                </div>
            </>
        )
    }
    renderEditSubscriptionDetails(){
        const { subscriptionItem, subscriptionPlanId } = this.state;
        const  { classes } = this.props;
        
        if(subscriptionItem === null){
            return (
                <></>
            );
        }
        return(
            <>
                <div className="inner-header" onClick={() => this.openSection('subscription-details', subscriptionItem)}>
                    <h4 className="inner-title"><BackIcon />Edit Subscription Details</h4>
                </div>
                <div className="inner-body">
                    <div className="inner-body-content">
                        <div className="subscriptions-dropdown">
                            <CustomSelect 
                                formControlProps={{
                                    fullWidth: true,
                                    className: classes.customSelectFormControl
                                }}
                                selectProps={{
                                    onChange: (e) => this.handleSubscriptionPlanChange(e,"subscriptionPlanId"),
                                    value: subscriptionPlanId
                                }}
                                inputProps={{
                                    name: "subscriptionPlanId",
                                    id: "input-subscriptionPlanId",
                                    className: classes.alignLeft
                                }}
                                color="custom"
                                items={this.subscriptionPlanItems()}
                                itemLabel="name"
                                itemValue="id"
                            />
                        </div>
                        {
                            this.renderSubscriptionPlanContent()
                        }
                    </div>
                </div>
            </>
        )
    }
    renderSubscriptionPlanContent(){
        const { subscriptionPlanId, subscriptionPlans, subscriptionItem, couponCode, saving } = this.state;
        let plan = "";

        plan = subscriptionPlans.filter(i => i.id === subscriptionPlanId);
        
        if(plan === ""){
            return (
                <></>
            )
        }
        plan = plan[0];
        return (
            <>
                <div className="subscription-detail">
                    <div className="price">${plan.price}</div>
                    <div className="name">{plan.invoice_name}</div>
                    <div className="desc">{plan.description}</div>
                </div>
                <div className="links-content hide">
                    <div className="addons-btn" onClick={() => this.openSection('add-addons', subscriptionItem)}>
                        <Button color="custom" size="sm">Add Addons</Button>
                    </div>
                    <div className={"apply-coupon-link "+(couponCode === false ? 'show' : 'hide')} onClick={(e) => this.showCouponInput(e)}>
                        <div>Apply coupon</div>
                    </div>
                    <div className={"coupon-input "+(couponCode === true ? 'show' : 'hide')}>
                        <CustomInput
                            id="outlined-coupon"
                            labelText="Enter coupon code"
                            inputProps={{
                                value: '',
                                onChange: (e) => this.handleChange(e, 'coupon'),
                                name: "coupon",
                            }}                                    
                            formControlProps={{
                                fullWidth: true
                            }}
                        />
                    </div>
                </div>
                <div className="shipping-card-content" onClick={() => this.openSection('order-summary', subscriptionItem)}>
                    <div className="shipping-card-section address-section">
                        <div className="address-bar next-arrow">
                            <div className="address-content">
                                <h4>Your next charge ${plan.price} will be on {this.getNextBillingDate(subscriptionItem)} via</h4>
                                <div className="payment-card">
                                    <div className="payment-card-section">
                                        <div className="payment-card-content">
                                            <span className="card-name">
                                                {
                                                    subscriptionItem.card.card_type === 'visa' ?
                                                        <i className="fab fa-cc-visa"></i>
                                                    :
                                                        <i className="fab fa-cc-visa"></i>
                                                }
                                            </span>
                                            <span className="card-detail">
                                                ending {subscriptionItem.card.last4}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="confirm-btn">
                    {
                        subscriptionPlanId === subscriptionItem.plan.id ?
                            <Button color="custom" disabled={true}>Update Subscription</Button>
                        :
                            saving === false ?
                                <Button color="custom" onClick={() => this.updateSubscriptionPlan()}>Update Subscription</Button>
                            :
                                <Button color="custom">
                                    <LoaderComponent color="white" align="center" saving={true} />
                                </Button>
                    }
                </div>
            </>
        )
    }
    renderAddAddonsSection(){
        const { classes } = this.props;
        const { subscriptionItem, checkbox } = this.state;

        return(
            <>
                <div className="inner-header" onClick={() => this.openSection('edit-subscription', subscriptionItem)}>
                    <h4 className="inner-title"><BackIcon />Addd Addons</h4>
                </div>
                <div className="inner-body">
                    <div className="inner-body-content">
                        <div className="add-addons-content">
                            <div className="add-addons-options">
                                <div className="add-addons-option">
                                    <div className="addons-option-left radio-checkbox">
                                        <FormControlLabel
                                            classes={{label: classes.label}}
                                            control={
                                                <Checkbox                                                
                                                    tabIndex={-1}
                                                    onClick={(e) => this.handleCheckbox(e, "checkbox")}
                                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                                    icon={<Check className={classes.uncheckedIcon} />}
                                                    classes={{checked: classes.checked, root: classes.checkRoot}}
                                                    checked={(checkbox ? true : false)}
                                                />
                                            }
                                        />
                                    </div>
                                    <div className="addons-option-right radio-label" onClick={(e) => this.handleCheckbox(e, "checkbox")}>
                                        <div className="addon-option-name">Custom Reports</div>
                                        <div className="addon-option-price">$20.00</div>
                                    </div>
                                </div>
                                <div className="add-addons-option">
                                    <div className="addons-option-left radio-checkbox">
                                        <FormControlLabel
                                            classes={{label: classes.label}}
                                            control={
                                                <Checkbox                                                
                                                    tabIndex={-1}
                                                    onClick={(e) => this.handleCheckbox(e, "checkbox")}
                                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                                    icon={<Check className={classes.uncheckedIcon} />}
                                                    classes={{checked: classes.checked, root: classes.checkRoot}}
                                                    checked={(checkbox ? true : false)}
                                                />
                                            }
                                        />
                                    </div>
                                    <div className="addons-option-right radio-label" onClick={(e) => this.handleCheckbox(e, "checkbox")}>
                                        <div className="addon-option-name">Concierge Support</div>
                                        <div className="addon-option-price">$75.00</div>
                                    </div>
                                </div>
                            </div>
                            <div className="addons-btn">
                                <Button color="custom">Add</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    rednderOrderSummarySection(){
        const { subscriptionItem } = this.state;
        if(subscriptionItem === null){
            return (
                <></>
            );
        }
        return(
            <>
                <div className="inner-header" onClick={() => this.openSection('edit-subscription', subscriptionItem)}>
                    <h4 className="inner-title"><BackIcon />Your order summary</h4>
                </div>
                <div className="inner-body">
                    <div className="inner-body-content">
                        <div className="order-summary-content">
                            <div className="title">Charges on {this.getNextBillingDate(subscriptionItem)}</div>
                            <div className="order-details">
                                <div className="order-name-price">
                                    <div className="order-name">{subscriptionItem.plan.invoice_name}</div>
                                    <div className="order-price">${subscriptionItem.plan.price}</div>
                                </div>
                                <div className="order-detail-footer">
                                    <div className="order-total-amount">
                                        ${subscriptionItem.plan.price}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    renderCancelSubscriptionSection(){
        const { cancellation, subscriptionItem, saving } = this.state;

        return(
            <>
                <div className="inner-header" onClick={() => this.openSection('subscription-details', subscriptionItem)}>
                    <h4 className="inner-title"><BackIcon />Cancel Subscription</h4>
                </div>
                <div className="inner-body">
                    <div className="inner-body-content">
                        <div className="cancellation-form-content">
                            <div className="title">Your subscription will be cancelled immediately</div>
                            <div className="cancellation-form">
                                <div className="reason-field">
                                    <CustomInput
                                        id="outlined-reason"
                                        labelText="Reason for cancellation"                                    
                                        inputProps={{
                                            value: cancellation.reason,
                                            onChange: (e) => this.handleCancellationChange(e, 'reason'),
                                            name: "reason",
                                            multiline: true,
                                            rows: 3,
                                        }}                                    
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                    />
                                </div>
                                <div className="confirm-btn" onClick={(e) => this.cancelSubscription(e)}>
                                    {
                                        saving === false ?
                                            <Button color="custom">Confirm Cancellation</Button>
                                        :
                                            <Button color="custom">
                                                <LoaderComponent color="white" align="center" saving={true} />
                                            </Button>
                                    }  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    renderChoosePaymentMethodDetail(){
        const { subscriptionItem, paymentMethodsResponse, subscriptionCardId } = this.state;
        const { classes } = this.props;
        if(subscriptionItem === null){
            return (
                <></>
            );
        }
        return(
            <>
                <div className="inner-header" onClick={() => this.openSection('subscription-details', subscriptionItem)}>
                    <h4 className="inner-title"><BackIcon />Choose your payment method for this subscription</h4>
                </div>
                <div className="inner-body">
                    <div className="inner-body-content">
                        <div className="payment-methods-lists">
                            {
                                paymentMethodsResponse.map((item, key) => {
                                    return (
                                        <div className="payment-card" key={key} >
                                            <div className="payment-card-section">
                                                <div className="payment-card-content">
                                                    <div className="payment-card-radio radio-checkbox">
                                                        <FormControlLabel
                                                            classes={{label: classes.label}}
                                                            control={
                                                                <Radio
                                                                    checked={subscriptionCardId === item.id ? true : false}
                                                                    onChange={(e) => this.handleSubscriptionCardChange(e,"subscriptionCardId",item.id)}
                                                                    value={item.id}
                                                                    name="subscriptionCardId"
                                                                    icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                                                    checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                                                                    classes={{
                                                                        checked: classes.radio,
                                                                        root: classes.radioRoot
                                                                    }}
                                                                />
                                                            }
                                                        />
                                                    </div>
                                                    <div className="payment-card-detail" onClick={(e) => this.handleSubscriptionCardChange(e,"subscriptionCardId",item.id)}>
                                                        <span className="card-name">
                                                            {
                                                                item.card_type === 'visa' ?
                                                                    <i className="fab fa-cc-visa"></i>
                                                                :
                                                                    <i className="fab fa-cc-visa"></i>
                                                            }
                                                        </span>
                                                        <span className="card-detail">
                                                            ending {item.last4}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="subscription-body-content">
                            <div className="subscription-links">
                                <div onClick={() => this.openSection('add-payment-method', subscriptionItem)}>
                                    <span>+ Add a payment method</span>
                                </div>
                            </div>
                        </div>
                        <div className={"update-payment-methods textCenter"}>
                            <Button color="custom" onClick={() => this.updateSubscriptionPaymentMethod()}>Update</Button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    renderShippingDetailsSection(){
        const { addressesResponse ,subscriptionItem, section, countries, loadingCountries, shippingValidation, saving } = this.state;
        const { classes } = this.props;

        if(subscriptionItem === null && section !== 'update-shipping-address'){
            return (
                <></>
            );
        }
        let shippingAddress = null;
        let openSection = '';
        if(subscriptionItem !== null){
            shippingAddress = subscriptionItem.shipping_address;
            openSection = 'subscription-details';
        }
        if(subscriptionItem === null && section === 'update-shipping-address'){
            shippingAddress = addressesResponse.shipping_address;
            openSection = 'billings';
        }
        return(
            <>
                <div className="inner-header" onClick={() => this.openSection(openSection, subscriptionItem)}>
                    <h4 className="inner-title"><BackIcon />Update your shipping details</h4>
                </div>
                <div className="inner-body">
                    <div className="inner-body-content">
                        <div className="update-form-section">
                            <form onSubmit={(e) => this.handleAddressesSubmit(e, 'shipping_address')}>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        <CustomInput
                                            success={shippingValidation.first_name === "success"}
                                            error={shippingValidation.first_name === "error"}
                                            id="outlined-firstName"
                                            labelText="First Name"                                    
                                            inputProps={{
                                                value: shippingAddress.first_name,
                                                onChange: (e) => this.handleAddressesChange(e, 'first_name', 'shipping_address'),
                                                name: "first_name",
                                            }}                                    
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={6}>
                                        <CustomInput
                                            success={shippingValidation.last_name === "success"}
                                            error={shippingValidation.last_name === "error"}
                                            id="outlined-lastName"
                                            labelText="Last Name"                                    
                                            inputProps={{
                                                value: shippingAddress.last_name,
                                                onChange: (e) => this.handleAddressesChange(e, 'last_name', 'shipping_address'),
                                                name: "last_name",
                                            }}                                    
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12}>
                                        <CustomInput
                                            success={shippingValidation.line1 === "success"}
                                            error={shippingValidation.line1 === "error"}
                                            id="outlined-address-line1"
                                            labelText="Address Line 1"                                    
                                            inputProps={{
                                                value: shippingAddress.line1,
                                                onChange: (e) => this.handleAddressesChange(e, 'line1', 'shipping_address'),
                                                name: "line1",
                                            }}                                    
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                        />
                                    </GridItem>    
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12}>
                                        <CustomInput
                                            id="outlined-address-line2"
                                            labelText="Address Line 2 (Optional)"                                    
                                            inputProps={{
                                                value: shippingAddress.line2,
                                                onChange: (e) => this.handleAddressesChange(e, 'line2', 'shipping_address'),
                                                name: "line2",
                                            }}                                    
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                        />
                                    </GridItem>    
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        <CustomInput
                                            success={shippingValidation.city === "success"}
                                            error={shippingValidation.city === "error"}
                                            id="outlined-city"
                                            labelText="City"                                    
                                            inputProps={{
                                                value: shippingAddress.city,
                                                onChange: (e) => this.handleAddressesChange(e, 'city', 'shipping_address'),
                                                name: "city",
                                            }}                                    
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={6}>
                                        <CustomInput
                                            id="outlined-zip"
                                            labelText="Postal / Zip Code (Optional)"                                    
                                            inputProps={{
                                                value: shippingAddress.zip,
                                                onChange: (e) => this.handleAddressesChange(e, 'zip', 'shipping_address'),
                                                name: "zip",
                                            }}                                    
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        <CustomInput
                                            success={shippingValidation.state === "success"}
                                            error={shippingValidation.state === "error"}
                                            id="outlined-state"
                                            labelText="Province / State"                                    
                                            inputProps={{
                                                value: shippingAddress.state,
                                                onChange: (e) => this.handleAddressesChange(e, 'state', 'shipping_address'),
                                                name: "state",
                                            }}                                    
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={6}>
                                        {
                                            countries.length > 0 ?
                                                <CustomSelect 
                                                success={shippingValidation.country === "success"}
                                                error={shippingValidation.country === "error"}
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    labelText="Country"
                                                    selectProps={{
                                                        onChange: (e) => this.handleAddressesChange(e, 'country', 'shipping_address'),
                                                        value: shippingAddress.country
                                                    }}
                                                    inputProps={{
                                                        name: "country",
                                                        id: "input-country",
                                                        className: classes.alignLeft
                                                    }}
                                                    items={countries}
                                                    itemLabel="name"
                                                    itemValue="code"
                                                />
                                            :
                                                loadingCountries ?
                                                    <LoaderComponent color="custom" align="left" />
                                                :
                                                <></>
                                        }
                                    </GridItem>
                                </GridContainer>
                                <div className="update-form-btn">
                                    {
                                        saving === false ?
                                            <Button color="custom" type="submit">Update</Button>
                                        :
                                            <Button color="custom">
                                                <LoaderComponent color="white" align="center" saving={true} />
                                            </Button>
                                    }                                    
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    renderBillingDetailsSection(){
        const { addressesResponse, countries, loadingCountries, saving, billingValidation } = this.state;
        const { classes } = this.props;

        if(addressesResponse === null || !(addressesResponse.billing_address)){
            return (
                <></>
            );
        }
        let billingAddress = addressesResponse.billing_address;
        return(
            <>
                <div className="inner-header" onClick={() => this.openSection('billings')}>
                    <h4 className="inner-title"><BackIcon />Update your billing details</h4>
                </div>
                <div className="inner-body">
                    <div className="inner-body-content">
                        <div className="update-form-section">
                        <form onSubmit={(e) => this.handleAddressesSubmit(e, 'billing_address')}>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        <CustomInput
                                            success={billingValidation.first_name === "success"}
                                            error={billingValidation.first_name === "error"}
                                            id="outlined-firstName"
                                            labelText="First Name"                                    
                                            inputProps={{
                                                value: billingAddress.first_name,
                                                onChange: (e) => this.handleAddressesChange(e, 'first_name', 'billing_address'),
                                                name: "first_name",
                                            }}                                    
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={6}>
                                        <CustomInput
                                            success={billingValidation.last_name === "success"}
                                            error={billingValidation.last_name === "error"}
                                            id="outlined-lastName"
                                            labelText="Last Name"                                    
                                            inputProps={{
                                                value: billingAddress.last_name,
                                                onChange: (e) => this.handleAddressesChange(e, 'last_name', 'billing_address'),
                                                name: "last_name",
                                            }}                                    
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12}>
                                        <CustomInput
                                            success={billingValidation.line1 === "success"}
                                            error={billingValidation.line1 === "error"}
                                            id="outlined-address-line1"
                                            labelText="Address Line 1"                                    
                                            inputProps={{
                                                value: billingAddress.line1,
                                                onChange: (e) => this.handleAddressesChange(e, 'line1', 'billing_address'),
                                                name: "line1",
                                            }}                                    
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                        />
                                    </GridItem>    
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12}>
                                        <CustomInput
                                            id="outlined-address-line2"
                                            labelText="Address Line 2 (Optional)"                                    
                                            inputProps={{
                                                value: billingAddress.line2,
                                                onChange: (e) => this.handleAddressesChange(e, 'line2', 'billing_address'),
                                                name: "line2",
                                            }}                                    
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                        />
                                    </GridItem>    
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        <CustomInput
                                            success={billingValidation.city === "success"}
                                            error={billingValidation.city === "error"}
                                            id="outlined-city"
                                            labelText="City"                                    
                                            inputProps={{
                                                value: billingAddress.city,
                                                onChange: (e) => this.handleAddressesChange(e, 'city', 'billing_address'),
                                                name: "city",
                                            }}                                    
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={6}>
                                        <CustomInput
                                            id="outlined-zip"
                                            labelText="Postal / Zip Code (Optional)"                                    
                                            inputProps={{
                                                value: billingAddress.zip,
                                                onChange: (e) => this.handleAddressesChange(e, 'zip', 'billing_address'),
                                                name: "zip",
                                            }}                                    
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        <CustomInput
                                            success={billingValidation.state === "success"}
                                            error={billingValidation.state === "error"}
                                            id="outlined-state"
                                            labelText="Province / State"                                    
                                            inputProps={{
                                                value: billingAddress.state,
                                                onChange: (e) => this.handleAddressesChange(e, 'state', 'billing_address'),
                                                name: "state",
                                            }}                                    
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={6}>
                                        {
                                            countries.length > 0 ?
                                                <CustomSelect 
                                                success={billingValidation.country === "success"}
                                                error={billingValidation.country === "error"}
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    labelText="Country"
                                                    selectProps={{
                                                        onChange: (e) => this.handleAddressesChange(e, 'country', 'billing_address'),
                                                        value: billingAddress.country
                                                    }}
                                                    inputProps={{
                                                        name: "country",
                                                        id: "input-country",
                                                        className: classes.alignLeft
                                                    }}
                                                    items={countries}
                                                    itemLabel="name"
                                                    itemValue="code"
                                                />
                                            :
                                                loadingCountries ?
                                                    <LoaderComponent color="custom" align="left" />
                                                :
                                                <></>
                                        }
                                    </GridItem>
                                </GridContainer>
                                <div className="update-form-btn">
                                    {
                                        saving === false ?
                                            <Button color="custom" type="submit">Update</Button>
                                        :
                                            <Button color="custom">
                                                <LoaderComponent color="custom" align="center" saving={true} />
                                            </Button>
                                    }                                    
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    renderBillingSection(){
        const { addressesResponse, addressesLoading } = this.state;
        return(
            <>
                <div className="inner-header" onClick={() => this.openSection('subscriptions')}>
                    <h4 className="inner-title"><BackIcon />Billing & Shipping Addresses</h4>
                </div>
                <div className="inner-body">
                    <div className="inner-body-content">
                        {
                            addressesResponse !== null ?
                                <>
                                    <div className="address-section billing-address-section">
                                        <h4>Billing</h4>
                                        <div className="address-bar next-arrow" onClick={() => this.openSection('update-billing-address')}>
                                            <div className="address-content">
                                                <div className="address-title">{ this.getAddressTitle(addressesResponse.billing_address) }</div>
                                                <div className="address-detail">
                                                    <div>{this.getLocalAddress(addressesResponse.billing_address)}</div>
                                                    <div>{this.getCityAddress(addressesResponse.billing_address)}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="address-section shipping-address-section">
                                        <h4>Shipping</h4>
                                        <div className="address-bar next-arrow" onClick={() => this.openSection('update-shipping-address')}>
                                            <div className="address-content">
                                                <div className="address-title">{ this.getAddressTitle(addressesResponse.shipping_address) }</div>
                                                <div className="address-detail">
                                                    <div>{this.getLocalAddress(addressesResponse.shipping_address)}</div>
                                                    <div>{this.getCityAddress(addressesResponse.shipping_address)}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            :
                                <>
                                    {
                                        addressesLoading === true ?
                                            <LoaderComponent color="custom" align="center" />
                                        :
                                            <></>
                                    }
                                </>
                        }
                    </div>
                </div>
            </>
        )
    }
    renderPaymentMethodsSection(){
        const { paymentMethodsResponse, paymentMethodsLoading } = this.state;
        return(
            <>
                <div className="inner-header" onClick={() => this.openSection('subscriptions')}>
                    <h4 className="inner-title"><BackIcon />Payment Methods</h4>
                </div>
                <div className="inner-body">
                    <div className="inner-body-content">
                        {
                            paymentMethodsResponse !== null ?
                                <>
                                    <div className="payments-notification">
                                        <div className="notification-icon">
                                             <InfoIcon />
                                        </div>
                                        <div className="notification-desc">
                                            Backups come handy when your primary payment fails. Set a backup.
                                        </div>
                                    </div>
                                    <div className="payment-cards">
                                        {
                                            paymentMethodsResponse.map((item, key) => {
                                                return(
                                                    <div className="payment-card next-arrow" key={key} onClick={() => this.openSection('payment-method-detail', null, item)}>
                                                        <div className="payment-card-section">
                                                            <div className="payment-card-content">
                                                                <span className="card-name">
                                                                    {
                                                                        item.card_type === 'visa' ?
                                                                            <i className="fab fa-cc-visa"></i>
                                                                        :
                                                                            <i className="fab fa-cc-visa"></i>
                                                                    }
                                                                </span>
                                                                <span className="card-detail">
                                                                    ending {item.last4}
                                                                </span>
                                                                {
                                                                    item.primary === true ?
                                                                        <div className="card-type">Primary</div>
                                                                    : item.backup === true ?
                                                                        <div className="card-type backup">Backup</div>
                                                                    :
                                                                        <></>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="add-payment-card" onClick={() => this.openSection('add-payment-method-methods')}>
                                        <Button color="custom">Add New</Button>
                                    </div>
                                </>
                            :
                                <>
                                    {
                                        paymentMethodsLoading === true ?
                                            <LoaderComponent color="custom" align="center" />
                                        :
                                            <></>
                                    }
                                </>
                        }
                    </div>
                </div>
            </>
        )
    }
    renderPaymentMethodDetailSection(){
        const { paymentItem, showRemovePaymentMethodModal, showPrimarySourceModal, showBackupSourceModal } = this.state;

        if(paymentItem === null){
            return (
                <></>
            );
        }
        return(
            <>
                <div className="inner-header" onClick={() => this.openSection('payment-methods')}>
                    <h4 className="inner-title"><BackIcon />Payment Method Details</h4>
                </div>
                <div className="inner-body">
                    <div className="inner-body-content">
                        <div className="payment-detail-content">
                            <div className="card-name">
                                {
                                    paymentItem.card_type === 'visa' ?
                                        <i className="fab fa-cc-visa"></i>
                                    :
                                        <i className="fab fa-cc-visa"></i>
                                }
                            </div>
                            <div className="card-detail">
                                ending {paymentItem.last4}
                            </div>
                            <div className="card-type">
                                {
                                    paymentItem.primary === true ?
                                        <>
                                            <span>This is your primary payment method</span>
                                            <Tooltip 
                                                title="This will be charged for all your automatic payments." 
                                                placement="top"
                                                arrow
                                            >
                                                <InfoIcon />
                                            </Tooltip>
                                        </>
                                    : paymentItem.backup === true ?
                                        <>
                                            <span>This is your backup payment method</span>
                                            <Tooltip 
                                                title="This will be the backup when your primary payment method fails."
                                                placement="top"
                                                arrow
                                            >
                                                <InfoIcon />
                                            </Tooltip>
                                            <div className="bacup-source-actions">
                                                <span onClick={() => this.backupSourceModel()}>Remove as backup</span>
                                                <span onClick={() => this.primarySourceModel()}>Set as primary</span>
                                            </div>
                                        </>
                                    :
                                        <div onClick={() => this.openSection('payment-source-role', null, paymentItem)}>Set this as primary or backup</div>
                                }
                                
                            </div>
                            <div className="subscription-body-content">
                                <div className="subscription-links">
                                    {
                                        paymentItem.primary === true ?
                                            <div onClick={() => this.openSection('update-payment-method', null, paymentItem)}>
                                                <span>Edit Payment Method</span>
                                            </div>
                                        :
                                            <div onClick={() => this.removePaymentMethod()}>
                                                <span>Remove Payment Method</span>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    showRemovePaymentMethodModal === true ?
                        <RemovePaymentMethodModal 
                            open={showRemovePaymentMethodModal} 
                            onClose={() => this.removePaymentMethod()} 
                            onSuccess={() => this.onRemovePaymentMethod()}
                            confirmationMessage="Are you sure you want to remove this payment method?"
                        />
                    :
                        <></>
                }
                {
                    showPrimarySourceModal === true ?
                        <PrimarySourceModal 
                            open={showPrimarySourceModal}
                            onClose={() => this.primarySourceModel()} 
                            onSuccess={() => this.updatePaymentRole('primary')}
                            confirmationMessage="Set this as primary? This will replace your current primary payment method"
                        />
                    :
                        <></>
                }
                {
                    showBackupSourceModal === true ?
                        <BackupSourceModal 
                            open={showBackupSourceModal}
                            onClose={() => this.backupSourceModel()}
                            onSuccess={() => this.updatePaymentRole('none')}
                            confirmationMessage="Remove backup? Backups come handy when your primary payment method fails."
                        />
                    :
                        <></>
                }
            </>
        )
    }
    renderPaymentSourceRoleSection(){
        const { paymentItem, paymentRole } = this.state;
        const { classes } = this.props;

        if(paymentItem === null){
            return (
                <></>
            );
        }
        return(
            <>
                <div className="inner-header" onClick={() => this.openSection('payment-methods')}>
                    <h4 className="inner-title"><BackIcon />Payment Method Settings</h4>
                </div>
                <div className="inner-body">
                    <div className="inner-body-content">
                        <div className="payment-detail-content">
                            <div className="card-name">
                                {
                                    paymentItem.card_type === 'visa' ?
                                        <i className="fab fa-cc-visa"></i>
                                    :
                                        <i className="fab fa-cc-visa"></i>
                                }
                            </div>
                            <div className="card-detail">
                                ending {paymentItem.last4}
                            </div>
                            <div className="card-payment-method">
                                <div className="title">Set this payment method as</div>
                                <div className="card-payment-roles">
                                    <div className="card-payment-role">
                                        <div className="card-role-option">
                                            <div className="role-option-left radio-checkbox">
                                                <FormControlLabel
                                                    classes={{label: classes.label}}
                                                    control={
                                                        <Radio
                                                            checked={paymentRole === "primary"}
                                                            onChange={(e) => this.handlePrimaryRole(e, "primary")}
                                                            value="primary"
                                                            name="paymentRole"
                                                            icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                                            checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                                                            classes={{
                                                                checked: classes.radio,
                                                                root: classes.radioRoot
                                                            }}
                                                        />
                                                    }
                                                />
                                            </div>
                                            <div className="role-option-right radio-label" onClick={(e) => this.handlePrimaryRole(e, "primary")}>
                                                <div className="role-option-name">Primary</div>
                                                <div className="role-option-desc">This will be charged for all your automatic payments.</div>
                                                {
                                                    paymentRole === "primary" ?
                                                        <div className="primary-note">
                                                            <div className="primary-note-icon">
                                                                <InfoIcon />
                                                            </div>
                                                            <div className="primary-note-desc">
                                                                This will replace your current primary payment method
                                                            </div>
                                                        </div>
                                                    :
                                                        <></>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-payment-role">
                                        <div className="card-role-option">
                                            <div className="role-option-left radio-checkbox">
                                                <FormControlLabel
                                                    classes={{label: classes.label}}
                                                    control={
                                                        <Radio
                                                            checked={paymentRole === "backup"}
                                                            onChange={(e) => this.handlePrimaryRole(e, "backup")}
                                                            value="backup"
                                                            name="paymentRole"
                                                            icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                                            checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                                                            classes={{
                                                                checked: classes.radio,
                                                                root: classes.radioRoot
                                                            }}
                                                        />
                                                    }
                                                />
                                            </div>
                                            <div className="role-option-right radio-label" onClick={(e) => this.handlePrimaryRole(e, "backup")}>
                                                <div className="role-option-name">Backup</div>
                                                <div className="role-option-desc">This will be the backup when your primary payment method fails.</div>
                                                {
                                                    paymentRole === "backup" ?
                                                        <div className="primary-note">
                                                            <div className="primary-note-icon">
                                                                <InfoIcon />
                                                            </div>
                                                            <div className="primary-note-desc">
                                                                This will replace your current backup payment method
                                                            </div>
                                                        </div>
                                                    :
                                                        <></>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="submit-payment-role">
                                {
                                    paymentRole === null ?
                                        <Button color="custom" onClick={() => this.openSection('payment-methods')}>Skip</Button>
                                    :
                                        <Button color="custom" onClick={() => this.updatePaymentRole()}>Update</Button>
                                }
                                
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    renderBillingHistorySection(){
        const { billingHistoryData, billingHistoryResponse, billingHistoryLoading, billingHistoryResponseLoading } = this.state;
        return(
            <>
                <div className="inner-header" onClick={() => this.openSection('subscriptions')}>
                    <h4 className="inner-title"><BackIcon />Billing history</h4>
                </div>
                <div className="inner-body">
                    <div className="inner-body-content">
                        {
                            billingHistoryResponse.length > 0 ?
                                <>
                                    <div className="billing-history-invoices">
                                        {
                                            billingHistoryResponse.map((invoice, key) => {
                                                return(
                                                    <div className="billing-history-invoice" key={key}>
                                                        <div className="billing-history-invoice-content">
                                                            <div className="payment-card-content">
                                                                <div className="invoice-detail">
                                                                    <div className="invoice-title">{invoice.plan.invoice_name}</div>
                                                                    <div className="invoice-details">
                                                                        <span className="invoice-price">${invoice.total}</span>
                                                                        <span className="invoice-date">{this.getDateString(invoice.updated_at)}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="invoice-download">
                                                                    <Button simple onClick={(e) => this.getInvoice(invoice.id)}>Download</Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    {
                                        billingHistoryData.offset !== null ?
                                            <div className="load-more-btn">
                                                {
                                                    billingHistoryResponseLoading === true ? 
                                                        <Button color="custom">
                                                            <LoaderComponent color="white" align="center" saving={true}/>
                                                        </Button>
                                                    :
                                                        <Button color="custom" onClick={() => this.getUserInvoices(billingHistoryData.offset)}>Load More</Button>
                                                }
                                            </div>
                                        :
                                            <></>
                                    }
                                </>
                            :
                                <>
                                    {
                                        billingHistoryLoading === true ?
                                            <LoaderComponent color="custom" align="center" />
                                        :
                                            <></>
                                    }
                                </>
                        }
                    </div>
                </div>
            </>
        )
    }
    renderAddPaymentMethodSection(){
        const { card, section, subscriptionItem, saving, cardValidation, showError } = this.state;
        
        let openSection = '';
        if(subscriptionItem !== null){
            openSection = 'choose-payment-method';
        }
        if(subscriptionItem === null && section === 'add-payment-method-methods'){
            openSection = 'payment-methods';
        }

        return(
            <>
                <div className="inner-header" onClick={() => this.openSection(openSection, subscriptionItem)}>
                    <h4 className="inner-title"><BackIcon />Add a payment method</h4>
                </div>
                <div className="inner-body">
                    <div className="inner-body-content">
                        <div className="update-form-section">
                            {
                                showError ?
                                    <div className="error-message-section">
                                        { this.renderErrorMessages() }
                                    </div>
                                :
                                <></>
                            }
                            <form onSubmit={(e) => this.handlePaymentSourceSubmit(e)}>
                                <GridContainer>
                                    <GridItem xs={12}>
                                        <CustomInput
                                            success={cardValidation.number === "success"}
                                            error={cardValidation.number === "error"}
                                            id="outlined-number"
                                            labelText="Card Number"                                    
                                            inputProps={{
                                                value: card.number,
                                                onChange: (e) => this.handlePaymentMethodChange(e, 'number'),
                                                name: "number",
                                            }}                                    
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                        />
                                    </GridItem>    
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        <CustomInput
                                            success={cardValidation.expiry === "success"}
                                            error={cardValidation.expiry === "error"}
                                            id="outlined-expiry"
                                            labelText="Expiry"                                    
                                            inputProps={{
                                                value: card.expiry,
                                                onChange: (e) => this.handlePaymentMethodChange(e, 'expiry'),
                                                name: "expiry",
                                            }}                                    
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={6}>
                                        <CustomInput
                                            success={cardValidation.cvv === "success"}
                                            error={cardValidation.cvv === "error"}
                                            id="outlined-cvv"
                                            labelText="CVV"
                                            inputProps={{
                                                value: card.cvv,
                                                onChange: (e) => this.handlePaymentMethodChange(e, 'cvv'),
                                                name: "cvv",
                                            }}                                    
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <div className="update-form-btn">
                                    {
                                        saving === false ?
                                            <Button color="custom" type="submit">Add</Button>
                                        :
                                            <Button color="custom">
                                                <LoaderComponent color="white" align="center" saving={true} />
                                            </Button>
                                    } 
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    renderUpdatePaymentMethodSection(){
        const { card, saving, cardValidation, showError, paymentItem } = this.state;

        return(
            <>
                <div className="inner-header" onClick={() => this.openSection('payment-method-detail', null, paymentItem)}>
                    <h4 className="inner-title"><BackIcon />Update your payment method</h4>
                </div>
                <div className="inner-body">
                    <div className="inner-body-content">
                        <div className="update-form-section">
                            {
                                showError ?
                                    <div className="error-message-section">
                                        { this.renderErrorMessages() }
                                    </div>
                                :
                                <></>
                            }
                            <form onSubmit={(e) => this.handlePaymentSourceSubmit(e, 'update')}>
                                <GridContainer>
                                    <GridItem xs={12}>
                                        <CustomInput
                                            id="outlined-number"
                                            labelText="Card Number"                                    
                                            inputProps={{
                                                disabled: true,
                                                value: paymentItem.masked_number,
                                                onChange: (e) => this.handlePaymentMethodChange(e, 'number'),
                                                name: "number",
                                            }}                                    
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                        />
                                    </GridItem>    
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        <CustomInput
                                            success={cardValidation.expiry === "success"}
                                            error={cardValidation.expiry === "error"}
                                            id="outlined-expiry"
                                            labelText="Expiry"                                    
                                            inputProps={{
                                                value: card.expiry,
                                                onChange: (e) => this.handlePaymentMethodChange(e, 'expiry'),
                                                name: "expiry",
                                            }}                                    
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={6}>
                                        <CustomInput
                                            id="outlined-cvv"
                                            labelText="CVV"
                                            inputProps={{
                                                disabled: true,
                                                value: '***',
                                                onChange: (e) => this.handlePaymentMethodChange(e, 'cvv'),
                                                name: "cvv",
                                            }}                                    
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <div className="update-form-btn">
                                    {
                                        saving === false ?
                                            <Button color="custom" type="submit" disabled={card.expiry === paymentItem.expiry ? true : false}>Save Payment Method</Button>
                                        :
                                            <Button color="custom">
                                                <LoaderComponent color="white" align="center" saving={true} />
                                            </Button>
                                    } 
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    renderErrorMessages(){
        const { errorMessage } = this.state;
        if(typeof(errorMessage) === "object"){
            let errorMessages = [];
            let key = 0;
            for(const attrib in errorMessage){
                const message = errorMessage[attrib];
                errorMessages.push(<div key={key} className={"passwordCheck-notValid-customizable"}>
                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                    <span className="checkPasswordText-lowerletter">{message}</span>
                </div>);
                key++;
            }
            return errorMessages;
        }
        return <div className={"passwordCheck-notValid-customizable"}>
            <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
            <span className="checkPasswordText-lowerletter">{errorMessage}</span>
        </div>;
    }
    render() {
        const { classes } = this.props;
        return (
            <div>
                <div className={classes.PortalSections}>
                    { this.renderPortalSections() }         
                </div>          
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </div>
        )
    }
}

PortalSections.propTypes = {
    classes: PropTypes.object
};

export default withStyles(PortalSectionsStyle)(PortalSections);
