import Config from '../../../../../Config';
import axios from 'axios';

const ApiHelper = {
    getGoogleDriveEmail(){
        return new Promise(function(resolve, reject){
            const token = localStorage.getItem("google_drive_token");
            const isConnect = localStorage.getItem("google_drive_connected");

            const clientId = Config.getGoogleDriveClientId();
            const redirectUrl = Config.getGoogleDriveRedirectUrl();
            const scope = Config.getGoogleDriveScope();
            const responseType = 'token';
            const prompt = 'select_account';

            if(token){
                const headers = {
                    Authorization: `Bearer ${token}`,
                };

                axios({
                    url: `https://www.googleapis.com/oauth2/v2/userinfo`,
                    method: "GET",
                    headers: headers,
                }).then(res => {
                    if(res.data){
                        resolve(res.data);
                    }else{
                        reject("Invalid response from api.");
                    }
                }).catch(err => {
                    if (isConnect) {
                        localStorage.setItem("google_drive_auth_flow", 1);
                        const URL = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUrl}&response_type=${responseType}&scope=${scope}&prompt=${prompt}`;
                        window.location.replace(URL);
                    }
                    reject(err);
                });
             }else{
                reject("Login.");
             }
        });
    },
    getDriveFiles(params = {}){
        return new Promise(function(resolve, reject){
            const token = localStorage.getItem("google_drive_token");
            const isConnect = localStorage.getItem("google_drive_connected");

            const clientId = Config.getGoogleDriveClientId();
            const redirectUrl = Config.getGoogleDriveRedirectUrl();
            const scope = Config.getGoogleDriveScope();
            const responseType = 'token';
            const prompt = 'select_account';

            if(token){
                const headers = {
                    Authorization: `Bearer ${token}`,
                };

                axios({
                    url: `https://www.googleapis.com/drive/v3/files`,
                    method: "GET",
                    headers: headers,
                    params: params,
                }).then(res => {
                    if(res.data){
                        resolve(res.data);
                    }else{
                        reject("Invalid response from api.");
                    }
                }).catch(err => {
                    if (isConnect) {
                        localStorage.setItem("google_drive_auth_flow", 1);
                        const URL = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUrl}&response_type=${responseType}&scope=${scope}&prompt=${prompt}`;
                        window.location.replace(URL);
                    }
                    reject(err);
                });
            }else{
                if (isConnect) {
                    localStorage.setItem("google_drive_auth_flow", 1);
                    const URL = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUrl}&response_type=${responseType}&scope=${scope}&prompt=${prompt}`;
                    window.location.replace(URL);
                }
                reject("Add Account");
            }
        });
    },
    fetchDownloadData(fileId, mimeType){
        return new Promise(function(resolve, reject){
            const token = localStorage.getItem("google_drive_token");
            if(token){
                let url = '';
                if (mimeType === 'application/vnd.google-apps.folder') {
                    return '';
                } else if (mimeType.startsWith('application/vnd.google-apps')) {
                    url = `https://www.googleapis.com/drive/v3/files/${fileId}/export?mimeType=application/pdf&alt=media`;
                } else {
                    url = `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`;
                }
                url = `https://www.googleapis.com/drive/v3/files/${fileId}?fields=webContentLink,webViewLink`;

                const headers = {
                    Authorization: `Bearer ${token}`,
                };

                axios({
                    url: url,
                    method: "GET",
                    headers: headers,
                }).then(res => {
                    if(res.data){
                        resolve(res.data);
                    }else{
                        reject("Invalid response from api.");
                    }
                }).catch(err => {
                    return null;
                });
            }
        });
    },
    getDownloadUrl(fileId, mimeType){
        let url = '';
        if (mimeType === 'application/vnd.google-apps.folder') {
            url = '';
        } else if (mimeType.startsWith('application/vnd.google-apps')) {
            url = `https://www.googleapis.com/drive/v3/files/${fileId}/export?mimeType=application/pdf&alt=media`;
        } else {
            url = `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`;
        }

        return url;
    }
}
export default ApiHelper;
export { ApiHelper };