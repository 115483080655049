import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import LoaderComponent from "../Loader.js";
import Close from "@material-ui/icons/Close";
import processingModalStyle from "../../../assets/jss/user/processingModalStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const WelcomeModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
    }
    render() {
        const { classes, saving, loadingMessage, successMessage, errorMessage } = this.props;
        const { user } = this.store.getState();

        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="processingModal-modal-slide-title"
                aria-describedby="processingModal-modal-slide-description"
            >
                <DialogTitle
                    id="processingModal-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                >
                    {
                        saving === false && successMessage !== '' ?
                            <Button
                                simple
                                className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                                key="close"
                                aria-label="Close"
                                onClick={() => this.props.onClose()}
                            >
                                <Close className={classes.modalClose} />
                            </Button>
                        :
                            <></>
                    }
                    {
                        errorMessage !== null ?
                            <></>
                        :
                            saving ?
                                <h4 className={classes.modalTitle}>{loadingMessage}</h4> 
                            :
                                <h4 className={classes.modalTitle}>{successMessage}</h4>
                    }
                </DialogTitle>
                <DialogContent
                    id="processingModal-modal-slide-description"
                    className={classes.modalBody+" "+(successMessage === '' ? classes.textCenter+" pt-0" : '')}
                    >
                        {
                            errorMessage !== null ?
                                <p className={classes.errorMessage}>{errorMessage}</p>
                            :
                                saving ?
                                    <LoaderComponent color="custom" align="center" />
                                : successMessage === '' ?
                                    <>
                                        <img src={user.welcomeContent.photoUrl} alt={user.welcomeContent.title} />
                                        <h4>{user.welcomeContent.title}</h4>
                                        <p className='text-content'>{user.welcomeContent.content}</p>
                                    </>
                                :
                                    <></>
                                    
                        }
                </DialogContent>
                <DialogActions className={classes.modalFooter+" "+(successMessage === '' ? classes.justifyContentCenter : '')}>
                    {
                        saving === false ?
                            <Button color="custom" onClick={() => this.props.onSuccess()}>
                                {
                                    successMessage === '' ? user.welcomeContent.buttonText : 'Close'
                                }
                            </Button>
                        :
                            <></>
                    }
                </DialogActions>
            </Dialog>
        )
    }
}

WelcomeModal.defaultProps = {
    open: false,
    loadingMessage: "",
    successMessage: "",
    saving: false,
    errorMessage: null,
}
WelcomeModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    loadingMessage: PropTypes.string,
    saving: PropTypes.bool,
    errorMessage: PropTypes.string
}
export default withStyles(processingModalStyle)(WelcomeModal);