import modalStyle from "../material-kit-pro-react/modalStyle.jsx";
import userHomePageStyle from "./userHomePageStyle.jsx";
const userAccountModalStyle = theme => ({
    ...userHomePageStyle(theme),
    ...modalStyle(theme),
    modal: {
        ...modalStyle(theme).modal,
        maxWidth: "550px !important",
        padding: "25px 28px 30px",
    },
    modalHeader: {
        padding: "0px",
        position: "relative",
    },
    modalTitle: {
        ...modalStyle(theme).modalTitle,
        textAlign: "left",
        fontWeight: "600",
        "& button": {
            padding: "0px",
            width: "auto",
            height: "auto"
        }
    },
    modalCloseButtonCustom: {
        position: "absolute",
        top: "0px",
        right: "0px",
        margin: "0px",
        padding: "0px",
        "& svg": {
            marginRight: "0px"
        }
    },
    modalBody: {
        ...modalStyle(theme).modalBody,
        paddingLeft: "0px",
        paddingRight: "0px",
        paddingTop: "25px",
        paddingBottom: "20px",
        textAlign: "left",
        "& .sd_loader": {
            width: "300px !important",
            height: "300px !important",
            [theme.breakpoints.down("xs")]: {
                width: "180px !important",
                height: "180px !important",
            },
            "& > div": {
                width: "250px !important",
                height: "250px !important",
                [theme.breakpoints.down("xs")]: {
                    width: "150px !important",
                    height: "150px !important",
                },
            },
            "& svg": {
                "& circle": {
                    strokeWidth: "1px"
                }
            }
        },
        "& .marginTop": {
            marginTop: "20px"
        },
        "& .defultPath": {
            " &p": {
                margin: "0px",
            }
        }
    },
    modalFooter: {
        ...modalStyle(theme).modalFooter,
        justifyContent: "end !important",
        MsFlexPack: "end !important",
        WebkitBoxPack: "end !important",
        padding: "0px",
    },
    sdBackNewfolder: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& svg": {
            width: "28px",
            height: "28px",
            color: theme.customize.iconsColor
        }
    }
});

export default userAccountModalStyle;
