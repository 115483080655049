import React from 'react';
import { Redirect } from 'react-router-dom';
import Cookie from '../../../assets/js/utils/Cookie';
import axios from "axios";
import { helper } from '../../../assets/js/utils/Element';
import Api from "../../../assets/js/utils/Api";
import PasswordMatch from "../../../assets/js/utils/PasswordMatch";
import LoaderComponent from '../../components/Loader'
import withStyles from "@material-ui/core/styles/withStyles";
import CustomInput from "../../components/CustomInput/CustomInput.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import Card from "../../components/Card/Card.jsx";
import CardBody from "../../components/Card/CardBody.jsx";
// import InfoArea from "../../components/InfoArea/InfoArea.jsx";
import Warning from "../../components/Typography/Warning.jsx";
// import BackgroundSlider from "../../components/Slider/BackgroundSlider";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Check from "@material-ui/icons/Check";
import Link from '@material-ui/core/Link';
import CustomSelect from "../../components/CustomInput/CustomSelect";

// @material-ui/icons
// import Timeline from "@material-ui/icons/Timeline";
// import Code from "@material-ui/icons/Code";
// import Group from "@material-ui/icons/Group";
import AuthIcon from "@material-ui/icons/Lock";
import RecaptchaV2 from "../../components/Google/RecaptchaV2";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import InputAdornment from "@material-ui/core/InputAdornment";

import signupPageStyle from "../../../assets/jss/material-kit-pro-react/views/signupPageStyle.jsx";

const BasicSignup = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.resendCode = this.resendCode.bind(this);
        this.confirmSignUp = this.confirmSignUp.bind(this);

        let planId = helper.getParam("id");
        if(planId === null || planId === "null"){
            planId = "1";
        }
        this.state = {
            id: this.props.match.params.id,
            firstName: '',
            lastName: '',
            email: this.props.match.params.email,
            password: "",
            passwordConfirm: "",
            accountName: "",
            phone: "",
            cell: "",
            address1: "",
            address2: "",
            address3: "",
            accountType: planId,
            code: "",
            countryId: "",
            stateId: "",
            cityId: "",
            dob: "",
            timezone: "",
            loading: false,
            terms: true,
            promotions: true,
            validation: {
                firstName: '',
                lastName: '',
                email: '',
                password: '',
                passwordConfirm: '',
                accountName: '',
                code: '',
                countryId: '',
                stateId: '',
                timezone: '',
                isValid: false,
                isValidate: false,
                validateForm: false
            },
            recaptchaToken: "",
            requireLowerletter: false,
            requireUpperletter: false,
            requireNumber: false,
            requireSymbol: false,
            requireLength: false,
            requestCode: false,
            showError: false,
            errorMessage: "",
            user: null,
            advancedFields: false,
            countries: [],
            states: [],
            cities: [],
            loadingCountries: false,
            loadingStates: false,
            loadingCities: false,
            showRecaptcha: true,
            response: null,
            showLogo: false,
            viewPassword: false,
            viewConfirmPassword: false
        };

        this.timezoneOptions = Api.getTimezoneOptions();
    }
    componentDidMount(){
        this.loadCountries();
        this.loadPreview();
        document.body.classList.add('hide_header_business_account');
        document.body.classList.add('hide_business_background_photo');

        if(this.state.accountType !== ""){
            this.canSignup();
        }
    }
    componentDidUpdate(prevProps, prevState){
        const { response } = this.state;
        if(prevState.response === null && response !== null && response.hasOwnProperty('businessUser') && response.businessUser !== null && (response.businessUser.icon_photo_url !== '' && response.businessUser.icon_photo_url !== null)){
            document.body.classList.add('business_account');
            document.body.classList.remove('hide_header_business_account');
            this.setState({
                showLogo: true
            })
        }else if(prevState.response === null && response !== null){
            document.body.classList.remove('hide_header_business_account');
        }

        if(prevState.response === null && response !== null && response.hasOwnProperty('businessUser') && response.businessUser !== null && (response.businessUser.background_photo !== '' && response.businessUser.background_photo !== null)){
            document.body.classList.add('business_background_photo');
            document.body.classList.remove('hide_business_background_photo');
        }else if(prevState.response === null && response !== null){
            document.body.classList.remove('hide_business_background_photo');
        }
    }
    componentWillUnmount(){
        if (this.cancelToken) {
            this.cancelToken.cancel('Request Cancelled')
        }
        document.body.classList.remove('business_account');
        document.body.classList.remove('business_background_photo');
    }
    canSignup(){
        const that = this;
        const source = axios.CancelToken.source();
        this.cancelToken = source;
        that.setState({
            cancelToken: source,
        });

        let requestData = {
            accountType: this.state.accountType
        }
        Api.canSignup(requestData, source).then(data => {
            if(data.canSignup === false){
                this.setState({
                    loading: false, 
                });

                window.location.href = data.path;
                return;
            }

            that.setState({
                loading: false,
            });
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message")){
                this.setState({
                    loading: false, 
                    showError: true, 
                    cancelToken: null,
                    errorMessage: err.message,
                    advancedFields: false
                });
            }
        });

        this.setState({loading: false, showError: false, cancelToken: source});
    }
    handleChange(e, name){
        let state = {};
        state[name] = e.target.value;
        if(name === "email"){
            state[name] = e.target.value.toLowerCase();
        }
        this.setState(state,() => {
            if(name === 'password' || name === 'passwordConfirm'){
                this.validatePassword();
            }
        });
    }
    handleDateChange(date, name) {
        try{
            let parsedDate = new Date(date);
            if(parsedDate === "Invalid Date"){
                return;
            }
            this.setState({
                [name]: parsedDate.toISOString()
           });
        }catch(e){ console.log(e); }        
    }
    handleCheckbox(e, name){
        let state = {};
        state[name] = e.target.checked;
        this.setState(state);
    }
    handleSubmit(e){
        e.preventDefault();
        const isValid = this.validateForm();
        if(!isValid || this.state.loading){
            return;
        }
        let values = this.state;
        const { recaptchaToken } = this.state;
        const requestData = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            password: values.password,
            accountName: values.accountName,
            cell: values.cell,
            phone: values.phone,
            address1: values.address1,
            address2: values.address2,
            address3: values.address3,
            accountType: values.accountType,
            countryId: values.countryId,
            stateId: values.stateId,
            cityId: values.cityId,
            dob: values.dob,
            timezone: values.timezone,
            recaptchaToken: recaptchaToken,
            terms: values.terms,
            promotions: values.promotions,
            basicShared: 1,
            signupControl: 1
        };
        const source = axios.CancelToken.source();
        Api.signUp(requestData, source).then(data => {
            if(data.canSignup === false){
                this.setState({
                    loading: false, 
                });

                window.location.href = data.path;
                return;
            }
            
            this.setState({
                loading: false, 
                // requestCode: true,
                user: data.user
            });

            Cookie.write("oauth_token", data.oauth_token);
            Cookie.write("oauth_secret", data.oauth_secret);
            Cookie.write("expires_at", data.expires_at);
            localStorage.setItem("sendlinx_userinfo", JSON.stringify(data.user));
            if(data.hasOwnProperty("checkout_url")){
                window.location.href = data.checkout_url;
                return;
            }
            this.history.push("/user/shared/transfer/"+this.state.id);
            this.store.dispatch({
                type: "LOGIN",
                state: {
                    authorized: true,
                    user: data.user
                }
            }); 
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message")){
                this.setState({
                    loading: false, 
                    showError: true, 
                    cancelToken: null,
                    errorMessage: err.message,
                    advancedFields: false
                });
            }
        });
        this.setState({loading: true, showError: false, cancelToken: source});
    }
    validatePassword(){
        if(this.state.validation.validateForm){
            this.validateForm();
            return;
        }
        let validation = {
            password: "success",
            passwordConfirm: "success",
            isValid: true
        };

        const that = this;
        const check = PasswordMatch.check(this.state.password,function(requireLowerletter, requireUpperletter, requireNumber, requireSymbol, requireLength){
            that.setState({
                showPasswordErrors: true,
                requireLowerletter: requireLowerletter,
                requireUpperletter: requireUpperletter,
                requireNumber: requireNumber,
                requireSymbol: requireSymbol,
                requireLength: requireLength
            });
        });
        
        if(this.state.password.length <= 0 || check === false){
            validation.password = "error";
            validation.isValid = false;
        }
        if(this.state.passwordConfirm.length <= 0 || this.state.password !== this.state.passwordConfirm){
            validation.passwordConfirm = "error";
            validation.isValid = false;
        }
        validation.isValidate = true;

        this.setState({validation: validation});
    }
    validateForm(){
        let validation = {
            firstName: "success",
            lastName: "success",
            email: "success",
            password: "success",
            passwordConfirm: "success",
            timezone: "success",
            isValid: true
        };

        if(this.state.firstName.length <= 0){
            validation.firstName = "error";
            validation.isValid = false;
        }
        if(this.state.lastName.length <= 0){
            validation.lastName = "error";
            validation.isValid = false;
        }

        var emailRegex = /\S+@\S+\.\S+/;
        if(this.state.email.length <= 0 || !emailRegex.test(this.state.email)){
            validation.email = "error";
            validation.isValid = false;
        }
        
        const that = this;
        const check = PasswordMatch.check(this.state.password,function(requireLowerletter, requireUpperletter, requireNumber, requireSymbol, requireLength){
            that.setState({
              showPasswordErrors: true,
              requireLowerletter: requireLowerletter,
              requireUpperletter: requireUpperletter,
              requireNumber: requireNumber,
              requireSymbol: requireSymbol,
              requireLength: requireLength
            });
        });
        if(this.state.password.length <= 0 || check === false){
            validation.password = "error";
            validation.isValid = false;
        }
        if(this.state.passwordConfirm.length <= 0 || this.state.password !== this.state.passwordConfirm){
            validation.passwordConfirm = "error";
            validation.isValid = false;
        }
        if(this.state.timezone.length <= 2){
            validation.timezone = "error";
            validation.isValid = false;
        }
        validation.isValidate = true;
        validation.validateForm = true;

        this.setState({validation: validation});
        return validation.isValid;
    }
    validateAdvForm(){
        let validation = this.state.validation;
        validation.countryId = 'success';
        validation.stateId = 'success';
        validation.accountName = "success";
        validation.isValid = true;
        if(this.state.accountName.length <= 0){
            validation.accountName = "error";
            validation.isValid = false;
        }
        if(this.state.countryId.length <= 2){
            validation.countryId = "error";
            validation.isValid = false;
        }
        if(this.state.stateId.length <= 2){
            validation.stateId = "error";
            validation.isValid = false;
        }

        if(this.state.recaptchaToken.length <= 0){
            validation.isValid = false;
        }
        validation.isValidate = true;

        this.setState({validation: validation});
        return validation.isValid;
    }
    resendCode(event){
        event.preventDefault();
        event.stopPropagation();
        const requestData = {
            email: this.state.email,
        };
        const source = axios.CancelToken.source();
        Api.resendCode(requestData, source).then(data => {
            this.setState({
                loading: false, 
            });
        }).catch(err => {
            
        });
        this.setState({loading: true, showError: false, cancelToken: source});
    }
    confirmSignUp(event){
        event.preventDefault();
        event.stopPropagation();
        if (this.state.code.length <= 0) {
            this.setState({
                ...this.state,
                validation: {
                    ...this.state.validation,
                    code: "error"
                }
            });
            return;
        }
        const source = axios.CancelToken.source();
        const code = this.state.code;
        this.setState({loading: true, showError: false, cancelToken: source});
        const requestData = {
            code: code,
            email: this.state.email,
            cancelToken: source
        };
        Api.confirmSignUp(requestData, source).then(data => {
            Cookie.write("oauth_token", data.oauth_token);
            Cookie.write("oauth_secret", data.oauth_secret);
            Cookie.write("expires_at", data.expires_at);
            localStorage.setItem("sendlinx_userinfo", JSON.stringify(data.user));
            if(data.hasOwnProperty("checkout_url")){
                window.location.href = data.checkout_url;
                return;
            }
            this.history.push("/user/shared/transfer/"+this.state.id);
            this.store.dispatch({
                type: "LOGIN",
                state: {
                    authorized: true,
                    user: data.user
                }
            }); 
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message")){
                this.setState({
                    loading: false, 
                    showError: true, 
                    cancelToken: null,
                    errorMessage: err.message
                });
            }
        });;
    }
    renderErrorMessages(){
        const { errorMessage } = this.state;
        if(typeof(errorMessage) === "object"){
            let errorMessages = [];
            let key = 0;
            for(const attrib in errorMessage){
                const message = errorMessage[attrib];
                errorMessages.push(<GridItem key={key} className={"passwordCheck-notValid-customizable"}>
                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                    <span className="checkPasswordText-lowerletter">{message}</span>
                </GridItem>);
                key++;
            }
            return errorMessages;
        }
        return <GridItem className={"passwordCheck-notValid-customizable"}>
            <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
            <span className="checkPasswordText-lowerletter">{errorMessage}</span>
        </GridItem>;
    }
    showAdvancedFeilds(){
        const isValid = this.validateForm();
        if(!isValid){
            return;
        }
        this.setState({advancedFields: true});
    }
    hideAdvancedFeilds(){
        this.setState({advancedFields: false});
    }
    loadCountries(){
        const that = this;
        const source = axios.CancelToken.source();
        this.cancelToken = source;
        that.setState({
            cancelToken: source,
            loadingCountries: true,
            countries: []
        });
        Api.getCountries(source).then(data => {
            that.setState({
                countries: data.response,
                loadingCountries: false
            });
        }).catch(err => {
            console.log(err);
        });
    }
    onCountryChange(e){
        const country = e.target.value;
        this.loadStates(country);
    }
    loadStates(country){
        const { countryId } = this.state;
        if(countryId === country){
            return;
        }
        const that = this;
        const source = axios.CancelToken.source();
        this.cancelToken = source;
        that.setState({
            cancelToken: source,
            loadingStates: true,
            countryId: country,
            states: [],
            stateId: ""
        });
        Api.getStates(country, source).then(data => {
            that.setState({
                states: data.response,
                loadingStates: false
            });
        }).catch(err => {
            console.log(err);
        });
    }
    onStateChange(e){
        const state = e.target.value;
        this.loadCities(state);
    }
    loadCities(state){
        const { stateId } = this.state;
        if(stateId === state){
            return;
        }
        const that = this;
        const source = axios.CancelToken.source();
        this.cancelToken = source;
        const { countryId } = this.state;
        that.setState({
            cancelToken: source,
            loadingCities: true,
            stateId: state,
            cities: [],
            cityId: ""
        });
        Api.getCities(countryId, state, source).then(data => {
            that.setState({
                cities: data.response,
                loadingCities: false
            });
        }).catch(err => {
            console.log(err);
        });
    }
    onRecaptcha(token){
        this.setState({
            showRecaptcha: false,
            recaptchaToken: token
        });
    }
    loadPreview(){
        const that = this;
        const source = axios.CancelToken.source();
        this.cancelToken = source;
        that.setState({
            cancelToken: source
        });
        const { id } = this.state;
        Api.getTransfer(id, "", source).then(data => {
            that.setState({
                response: data,
            });
        }).catch(err => {
            if(err.message !== "Request Cancelled"){
                this.setState({
                    loading: false,
                });
            }
        });
    }
    onViewPassword(name = 'viewPassword'){
        let state = {};
        if(name  === 'viewPassword'){
            state['viewPassword'] = !this.state.viewPassword;
        }else if(name === 'viewConfirmPassword'){
            state['viewConfirmPassword'] = !this.state.viewConfirmPassword;
        }
        this.setState(state);
    }
    render() {
        const { classes } = this.props;
        const { validation, loading, requestCode, showError } = this.state;
        const { requireLowerletter, requireUpperletter, requireNumber, requireSymbol, requireLength, password, passwordConfirm, 
            showRecaptcha, response, viewPassword, viewConfirmPassword } = this.state;
        const { authorized } = this.store.getState();
        const showPasswordErrors = (validation.password === "success" || validation.password === "error");
        const showPasswordConfirmErrors = (validation.passwordConfirm === "success" || validation.passwordConfirm === "error");
        const requirePasswordConfirm = (passwordConfirm.length > 0 && password === passwordConfirm);
        if(authorized){
            return <Redirect to='/' />
        }
        return (
            <div>
                {
                    response && response.hasOwnProperty('businessUser') && response.businessUser !== null && (response.businessUser.profile_photo_url !== '' && response.businessUser.profile_photo_url !== null) ?
                        <div className={classes.businessLeagelHeader}>
                            <img className={"business-logo"} src={response.businessUser.profile_photo_url} alt="company logo" />
                        </div>
                    :
                        <></>
                }
                <div className={classes.container}>
                    <GridContainer justifyContent="center">
                    <GridItem xs={12} sm={10} md={6}>
                        <Card className={classes.cardSignup+" basic-signup "+(validation.isValidate ? ' validate' : '')}>
                        <h2 className={classes.cardTitle}>Register</h2>
                        {
                            requestCode === false ?
                                <p>Please identify yourself and create a secure password for your access</p>
                            :
                            <></>
                        }
                        <CardBody>
                            <GridContainer justifyContent="center">
                                <GridItem xs={12} sm={12} md={12}>
                                    {
                                        requestCode ?
                                            <div>
                                                <GridItem>
                                                    <Warning>
                                                        We have sent a code by email. Please check your spam or junk folder if you could not receive email in Inbox. Enter it below to confirm your account.
                                                    </Warning>
                                                </GridItem>
                                                <GridContainer>
                                                    <GridItem>
                                                        {
                                                            showError ?
                                                                this.renderErrorMessages()
                                                            :
                                                            <></>
                                                        }
                                                        <form onSubmit={this.confirmSignUp} noValidate>
                                                            <GridItem>                             
                                                                <CustomInput
                                                                    success={validation.code === "success"}
                                                                    error={validation.code === "error"}
                                                                    id="input-code"
                                                                    labelText="Verification Code"
                                                                    inputProps={{
                                                                        required: true,
                                                                        onChange: (e) => this.handleChange(e,'code'),
                                                                        name: "code",
                                                                        type: "text",
                                                                    }}                                    
                                                                    formControlProps={{
                                                                        fullWidth: true
                                                                    }}
                                                                />
                                                            </GridItem>                              
                                                            <GridItem>
                                                                {
                                                                    loading ?
                                                                        <LoaderComponent />
                                                                    :
                                                                        <>
                                                                        <Button type="submit" color="primary" round>
                                                                            <AuthIcon className={classes.icons} /> Confirm Account
                                                                        </Button>
                                                                        <p className="redirect-customizable text-center mt-2"><span>Didn't receive a code?</span>&nbsp;<span className={classes.anchor} onClick={(e) => this.resendCode(e)}>Resend it</span></p>
                                                                        </>
                                                                }
                                                                
                                                            </GridItem>
                                                        </form>
                                                    </GridItem>
                                                </GridContainer>                        
                                            </div>
                                        :
                                        <>
                                            {
                                                showError ?
                                                    this.renderErrorMessages()
                                                :
                                                <></>
                                            }
                                            <form className={classes.form}>
                                            <CustomInput
                                                    formControlProps={{
                                                        fullWidth: true,
                                                        className: classes.customFormControlClasses
                                                    }}
                                                    success={validation.firstName === "success"}
                                                    error={validation.firstName === "error"}
                                                    id="input-firstName"
                                                    labelText="First Name"
                                                    inputProps={{
                                                        onChange: (e) => this.handleChange(e, 'firstName'),
                                                        name: "firstName",
                                                        value: this.state.firstName,
                                                    }}
                                                />
                                                <CustomInput
                                                    formControlProps={{
                                                        fullWidth: true,
                                                        className: classes.customFormControlClasses
                                                    }}
                                                    success={validation.lastName === "success"}
                                                    error={validation.lastName === "error"}
                                                    id="input-lastName"
                                                    labelText="Last Name"
                                                    inputProps={{
                                                        onChange: (e) => this.handleChange(e, 'lastName'),
                                                        name: "lastName",
                                                        value: this.state.lastName,
                                                    }}
                                                />
                                                <CustomInput
                                                    formControlProps={{
                                                        fullWidth: true,
                                                        className: classes.customFormControlClasses
                                                    }}
                                                    success={validation.email === "success"}
                                                    error={validation.email === "error"}
                                                    id="input-email"
                                                    labelText="Email"
                                                    inputProps={{
                                                        onChange: (e) => this.handleChange(e, 'email'),
                                                        name: "email",
                                                        value: this.state.email,
                                                        disabled: true,
                                                        type: "email"
                                                    }}
                                                />
                                                <p className={classes.verificationNote+" "+classes.hide}>A verification code will be sent to this email to complete registration.</p>
                                                <CustomInput
                                                    formControlProps={{
                                                        fullWidth: true,
                                                        className: classes.customFormControlClasses
                                                    }}
                                                    success={validation.password === "success"}
                                                    error={validation.password === "error"}
                                                    id="input-password"   
                                                    labelText="Password"  
                                                    passwordInput={viewPassword ? false : true}                                   
                                                    inputProps={{
                                                        onChange: (e) => this.handleChange(e,'password'),
                                                        name: "password",
                                                        type: "text",
                                                        value: password,
                                                        autoComplete: 'off',
                                                        endAdornment: (
                                                            <InputAdornment 
                                                                position="end" 
                                                                className={classes.inputAdornment+' viewPasswordIcon'} 
                                                                onClick={() => this.onViewPassword('viewPassword') }
                                                            >
                                                                {
                                                                    password ?
                                                                        viewPassword ?
                                                                            <VisibilityOffIcon className={classes.inputAdornmentIcon}/>
                                                                        :
                                                                            <VisibilityIcon className={classes.inputAdornmentIcon}/>
                                                                    :
                                                                        <></>
                                                                }
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                {
                                                    showPasswordErrors || password.length > 0 ?
                                                        <GridItem>
                                                            <div>
                                                                <div className={(requireLowerletter?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-lowerletter"}>
                                                                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">{requireLowerletter? '\u2713' : '\u2716' }</span>
                                                                    <span className="checkPasswordText-lowerletter">Password must contain a lower case letter</span>
                                                                </div>
                                                                <div className={(requireUpperletter?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-upperletter"}>
                                                                    <span aria-hidden="true" className="validation-error-symbol check-upperletter">{requireUpperletter? '\u2713' : '\u2716' }</span>
                                                                    <span className="checkPasswordText-upperletter">Password must contain an upper case letter</span>
                                                                </div>
                                                                <div className={(requireNumber?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-numbers"}>
                                                                    <span aria-hidden="true" className="validation-error-symbol check-symbols">{requireNumber? '\u2713' : '\u2716' }</span>
                                                                    <span className="checkPasswordText-symbols">Password must contain a number</span>
                                                                </div>
                                                                <div className={(requireSymbol?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-symbols"}>
                                                                    <span aria-hidden="true" className="validation-error-symbol check-numbers">{requireSymbol? '\u2713' : '\u2716' }</span>
                                                                    <span className="checkPasswordText-numbers">Password must contain a special character</span>
                                                                </div>
                                                                <div className={(requireLength?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-length"}>
                                                                    <span aria-hidden="true" className="validation-error-symbol check-length">{requireLength? '\u2713' : '\u2716' }</span>
                                                                    <span className="checkPasswordText-length">Password must contain at least 8 characters</span>
                                                                </div>
                                                            </div>
                                                        </GridItem>
                                                    :
                                                    <></>
                                                }
                                                <CustomInput
                                                    success={validation.passwordConfirm === "success"}
                                                    error={validation.passwordConfirm === "error"}
                                                    id="input-signupPasswordConfirm"
                                                    labelText="Type Password Again"
                                                    passwordInput={viewConfirmPassword ? false : true}
                                                    inputProps={{
                                                        required: true,
                                                        onChange: (e) => this.handleChange(e,'passwordConfirm'),
                                                        name: "passwordConfirm",
                                                        type: "text",
                                                        value: passwordConfirm,
                                                        autoComplete: 'off',
                                                        endAdornment: (
                                                            <InputAdornment 
                                                                position="end" 
                                                                className={classes.inputAdornment+' viewPasswordIcon'} 
                                                                onClick={() => this.onViewPassword('viewConfirmPassword') }
                                                            >
                                                                {
                                                                    passwordConfirm ?
                                                                        viewConfirmPassword ?
                                                                            <VisibilityOffIcon className={classes.inputAdornmentIcon}/>
                                                                        :
                                                                            <VisibilityIcon className={classes.inputAdornmentIcon}/>
                                                                    :
                                                                        <></>
                                                                }
                                                            </InputAdornment>
                                                        ),
                                                    }}                                    
                                                    formControlProps={{
                                                        fullWidth: true,
                                                        className: classes.customFormControlClasses
                                                    }}
                                                />
                                                {
                                                    showPasswordConfirmErrors ?
                                                        <GridItem>
                                                            <div>
                                                                <div className={(requirePasswordConfirm?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-lowerletter"}>
                                                                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">{requirePasswordConfirm? '\u2713' : '\u2716' }</span>
                                                                    <span className="checkPasswordText-lowerletter">Password do not match</span>
                                                                </div>
                                                            </div>
                                                        </GridItem>
                                                    :
                                                    null
                                                }
                                                <CustomSelect 
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    labelText="Timezone"
                                                    selectProps={{
                                                        onChange: (e) => this.handleChange(e,"timezone"),
                                                        value: this.state.timezone
                                                    }}
                                                    inputProps={{
                                                        name: "timezone",
                                                        id: "input-timezone",
                                                        className: classes.alignLeft
                                                    }}
                                                    success={validation.timezone === "success"}
                                                    error={validation.timezone === "error"}
                                                    items={this.timezoneOptions}
                                                    itemLabel="value"
                                                    itemValue="key"
                                                />
                                                <FormControlLabel
                                                    classes={{label: classes.label}}
                                                    control={
                                                        <Checkbox                                                
                                                            tabIndex={-1}
                                                            onClick={(e) => this.handleCheckbox(e, "promotions")}
                                                            checkedIcon={<Check className={classes.checkedIcon} />}
                                                            icon={<Check className={classes.uncheckedIcon} />}
                                                            classes={{checked: classes.checked, root: classes.checkRoot}}
                                                            checked={this.state.promotions}
                                                        />
                                                    }
                                                    label={"I agree to recieve instructional and promotion emails"}
                                                />

                                                <FormControlLabel
                                                    classes={{label: classes.label}}
                                                    control={
                                                        <Checkbox                                                
                                                            tabIndex={-1}
                                                            onClick={(e) => this.handleCheckbox(e, "terms")}
                                                            checkedIcon={<Check className={classes.checkedIcon} />}
                                                            icon={<Check className={classes.uncheckedIcon} />}
                                                            classes={{checked: classes.checked, root: classes.checkRoot}}
                                                            checked={(this.state.terms)}
                                                        />
                                                    }
                                                    label={
                                                        <span>I agree to Sendlinx's <Link href="https://sendlinx.com/terms-of-use/" target="_blank">Term of Use</Link> & <Link href="https://sendlinx.com/privacy-policy/" target="_blank">Privacy Policy</Link></span>
                                                    }
                                                />
                                                <div className={classes.recaptcha}>
                                                    <RecaptchaV2 onChange={(response) => this.onRecaptcha(response)} show={showRecaptcha} />
                                                </div>
                                                <div className={classes.textCenter}>
                                                    {
                                                        loading ?
                                                            <LoaderComponent />
                                                        :
                                                            <Button round color="primary" onClick={this.handleSubmit}>Signup</Button>
                                                    }
                                                </div>
                                            </form>
                                        </>
                                    }
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        </Card>
                    </GridItem>
                    </GridContainer>
                </div>
                {
                    response && response.hasOwnProperty('businessUser') && response.businessUser !== null && (response.businessUser.background_photo !== '' && response.businessUser.background_photo !== null ) ?
                        <div className="business_bg_slider">
                            <div className={classes.backgroundSlider}>
                                <div className="background-image" style={{backgroundImage: 'url('+response.businessUser.background_photo+')'}}></div>
                                <div className="banner-text-content">
                                    <div className="banner-text">
                                        <p>Large file transfers made simple & secure.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    :
                        <></>
                }
            </div>
        )
    }
}

export default withStyles(signupPageStyle)(BasicSignup);