import React from 'react';
import { Helmet } from 'react-helmet';
import axios from "axios";
import Api from "../../../assets/js/utils/Api.js";
import ApiDrive from "../../../assets/js/utils/oneDrive/Api";
import ApiSharepoint from "../../../assets/js/utils/Sharepoint/Api";
import ApiGoogleDrive from "../../../assets/js/utils/GoogleDrive/Api";
import Auth from "../../../assets/js/utils/Auth";
import PropTypes from "prop-types";
import BackgroundSlider from "../../components/Slider/BackgroundSlider";
import Button from "../../components/CustomButtons/Button";
import GridItem from "../../components/Grid/GridItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { helper } from "../../../assets/js/utils/Element";
import withStyles from "@material-ui/core/styles/withStyles";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import EditIcon from "@material-ui/icons/Edit";
import NewEditAccountModal from '../../components/User/oneDrive/NewEditAccountModal';
import SharepointAccountModal from "../../components/User/Sharepoint/SharepointAccountModal";
import ConfirmationModal from '../../components/User/ConfirmationModal.js';
import Config from '../../../../Config';
import MessageModal from "../../components/User/ContactConfirmationModal";
import SignModal from "../../components/Docusign/SignModal.jsx";
import AdobeSignModal from "../../components/Adobesign/SignModal.jsx";
import userSettingsPageStyle from "../../../assets/jss/user/userSettingsPageStyle.jsx";

const UserExtensions = class extends React.Component {
    constructor(props) {
        super(props);
        this.store = this.props.store;
        this.history = this.props.history;
        const { user } = this.store.getState();
        this.state = {
            isPopupOpen: false,
            edit: false,
            newEditAccountModal: false,
            editAccountType: '',
            user: user,
            extensions: user.extensions,
            saving: false,
            validation: {
                isValid: false,
            },
            cancelToken: null,
            sharepointAccountModal: false,
            messageModal: false,
            messageModalMessage: '',
            deleteAccountModal: false,
            deleteAccountType: '',
            deleteingAccount: false,
            docusignModal: false,
            adobesignModal: false,
        };

        this.handleSubmit = this.handleSubmit.bind(this);

        this.scrollContainerRef = React.createRef();
        this.scrollPosition = 0;
    }
    componentDidMount() {
        const { authorized, user } = this.store.getState();
        if (!authorized && !Auth.hasAccessToken()) {
            const location = this.history.location;
            const loginRequired = "/auth/login?return=" + encodeURIComponent(location.pathname + location.search);
            this.history.push(loginRequired);
            return;
        }

        if (authorized && user.hasOwnProperty('businessUser') && user.businessUser !== null && user.member_type !== 'business') {
            let redirectUrl = "/user/home";
            if (user.level_id === 5) {
                redirectUrl = "/user/shared";
            }
            this.history.push(redirectUrl);
        }

        if(authorized){
            let showAccessPopup = helper.getParam("showAccessPopup");
            let type = helper.getParam("type");
            let showOneDriveAccountModal = helper.getParam("showOneDriveAccountModal");
            let showSharepointAccountModal = helper.getParam("showSharepointAccountModal");
            let showGoogleDriveAccountModal = helper.getParam("showGoogleDriveAccountModal");
            
            if(showAccessPopup !== 'null' && type !== 'null'){
                let message = '';
                if(type === 'onedrive'){                
                    message = localStorage.getItem("one_drive_email")+' does not have a OneDrive account that is accessible.';
                }else if(type === 'sharepoint'){
                    message = localStorage.getItem("sharepoint_email")+' does not have a Sharepoint account that is accessible.';
                }

                this.messageModal(true, message);
            }else if(showOneDriveAccountModal !== 'null'){
                this.setState({
                    newEditAccountModal: true,
                    editAccountType: 'one_drive'
                })
            }else if(showSharepointAccountModal !== 'null'){
                this.setState({
                    sharepointAccountModal: true
                })
            }else if(showGoogleDriveAccountModal !== 'null'){
                this.setState({
                    newEditAccountModal: true,
                    editAccountType: 'google_drive',
                })
            }else{
                const currentURL = window.location.href;
                const updatedURL = currentURL.replace(/#/g, '?');
                window.history.replaceState(null, '', updatedURL);
                let accessToken = helper.getParam("access_token");
                const oneDriveAuth = localStorage.getItem("one_drive_auth_flow");
                const sharepointAuth = localStorage.getItem("sharepoint_auth_flow");
                const googleDriveAuth = localStorage.getItem("google_drive_auth_flow");

                if (accessToken !== 'null') {
                    if(sharepointAuth === "1"){
                        localStorage.setItem("sharepoint_token", accessToken);
                        this.getEmailId(true);
                    }else if(oneDriveAuth === "1"){
                        localStorage.setItem("one_drive_token", accessToken);
                        this.getEmailId();
                    }else if(googleDriveAuth === "1"){
                        localStorage.setItem("google_drive_token", accessToken);
                        this.getGoogleEmailId();
                    }
                }
            }

            if(window.location.pathname === "/user/extensions/connect/docusign"){
                this.onDocusignConnectCallback();
            }else if(window.location.pathname === "/user/extensions/connect/adobesign"){
                this.onAdobesignConnectCallback();
            }else{
                let oldState = localStorage.getItem("sendlinx_extension_state");
                if(oldState !== null){
                    oldState = JSON.parse(oldState);
                    if(oldState.hasOwnProperty("docusignModal") && oldState.docusignModal){
                        this.onSignDocument(true);
                    }else if(oldState.hasOwnProperty("adobesignModal") && oldState.adobesignModal){
                        this.onAdobeSignDocument(true);
                    }
                }
            }

            // if(extensionsPageScrollPosition > 0){
            //     this.scrollContainerRef.current.scrollTop = extensionsPageScrollPosition;
            // }
        }
    }
    componentWillUnmount() {
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    saveScrollPosition = () => {
        const scrollContainer = this.scrollContainerRef.current;
        if (scrollContainer) {
            this.scrollPosition = scrollContainer.scrollTop;
        }
    };
    messageModal(status = false, message = ''){
        this.setState({
            messageModal: status,
            messageModalMessage: message
        })

        if(status === false){
            this.props.history.replace({
                showAccessPopup: '',
                type: ''
            })
        }
    }
    loginWith(sharepointAuth = false){
        let clientId = Config.getClientIdOneDrive();
        let redirectUrlOneDrive = Config.getRedirectUrlOneDrive();
        const scope = Config.getOnedriveScope();
        
        if(sharepointAuth){
            clientId = Config.getClientIdSharepoint();
            localStorage.setItem("sharepoint_auth_flow", 1);
            localStorage.removeItem("one_drive_auth_flow");
        }else{
            localStorage.setItem("one_drive_auth_flow", 1);
            localStorage.removeItem("sharepoint_auth_flow");
        }
        localStorage.removeItem("google_drive_auth_flow");

        const URL ='https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id='+clientId+'&scope='+scope+'&response_type=token&redirect_uri='+redirectUrlOneDrive+'&prompt=select_account';
        window.location.replace(URL);
    }
    loginWithGoogle(){
        localStorage.removeItem("one_drive_auth_flow");
        localStorage.removeItem("sharepoint_auth_flow");
        localStorage.setItem("google_drive_auth_flow", 1);

        const clientId = Config.getGoogleDriveClientId();
        const redirectUrl = Config.getGoogleDriveRedirectUrl();
        const scope = Config.getGoogleDriveScope();
        const responseType = 'token';
        const prompt = 'select_account';

        const URL = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUrl}&response_type=${responseType}&scope=${scope}&prompt=${prompt}`;

        window.location.replace(URL);
    }
    getEmailId(sharepoint = false){
        if(sharepoint){
            ApiSharepoint.getEmail().then(data => {
                const email = data.mail;
                localStorage.setItem("sharepoint_email", email);
                localStorage.setItem("sharepoint_connected", true);
                localStorage.removeItem("sharepoint_auth_flow");     
                
                const site = localStorage.getItem("sharepoint_site");
                
                if(!site || site.length <= 0){
                    this.history.push('/user/extensions?showSharepointAccountModal=true');
                }else{
                    this.history.push('/user/extensions');
                }
            }).catch(err => {
                console.log('error',err);
            });
        }else{
            ApiDrive.getOneDriveEmail().then(data => {
                let oldEmail = localStorage.getItem("one_drive_email");
                const email = data.mail;
                localStorage.setItem("one_drive_email", email);
                localStorage.setItem("one_drive_connected", true);
                localStorage.removeItem("one_drive_auth_flow");

                if(oldEmail !== email){
                    this.history.push('/user/extensions?showOneDriveAccountModal=true');
                }else{
                    this.history.push('/user/extensions');
                }
            }).catch(err => {
                console.log('error',err);
            });
        }        
    }
    getGoogleEmailId(){
        ApiGoogleDrive.getGoogleDriveEmail().then(data => {
            let oldEmail = localStorage.getItem("google_drive_email");
            const email = data.email;
            localStorage.setItem("google_drive_email", email);
            localStorage.setItem("google_drive_connected", true);
            localStorage.removeItem("google_drive_auth_flow");     
            
            if(oldEmail !== email){
                this.history.push('/user/extensions?showGoogleDriveAccountModal=true');
            }else{
                this.history.push('/user/extensions');
            }
        }).catch(err => {
            console.log('error',err);
        });
    }
    handleSubmit(e = null) {
        let { extensions } = this.state;
        const { user } = this.store.getState();
        if(e !== null){
            e.preventDefault();
        }else{
            extensions = user.extensions;
        }
        const isValid = this.validateForm();
        if (!isValid) {
            return;
        }

        const source = axios.CancelToken.source();
        this.setState({ cancelToken: source, saving: true });
        const requestData = {
            extensions: extensions,
        }

        Api.updateUserExtensions(requestData, source).then(data => {
            localStorage.setItem("sendlinx_userinfo", JSON.stringify(data.body));
            let newUser = Api.prepareMemberObject(data.body);
            this.store.dispatch({ type: "UPDATE_STATE", state: { 
                user: newUser,
                // extensionsPageScrollPosition: this.scrollPosition
            }});
            this.history.push('/user/extensions');
        }).catch(err => {
            if (err && err.message !== "Request Cancelled") {
                this.setState({ cancelToken: null, saving: false });
            }
        });
    }
    onNewEditAccountModal(status = false, submit = false, type = '') {
        this.setState({
            newEditAccountModal: status,
            editAccountType: type,
        });

        if(status === false){
            if(submit === true){
                this.handleSubmit();
            }else{
                this.props.history.replace({
                    showOneDriveAccountModal: '',
                    showGoogleDriveAccountModal: '',
                })
            }
        }
    }
    onSharepointAccountModal(status = false, submit = false){
        this.setState({
            sharepointAccountModal: status,
        });

        if(status === false){
            this.props.history.replace({
                showSharepointAccountModal: '',
            })

            if(submit === true){
                this.handleSubmit();
            }
        }
    }
    onDeleteAccountModal(status = false, deleteAccount = false, deleteAccountType = ''){
        if(status === false){
            this.setState({
                deleteAccountModal: false,
                deleteAccountType: ''
            })
        }else if(status === true){
            if(deleteAccount === true){
                if(this.state.deleteAccountType === 'oneDrive'){
                    this.onDeleteOneDrive();
                }else if(this.state.deleteAccountType === 'sharepoint'){
                    this.onDeleteSharepoint();
                }else if(this.state.deleteAccountType === 'google_drive'){
                    this.onDeleteGoogleDrive();
                }
            }else{
                this.setState({
                    deleteAccountModal: true,
                    deleteAccountType: deleteAccountType,
                })
            }
        }
    }
    onDeleteOneDrive = () => {
        const oneDriveEmail = localStorage.getItem("one_drive_email");

        localStorage.removeItem("one_drive_token");
        localStorage.removeItem("one_drive_email");
        localStorage.removeItem("one_drive_connected");
        localStorage.removeItem("one_drive_auth_flow");
        this.setState({ 
            deleteingAccount: true 
        });
        const { user } = this.state;
        const requestData = {
            "userId":user?.id
        }
        const source = axios.CancelToken.source();
        Api.updateSyncStatus(requestData, source).then(data => {
            this.setState({
                deleteAccountModal: false,
                deleteAccountType: '',
                deleteingAccount: false
            });

            const sharepointEmail = localStorage.getItem("sharepoint_email");
            if(oneDriveEmail !== sharepointEmail){
                const clientIdOneDrive = Config.getClientIdOneDrive();
                const URL ='https://login.microsoftonline.com/common/oauth2/v2.0/logout?client_id='+clientIdOneDrive+'&post_logout_redirect_uri='+window.origin+'/user/home';
                window.location.href = URL;
            }
        }).catch(err => {
            console.log(err);
        });
    }
    onDeleteSharepoint(){
        const sharepointEmail = localStorage.getItem("sharepoint_email");

        localStorage.removeItem("sharepoint_token");
        localStorage.removeItem("sharepoint_email");
        localStorage.removeItem("sharepoint_connected");
        localStorage.removeItem("sharepoint_auth_flow");
        localStorage.removeItem("sharepoint_site");
        localStorage.removeItem("sharepoint_connection");
        this.setState({ 
            deleteingAccount: true 
        });
        const { user } = this.state;
        const requestData = {
            "userId": user?.id,
            "driveName": "sharepoint"
        }
        const source = axios.CancelToken.source();
        Api.updateSyncStatus(requestData, source).then(data => {
            this.setState({
                deleteAccountModal: false,
                deleteAccountType: '',
                deleteingAccount: false
            });

            const oneDriveEmail = localStorage.getItem("one_drive_email");
            if(oneDriveEmail !== sharepointEmail){
                const clientId = Config.getClientIdOneDrive();
                const URL ='https://login.microsoftonline.com/common/oauth2/v2.0/logout?client_id='+clientId+'&post_logout_redirect_uri='+window.origin+'/user/home';
                window.location.href = URL;
            }
        }).catch(err => {
            console.log(err);
        });
    }
    onDeleteGoogleDrive(){
        localStorage.removeItem("google_drive_token");
        localStorage.removeItem("google_drive_email");
        localStorage.removeItem("google_drive_connected");
        localStorage.removeItem("google_drive_auth_flow");

        this.setState({ 
            deleteingAccount: true 
        });
        const { user } = this.state;
        const requestData = {
            "userId": user?.id,
            "driveName": "google_drive"
        }
        const source = axios.CancelToken.source();
        Api.updateSyncStatus(requestData, source).then(data => {
            this.setState({
                deleteAccountModal: false,
                deleteAccountType: '',
                deleteingAccount: false
            });
        }).catch(err => {
            console.log(err);
        });
    }
    handleCheckbox(e, name) {
        this.setState({
            extensions: {
                ...this.state.extensions,
                [name]: {
                    'status': e.target.checked ? 1 : 0
                }
            }
        }, () => {
            this.handleSubmit(e);
        });
    }
    validateForm() {
        let validation = {
            isValid: true
        };

        return validation.isValid;
    }
    saveButtonDisabled() {
        const { user, extensions } = this.state;

        if (
            (user.extensions.ms_office.status !== extensions.ms_office.status) ||
            (user.extensions.google_workspace.status !== extensions.google_workspace.status) ||
            (user.extensions.docusign.status !== extensions.docusign.status) ||
            (user.extensions.adobesign.status !== extensions.adobesign.status) ||
            (user.extensions.autodesk.status !== extensions.autodesk.status)
        ) {
            return false;
        }

        return false;
    }
    onSignDocument(status = false){
        this.setState({
            docusignModal: status
        });
    }
    onDocusignConnect(type){
        let url = Config.getDocusignSignupUrl();
        if(type === "login"){
            url = Config.getDocusignLoginUrl();
        }
        
        let state = this.state;
        state.cancelToken = null;
        
        localStorage.setItem("sendlinx_extension_state", JSON.stringify(state));
        window.location.href = url;
    }
    onDocusignSuccess(data){
        this.setState({
            docusignModal: false
        });
        window.open(data.prepareUrl, "_blank");
    }
    onDocusignConnectCallback(){
        const source = axios.CancelToken.source();
        this.setState({cancelToken: source, saving: true});

        const requestData = {
            code: helper.getParam("code"),
            state: helper.getParam("state")
        }

        Api.authorizeDocusign(requestData, source).then(data => {
            // localStorage.setItem("sendlinx_userinfo", JSON.stringify(data.body));
            // let newUser = Api.prepareMemberObject(data.body);
            // this.store.dispatch({type: "UPDATE_STATE",state: {user: newUser}});
   
            this.history.push("/user/extensions");
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.setState({cancelToken: null, saving: false});
            }
            this.history.push("/user/extensions");
        });
    }
    onAdobeSignDocument(status = false){
        this.setState({
            adobesignModal: status
        });
    }
    onAdobesignConnect(type){
        let url = Config.getAdobesignSignupUrl();
        if(type === "login"){
            url = Config.getAdobesignLoginUrl();
        }
        
        let state = this.state;
        state.cancelToken = null;
        
        localStorage.setItem("sendlinx_extension_state", JSON.stringify(state));
        window.location.href = url;
    }
    onAdobesignSuccess(data){
        this.setState({
            adobesignModal: false
        });
        window.open(data.prepareUrl, "_blank");
    }
    onAdobesignConnectCallback(){
        const source = axios.CancelToken.source();
        this.setState({cancelToken: source, saving: true});

        const requestData = {
            code: helper.getParam("code"),
        }

        Api.authorizeAdobesign(requestData, source).then(data => {
            // localStorage.setItem("sendlinx_userinfo", JSON.stringify(data.body));
            // let newUser = Api.prepareMemberObject(data.body);
            // this.store.dispatch({type: "UPDATE_STATE",state: {user: newUser}});
   
            this.history.push("/user/extensions");
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.setState({cancelToken: null, saving: false});
            }
            this.history.push("/user/extensions");
        });
    }
    render() {
        const { classes } = this.props;
        const { extensions, newEditAccountModal, editAccountType, sharepointAccountModal, messageModal, messageModalMessage,
            deleteAccountModal, deleteingAccount, docusignModal, adobesignModal } = this.state;

        const { authorized, user } = this.store.getState();
        const isOneDriveConnected = localStorage.getItem('one_drive_connected');
        const isSharepointConnected = localStorage.getItem('sharepoint_connected');
        const isGoogleDriveConnected = localStorage.getItem('google_drive_connected');
        if (!authorized) {
            return (
                <div className={classes.main}>
                    <BackgroundSlider store={this.store} />
                    <Helmet>
                        <title>{process.env.REACT_APP_TITLE}</title>
                    </Helmet>
                </div>
            )
        }
        return (
            <div className={classes.main}>
                <div className={classes.container}>
                    <div className={classes.content} ref={this.scrollContainerRef} onScroll={this.saveScrollPosition}>
                        <GridItem>
                            <div className={classes.extensionsContainer}>
                                <h3 className={classes.title}>Extensions</h3>
                                <form onSubmit={this.handleSubmit} className={classes.formFields}>
                                    <div className={classes.extensionsLists}>
                                        {
                                            helper.isExtensionAllowed(user, 'adobesign') ?
                                                <div className={"extension"}>
                                                    <div className='icon-detail'>
                                                        <img src={helper.getExtensionsData(user, 'adobesign', 'photo_url')} alt="Adobesign" />
                                                        <div>
                                                            <p className='title'>{helper.getExtensionsData(user, 'adobesign', 'extension')}</p>
                                                            <p className='description'>{helper.getExtensionsData(user, 'adobesign', 'description')}</p>
                                                        </div>
                                                    </div>
                                                    {
                                                        extensions.adobesign?.status && localStorage.getItem("adobesign_name") ?
                                                            <div className={classes.tableSection}>
                                                                <TableContainer className='TabContainer'>
                                                                    <Table >
                                                                        <TableHead>
                                                                            <TableRow >
                                                                                <TableCell>Account</TableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                        
                                                                            <TableRow
                                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                            >
                                                                                <TableCell component="th" scope="row" className=''>
                                                                                    {localStorage.getItem("adobesign_name")}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                            
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            </div>
                                                        :
                                                            <></>
                                                    }
                                                    <div className="extension-actions">
                                                        <div className={"buttons"}>
                                                            {
                                                                extensions.adobesign?.status ? 
                                                                    <>
                                                                        <Button size="sm" onClick={() => this.onAdobeSignDocument(true)} color="custom" title="Manage Account">
                                                                            Manage Account
                                                                        </Button>
                                                                    </>
                                                                :
                                                                    <></>
                                                            }
                                                        </div>
                                                        <FormControlLabel
                                                            classes={{ label: classes.label }}
                                                            control={
                                                                <Switch
                                                                    checked={(extensions.adobesign.status ? true : false)}
                                                                    onChange={(e) => this.handleCheckbox(e, 'adobesign')}
                                                                    value="adobesign"
                                                                    classes={{
                                                                        switchBase: classes.switchBase,
                                                                        checked: classes.switchChecked,
                                                                        thumb: classes.switchIcon,
                                                                        track: classes.switchBar
                                                                    }}
                                                                />
                                                            }
                                                            label={""}
                                                        />
                                                    </div>
                                                </div>
                                            :
                                                <></>
                                        } 
                                        {
                                            helper.isExtensionAllowed(user, 'docusign') ?
                                                <div className={"extension"}>
                                                    <div className='icon-detail'>
                                                        <img src={helper.getExtensionsData(user, 'docusign', 'photo_url')} alt="Docusign" />
                                                        <div>
                                                            <p className='title'>{helper.getExtensionsData(user, 'docusign', 'extension')}</p>
                                                            <p className='description'>{helper.getExtensionsData(user, 'docusign', 'description')}</p>
                                                        </div>
                                                    </div>
                                                    {
                                                        extensions.docusign?.status && localStorage.getItem("docusign_name") ?
                                                            <div className={classes.tableSection}>
                                                                <TableContainer className='TabContainer'>
                                                                    <Table >
                                                                        <TableHead>
                                                                            <TableRow >
                                                                                <TableCell>Account</TableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                        
                                                                            <TableRow
                                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                            >
                                                                                <TableCell component="th" scope="row" className=''>
                                                                                    {localStorage.getItem("docusign_name")}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                            
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            </div>
                                                        :
                                                            <></>
                                                    }
                                                    <div className="extension-actions">
                                                        <div className={"buttons"}>
                                                            {
                                                                extensions.docusign?.status ? 
                                                                    <>
                                                                        <Button size="sm" onClick={() => this.onSignDocument(true)} color="custom" title="Manage Account">
                                                                            Manage Account
                                                                        </Button>
                                                                    </>
                                                                :
                                                                    <></>
                                                            }
                                                        </div>
                                                        <FormControlLabel
                                                            classes={{ label: classes.label }}
                                                            control={
                                                                <Switch
                                                                    checked={(extensions.docusign.status ? true : false)}
                                                                    onChange={(e) => this.handleCheckbox(e, 'docusign')}
                                                                    value="docusign"
                                                                    classes={{
                                                                        switchBase: classes.switchBase,
                                                                        checked: classes.switchChecked,
                                                                        thumb: classes.switchIcon,
                                                                        track: classes.switchBar
                                                                    }}
                                                                />
                                                            }
                                                            label={""}
                                                        />
                                                    </div>
                                                </div>
                                            :
                                                <></>
                                        }   
                                        {
                                            helper.isExtensionAllowed(user, 'ms_office') ?
                                                <div className={"extension"}>
                                                    <div className='icon-detail'>
                                                        <img src={helper.getExtensionsData(user, 'ms_office', 'photo_url')} alt="MS Office" />
                                                        <div>
                                                            <p className='title'>{helper.getExtensionsData(user, 'ms_office', 'extension')}</p>
                                                            <p className='description'>{helper.getExtensionsData(user, 'ms_office', 'description')}</p>
                                                        </div>
                                                    </div>
                                                    <FormControlLabel
                                                        classes={{ label: classes.label }}
                                                        control={
                                                            <Switch
                                                                checked={(extensions.ms_office.status ? true : false)}
                                                                onChange={(e) => this.handleCheckbox(e, 'ms_office')}
                                                                value="ms_office"
                                                                classes={{
                                                                    switchBase: classes.switchBase,
                                                                    checked: classes.switchChecked,
                                                                    thumb: classes.switchIcon,
                                                                    track: classes.switchBar
                                                                }}
                                                            />
                                                        }
                                                        label={""}
                                                    />
                                                </div>
                                            :
                                                <></>
                                        }
                                        {
                                            helper.isExtensionAllowed(user, 'google_drive') ?
                                                <div className={"extension"}>
                                                    <div className='icon-detail'>
                                                        <img src={helper.getExtensionsData(user, 'google_drive', 'photo_url')} alt="Google Drive" />
                                                        <div>
                                                            <p className='title'>{helper.getExtensionsData(user, 'google_drive', 'extension')}</p>
                                                            <p className='description'>{helper.getExtensionsData(user, 'google_drive', 'description')}</p>
                                                        </div>
                                                    </div>
                                                    {
                                                        extensions.google_drive?.status && localStorage.getItem("google_drive_email") ?
                                                            <div className={classes.tableSection}>
                                                                <TableContainer className='TabContainer'>
                                                                    <Table >
                                                                        <TableHead>
                                                                            <TableRow >
                                                                                <TableCell>Account</TableCell>
                                                                                <TableCell>Status</TableCell>
                                                                                <TableCell>Permissions</TableCell>
                                                                                <TableCell className='path'>Path</TableCell>
                                                                                <TableCell></TableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                        
                                                                            <TableRow
                                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                            >
                                                                                <TableCell component="th" scope="row" className=''>
                                                                                    {localStorage.getItem("google_drive_email")}
                                                                                </TableCell>
                                                                                <TableCell className=''>Connected</TableCell>
                                                                                <TableCell className=''>Copy To My Docs</TableCell>
                                                                                <TableCell  className='path' title={helper.getExtensionPath(user, 'google_drive')}>
                                                                                    <div>{helper.getExtensionPath(user, 'google_drive')}</div>
                                                                                </TableCell>
                                                                                <TableCell className=''>
                                                                                    <Button justIcon onClick={() => this.onNewEditAccountModal(true, false, 'google_drive')} color="transparent" title="edit">
                                                                                        <EditIcon />
                                                                                    </Button>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                            
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            </div>
                                                        : 
                                                            null 
                                                    }
                                                    <div className="extension-actions">
                                                        <div className={"buttons"}>
                                                            {
                                                                extensions.google_drive?.status ? 
                                                                    <>
                                                                        <Button size="sm" onClick={() => this.loginWithGoogle()} color="custom" disabled={isGoogleDriveConnected ? true : false} title="Add Account">
                                                                            Add Account
                                                                        </Button>
                                                                        <Button  size="sm" onClick={() => this.onDeleteAccountModal(true, false, 'google_drive')} color="custom" disabled={isGoogleDriveConnected ? false : true} ttitle="Delete Account">
                                                                            Delete Account
                                                                        </Button>
                                                                    </>
                                                                :
                                                                    <></>
                                                            }
                                                        </div>
                                                        <FormControlLabel
                                                            classes={{ label: classes.label }}
                                                            control={
                                                                <Switch
                                                                    checked={(extensions.hasOwnProperty("google_drive") && extensions.google_drive.status ? true : false)}
                                                                    onChange={(e) => this.handleCheckbox(e, 'google_drive')}
                                                                    value="google_drive"
                                                                    classes={{
                                                                        switchBase: classes.switchBase,
                                                                        checked: classes.switchChecked,
                                                                        thumb: classes.switchIcon,
                                                                        track: classes.switchBar
                                                                    }}
                                                                />
                                                            }
                                                            label={""}
                                                        />
                                                    </div>
                                                </div>      
                                            :
                                                <></>
                                        }
                                        {
                                            helper.isExtensionAllowed(user, 'one_drive') ?
                                                <div className={"extension"}>
                                                    <div className='icon-detail'>
                                                        <img src={helper.getExtensionsData(user, 'one_drive', 'photo_url')} alt="OneDrive" />
                                                        <div>
                                                            <p className='title'>{helper.getExtensionsData(user, 'one_drive', 'extension')}</p>
                                                            <p className='description'>{helper.getExtensionsData(user, 'one_drive', 'description')}</p>
                                                        </div>
                                                    </div>
                                                    {
                                                        extensions.one_drive?.status && localStorage.getItem("one_drive_email") ?
                                                            <div className={classes.tableSection}>
                                                                <TableContainer className='TabContainer'>
                                                                    <Table >
                                                                        <TableHead>
                                                                            <TableRow >
                                                                                <TableCell>Account</TableCell>
                                                                                <TableCell>Status</TableCell>
                                                                                <TableCell>Permissions</TableCell>
                                                                                <TableCell className='path'>Path</TableCell>
                                                                                <TableCell></TableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                        
                                                                            <TableRow
                                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                            >
                                                                                <TableCell component="th" scope="row" className=''>
                                                                                    {localStorage.getItem("one_drive_email")}
                                                                                </TableCell>
                                                                                <TableCell className=''>Connected</TableCell>
                                                                                <TableCell className=''>Copy To My Docs</TableCell>
                                                                                <TableCell  className='path' title={helper.getExtensionPath(user, 'one_drive')}>
                                                                                    <div>{helper.getExtensionPath(user, 'one_drive')}</div>
                                                                                </TableCell>
                                                                                <TableCell className=''>
                                                                                    <Button justIcon onClick={() => this.onNewEditAccountModal(true, false, 'one_drive')} color="transparent" title="edit">
                                                                                        <EditIcon />
                                                                                    </Button>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                            
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            </div>
                                                        : 
                                                            null 
                                                    }
                                                    <div className="extension-actions">
                                                        <div className={"buttons"}>
                                                            {
                                                                extensions.one_drive?.status ? 
                                                                    <>
                                                                        <Button size="sm" onClick={() => this.loginWith()} color="custom" disabled={isOneDriveConnected ? true : false} title="Add Account">
                                                                            Add Account
                                                                        </Button>
                                                                        <Button  size="sm" onClick={() => this.onDeleteAccountModal(true, false, 'oneDrive')} color="custom" disabled={isOneDriveConnected ? false : true} ttitle="Delete Account">
                                                                            Delete Account
                                                                        </Button>
                                                                    </>
                                                                :
                                                                    <></>
                                                            }
                                                        </div>
                                                        <FormControlLabel
                                                            classes={{ label: classes.label }}
                                                            control={
                                                                <Switch
                                                                    checked={(extensions.hasOwnProperty("one_drive") && extensions.one_drive.status ? true : false)}
                                                                    onChange={(e) => this.handleCheckbox(e, 'one_drive')}
                                                                    value="one_drive"
                                                                    classes={{
                                                                        switchBase: classes.switchBase,
                                                                        checked: classes.switchChecked,
                                                                        thumb: classes.switchIcon,
                                                                        track: classes.switchBar
                                                                    }}
                                                                />
                                                            }
                                                            label={""}
                                                        />
                                                    </div>
                                                </div>      
                                            :
                                                <></>
                                        }
                                        {
                                            helper.isExtensionAllowed(user, 'sharepoint') ?
                                                <div className={"extension"}>
                                                    <div className='icon-detail'>
                                                        <img src={helper.getExtensionsData(user, 'sharepoint', 'photo_url')} alt="Sharepoint" />
                                                        <div>
                                                            <p className='title'>{helper.getExtensionsData(user, 'sharepoint', 'extension')}</p>
                                                            <p className='description'>{helper.getExtensionsData(user, 'sharepoint', 'description')}</p>
                                                        </div>
                                                    </div>
                                                    {
                                                        extensions.sharepoint?.status && localStorage.getItem("sharepoint_email") ?
                                                            <div className={classes.tableSection}>
                                                                <TableContainer className='TabContainer'>
                                                                    <Table >
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                <TableCell>Account</TableCell>
                                                                                <TableCell>Status</TableCell>
                                                                                <TableCell>Permissions</TableCell>
                                                                                <TableCell className='path'>Path</TableCell>
                                                                                <TableCell></TableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            <TableRow
                                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                            >
                                                                                <TableCell component="th" scope="row" className=''>
                                                                                    {localStorage.getItem("sharepoint_email")}
                                                                                </TableCell>
                                                                                <TableCell className=''>Connected</TableCell>
                                                                                <TableCell className=''>Copy To My Docs</TableCell>
                                                                                <TableCell  className='path' title={helper.getExtensionPath(user, 'sharepoint')}>
                                                                                    <div>{helper.getExtensionPath(user, 'sharepoint')}</div>                                                              
                                                                                </TableCell>
                                                                                <TableCell className=''>
                                                                                    <Button justIcon onClick={() => this.onSharepointAccountModal(true)} color="transparent" title="edit">
                                                                                        <EditIcon />
                                                                                    </Button>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>      
                                                            </div>
                                                        : 
                                                            null
                                                    }
                                                    <div className="extension-actions">
                                                        <div className={"buttons"}>
                                                            {
                                                                extensions.sharepoint?.status ? 
                                                                    <>
                                                                        <Button size="sm" onClick={() => this.loginWith(true)} color="custom" disabled={isSharepointConnected ? true :  false} title="Add Account">
                                                                            Add Account
                                                                        </Button>
                                                                        <Button size="sm" onClick={() => this.onDeleteAccountModal(true, false, 'sharepoint')} color="custom" disabled={isSharepointConnected ? false :  true} title="Delete Account">
                                                                            Delete Account
                                                                        </Button>
                                                                    </>
                                                                    
                                                                :
                                                                    <></>
                                                            }
                                                        </div>
                                                        <FormControlLabel
                                                            classes={{ label: classes.label }}
                                                            control={
                                                                <Switch
                                                                    checked={(extensions.hasOwnProperty("sharepoint") && extensions.sharepoint.status ? true : false)}
                                                                    onChange={(e) => this.handleCheckbox(e, 'sharepoint')}
                                                                    value="sharepoint"
                                                                    classes={{
                                                                        switchBase: classes.switchBase,
                                                                        checked: classes.switchChecked,
                                                                        thumb: classes.switchIcon,
                                                                        track: classes.switchBar
                                                                    }}
                                                                />
                                                            }
                                                            label={""}
                                                        />
                                                    </div>
                                                </div>
                                            :
                                                <></>
                                        }
                                        {/* <div className={"extension"}>
                                            <p className='title'>Google Workspace</p>
                                            <FormControlLabel
                                                classes={{ label: classes.label }}
                                                control={
                                                    <Switch
                                                        checked={(extensions.google_workspace.status ? true : false)}
                                                        onChange={(e) => this.handleCheckbox(e, 'google_workspace')}
                                                        value="google_workspace"
                                                        classes={{
                                                            switchBase: classes.switchBase,
                                                            checked: classes.switchChecked,
                                                            thumb: classes.switchIcon,
                                                            track: classes.switchBar
                                                        }}
                                                    />
                                                }
                                                label={""}
                                            />
                                        </div> */}                                
                                        {/* <div className={"extension"}>
                                            <p className='title'>Autodesk</p>
                                            <FormControlLabel
                                                classes={{ label: classes.label }}
                                                control={
                                                    <Switch
                                                        checked={(extensions.autodesk.status ? true : false)}
                                                        onChange={(e) => this.handleCheckbox(e, 'autodesk')}
                                                        value="autodesk"
                                                        classes={{
                                                            switchBase: classes.switchBase,
                                                            checked: classes.switchChecked,
                                                            thumb: classes.switchIcon,
                                                            track: classes.switchBar
                                                        }}
                                                    />
                                                }
                                                label={""}
                                            />
                                        </div> */}
                                    </div>
                                    {/* <div className={classes.formFooter}>
                                        <Button color="custom" type="submit" disabled={this.saveButtonDisabled()}>
                                            {
                                                saving ?
                                                    <LoaderComponent color="white" align="center" saving={true} />
                                                :
                                                "Save"
                                            }
                                        </Button>
                                    </div> */}
                                </form>
                            </div>
                        </GridItem>
                    </div>
                    {
                        messageModal ?
                            <MessageModal 
                                onClose={() => this.messageModal()} 
                                open={messageModal} 
                                store={this.store} 
                                history={this.history}
                                title={messageModalMessage}
                            />
                        :
                            <></>
                    }
                    {
                        deleteAccountModal ?
                            <ConfirmationModal 
                                open={deleteAccountModal} 
                                onClose={() => this.onDeleteAccountModal(false)} 
                                onSuccess={() => this.onDeleteAccountModal(true, true)}
                                confirmationMessage="Are you sure you want to delete account?"
                                cancelButtonText={"Cancel"}
                                successButtonText={"Yes"}
                                saving={deleteingAccount}
                            />
                        :
                            <></>
                    }
                    {
                        newEditAccountModal && (
                            <NewEditAccountModal
                                open={newEditAccountModal}
                                user={this.state.user}
                                onClose={(status, submit) => this.onNewEditAccountModal(false, submit)}
                                onSuccess={(folderName) => this.onNewFolderModalSuccess(folderName)}
                                confirmationMessage="Confirm Default Destination Folder"
                                store={this.store}
                                history={this.history}
                                type={editAccountType}
                            />
                        )
                    }
                    {
                        sharepointAccountModal && (
                            <SharepointAccountModal
                                open={sharepointAccountModal}
                                user={this.state.user}
                                onClose={(status, submit) => this.onSharepointAccountModal(false, submit)}
                                store={this.store}
                                history={this.history}
                            />
                        )
                    }
                    {
                        docusignModal ?
                            <SignModal
                                open={docusignModal}
                                onClose={() => this.onSignDocument(false)}
                                store={this.store}
                                onDocusignConnect={(type) => this.onDocusignConnect(type)}
                                onSignSuccess={(data) => this.onDocusignSuccess(data)}
                                type="extension"
                            />
                        :
                            <></>
                    }
                    {
                        adobesignModal ?
                            <AdobeSignModal
                                open={adobesignModal}
                                onClose={() => this.onAdobeSignDocument(false)}
                                store={this.store}
                                onAdobesignConnect={(type) => this.onAdobesignConnect(type)}
                                onSignSuccess={(data) => this.onAdobesignSuccess(data)}
                                type="extension"
                            />
                        :
                            <></>
                    }
                </div>
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </div>
        )
    }
}

UserExtensions.propTypes = {
    classes: PropTypes.object
};

export default withStyles(userSettingsPageStyle)(UserExtensions);
