import { whiteColor } from "../material-kit-pro-react.jsx";
import modalStyle from "../material-kit-pro-react/modalStyle.jsx";
  
const linkDetailModalStyle = theme => ({
    ...modalStyle(theme),
    textCenter: {
        textAlign: "center"
    },
    modal: {
        ...modalStyle(theme).modal,
        maxWidth: "550px !important",
        padding: "25px 28px 30px",
    },
    modalHeader: {
        padding: "0px",
        position: "relative",
    },
    modalTitle: {
        ...modalStyle(theme).modalTitle,
        textAlign: "left",
        fontWeight: "600",
    },
    modalBody: {
        ...modalStyle(theme).modalBody,
        paddingLeft: "0px",
        paddingRight: "0px",
        paddingTop: "50px",
        paddingBottom: "10px",
        "& .sd_loader": {
            width: "300px !important",
            height: "300px !important",
            [theme.breakpoints.down("xs")]: {
                width: "180px !important",
                height: "180px !important",
            },
            "& > div": {
                width: "250px !important",
                height: "250px !important",
                [theme.breakpoints.down("xs")]: {
                    width: "150px !important",
                    height: "150px !important",
                },
            },
            "& svg": {
                "& circle": {
                    strokeWidth: "1px"
                }
            }
        },
        "& ul": {
            listStyleType: "none",
            paddingLeft: "0px",
            "& li": {
                fontSize: "14px",
                marginBottom: "5px",
                paddingBottom: "5px",
                display: "flex",
                color: theme.customize.whiteBlackColor,
                justifyContent: "space-between",
                flexWrap: "wrap",
                "&>span": {
                    fontWeight: "600",
                    color: theme.customize.whiteBlackColor,
                },
                "& button": {
                    width: "auto",
                    height: "auto",
                    margin: "0px",
                    marginLeft: "5px",
                    padding: "0px",
                    minWidth: "0px",
                    "& svg": {
                        color: theme.customize.whiteBlackColor,
                        fill: theme.customize.whiteBlackColor,
                        margin: "0px",
                    },
                    "&.text-button": {
                        color: whiteColor,
                        textTransform: "capitalize",
                        padding: "5px",
                        fontSize: "12px",
                        "&.icon": {
                            color: theme.customize.color,
                            padding: "0px",
                            opacity: "0.7",
                            "& svg": {
                                width: "12px",
                                height: "12px",
                                marginLeft: "5px",
                                marginTop: "-3px",
                            }
                        }
                    }
                },
                "&.password, &.expire": {
                    "& button": {
                        "& svg": {
                            width: "12px",
                            height: "12px",
                        },
                        "&.eye-icon": {
                            "& svg": {
                                width: "14px",
                                height: "14px",
                            }
                        }
                    }
                },
                "&.list": {
                    flexDirection: "column",
                    rowGap: "5px",
                    "& div": {
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                        gap: "10px",
                        "& >span": {
                            backgroundColor: theme.customize.gridBgColor,
                            padding: "5px 10px",
                            borderRadius: "5px",
                            "& button": {
                                marginRight: "5px",
                                marginLeft: "0px",
                            },
                            "&.others": {
                                cursor: "pointer",
                                fontWeight: "bold"
                            }
                        }
                    }
                },
                "& .sd-password-field": {
                    display: "flex",
                    alignItems: "center",
                    "& .sd-passwords": {
                        display: "flex",
                        "& span:before": {
                            content: '""',
                            width: "3px",
                            height: "3px",
                            display: "block",
                            borderRadius: "100%",
                            backgroundColor: theme.customize.color,
                            marginRight: "2px"
                        }
                    }
                }
            }
        }
    },
    modalFooter: {
        ...modalStyle(theme).modalFooter,
        justifyContent: "space-between !important",
        MsFlexPack: "space-between !important",
        WebkitBoxPack: "space-between !important",
        flexWrap: "wrap",
        padding: "0px",
        borderTop: "1px solid #D8D9E0",
        paddingTop: "17px",
        "& >div": {
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
        }
    },
    modalCloseButtonCustom: {
        position: "absolute",
        top: "0px",
        right: "0px",
        margin: "0px",
        padding: "0px",
        "& svg": {
            marginRight: "0px"
        }
    },
});
  
export default linkDetailModalStyle;
  