import React from "react";
import axios from "axios";
import Api from "../../../assets/js/utils/Api";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
import LoaderComponent from '../Loader';
import GridItem from "../Grid/GridItem.jsx";
import Link from "@material-ui/core/Link";
import CustomFileInput from "../CustomFileInput/CustomFileInput";
import AttachFile from "@material-ui/icons/AttachFile";
import { helper } from '../../../assets/js/utils/Element';
import classNames from "classnames";
import DialogActions from "@material-ui/core/DialogActions";
import addUserModelStyle from "../../../assets/jss/user/addUserModelStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const AddMultiUserModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        
        this.state = {
            saving: false,
            response: null,
            success: false,
            cancelToken: null,
            showError: false,
            file: null,
            usersArray: [],
            errorMessage: "",
            responseData: {},
            validation: {
                file: "",
                isValid: false
            }
        };

        this.updateUserDetails = this.updateUserDetails.bind(this);
        this.storageSizes = ['1 GB', '2 GB', '3 GB', '4 GB', '5 GB', '10 GB', '15 GB', '20 GB'];
    }
    updateUserDetails(){
        const isValid = this.validateForm();
        if(!isValid || this.state.loading){
            return;
        }
        
        const source = axios.CancelToken.source();
        this.setState({
            saving: true,
            cancelToken: source,
            showError: false,
        });
        const requestData = {
            users: this.state.usersArray
        };


        const that = this;
        Api.addMultiUser(requestData, source).then(data => {
            that.setState({
                success: true,
                saving: false,
                responseData: data.response
            });
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message") && err.message !== 'Request Cancelled'){
                this.setState({
                    saving: false,
                    showError: true,
                    errorMessage: err.message
                });
            }
        });
    }
    renderErrorMessages(){
        const { errorMessage } = this.state;
        if(typeof(errorMessage) === "object"){
            let errorMessages = [];
            let key = 0;
            for(const attrib in errorMessage){
                const message = errorMessage[attrib];
                errorMessages.push(<GridItem key={key} className={"passwordCheck-notValid-customizable"}>
                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                    <span className="checkPasswordText-lowerletter">{message}</span>
                </GridItem>);
                key++;
            }
            return errorMessages;
        }
        return <GridItem className={"passwordCheck-notValid-customizable"}>
            <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
            <span className="checkPasswordText-lowerletter">{errorMessage}</span>
        </GridItem>;
    }
    handleChange(e, name){
        let state = {};
        state[name] = e.target.value;
        this.setState(state);
    }
    handleFileChange(e, name){
        let state = {};
        state[name] = e.target.files[0];
        this.setState(state);

        let reader = new FileReader();
        let file = e.target.files[0];

        const that = this;
        reader.onload = function(e) {
            that.csvToArray(reader.result);
        }
        reader.readAsText(file);
    }
    csvToArray(result){
        let lines = result.split("\n");
        lines.splice(lines.length - 1, 1);

        let resultArray = [];
        
        let headers = lines[0].split(",");

        for(let i=1; i<lines.length; i++){

            let obj = {};
            let currentline = lines[i].split(",");
            
            for(let j=0; j<headers.length; j++){
                let headerTrim = headers[j].trim();
                let currentLineTrim = "";
                if(currentline[j]){
                    currentLineTrim = currentline[j].trim();
                }
                if(headerTrim === 'quota'){
                    currentLineTrim = helper.getBytesSize(currentLineTrim);
                }
                
                obj[headerTrim] = currentLineTrim;
            }

            resultArray.push(obj);
        }
        
        this.setState({
            usersArray: resultArray
        })
    }
    validateForm(){
        let validation = {  
            file: "success",  
            isValid: true
        };
        const { file } = this.state;
        if(file === null || (typeof(file) === "object" && file.size <= 0)){
            validation.file = "error";
            validation.isValid = false;
        }
        this.setState({validation: validation});
        return validation.isValid;
    }
    downloadCsv(fileName){
        Api.downloadCsv(fileName);
    }
    renderForm(){
        const { validation, file } = this.state;
        const { classes } = this.props;
        
        return(
            <div className={classes.addUserContainer}>
                <div className={classes.addMultiUserForm}>
                    <div>
                        <div className="multi-user-form-content">
                            <p>Download and save one of the file below. Open the file in Excel or a similar app and add your user info. Save the file as .CSV using commas as delimiters between the 16 columns.</p>
                            <Link href={""} target="_blank">
                                Learn more about importing multiple users
                            </Link>
                            <div className="sample-files">
                                <Button color="custom" simple onClick={() => this.downloadCsv('blank')}>
                                    Download a blank CSV file with the required headers
                                </Button>
                                <Button color="custom" simple onClick={() => this.downloadCsv('sample')}>
                                    Download a CSV file that includes example user info
                                </Button>
                            </div>
                            <h6>Avoid common errors</h6>
                            <ul>
                                <li>You can upload up to 249 users per CSV file.</li>
                                <li>Each user must have a unique email address.</li>
                                <li>Email addresses may only use letters, numbers, and the following special characters: <p>!#$%&'*+-/=?^_`~.</p></li>
                                <li>Email addresses can't begin or end with a period (.).</li>
                                <li>The part of the email address before the @ symbol can have 64 character or less.</li>
                                <li>Save as a CSV (comma delimited) file with 16 columns.</li>
                            </ul>
                            <CustomFileInput
                                success={validation.file === "success"}
                                error={validation.file === "error"}
                                id="input-file"                            
                                onChange={(e) => this.handleFileChange(e,'file')}
                                endButton={{
                                    buttonProps: {
                                        round: true,
                                        color: "custom",
                                        justIcon: true,
                                        fileButton: true
                                    },
                                    icon: <AttachFile />
                                }}
                                inputProps={{
                                    value: file,
                                    placeholder: "Upload CSV file with your user information"
                                }}
                                color="custom"
                                formControlProps={{
                                    fullWidth: true,
                                    className: "custom-input"
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    render() {
        const { saving, success, showError, responseData } = this.state;
        const { classes } = this.props;

        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="userDetails-slide-title"
                aria-describedby="userDetails-slide-description"
            >
                <DialogTitle
                    id="userDetails-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                    <Button
                        simple
                        className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.props.onClose()}
                    >
                        <Close className={classes.modalClose} />
                    </Button>
                    <h4 className={classes.modalTitle}>
                        Upload a CSV file with user info
                    </h4>
                </DialogTitle>
                <DialogContent
                    id="userDetails-slide-description"
                    className={classes.modalBody}
                    >
                        {
                            showError ?
                                this.renderErrorMessages()
                            :
                                <></>
                        }
                        {
                            success ?
                                <>
                                    <p>
                                        <Check
                                            className={classNames(
                                                classes.cardIcons,
                                                classes.successColor
                                            )}
                                        />{" "}
                                        <b>Users added:</b> {responseData.successfully}
                                    </p>
                                    <p>
                                        <Close
                                            className={classNames(
                                                classes.cardIcons,
                                                classes.dangerColor
                                            )}
                                        />{" "}
                                        <b>Users not added:</b> {responseData.unsuccessfully}
                                    </p>
                                    <p>
                                        <Check
                                            className={classNames(
                                                classes.cardIcons,
                                                classes.successColor
                                            )}
                                        />{" "}
                                        <b>Invitation Sent:</b> {responseData.invitationSent}
                                    </p>
                                </>
                            :
                                this.renderForm()
                        }
                </DialogContent>      
                <DialogActions className={classes.modalFooter}>
                    {
                        success ?
                            <Button color="custom" onClick={() => this.props.onClose()}>
                                Close
                            </Button>
                        :
                            <Button color="custom" onClick={this.updateUserDetails} type="button" >
                                {
                                    saving ?
                                        <LoaderComponent color="white" align="left" saving={true} />
                                    :
                                        "Submit"
                                }
                            </Button>
                    }
                </DialogActions>
            </Dialog>
        );
    }
};

AddMultiUserModal.defaultProps = {
    open: false,
};
AddMultiUserModal.propTypes = {
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    open: PropTypes.bool,
};
export default withStyles(addUserModelStyle)(AddMultiUserModal);
