import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import archivesInfoModalStyle from "../../../assets/jss/user/archivesInfoModalStyle";
import Close from "@material-ui/icons/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const ArchivesInfoModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
    }
    
    getInfoText(type){
        let infoTypeText = "";
        switch(type){
            case 'link':
                infoTypeText = "These are all the ‘Zip’ files that gets generated when clicking the “Download” button from the download page accessed by a link. This occurs when a user selects a folder or more than one file at once to download and it downloads it as a zip.";
                break;
            case 'direct':
                infoTypeText = "These are all the ‘Zip’ files that gets generated when downloading directly from within My Docs. This occurs when a user selects a folder or more than one file at once to download and it downloads as a zip.";
                break;
            case 'office':
                infoTypeText = "These are all the files that are backed up right before being edited using Microsoft Office Online.";
                break;
            case 'shared':
                infoTypeText = "These are all the files that are backed up right when duplicate files have been uploaded with appending of v01 or date time. This by default only is kept in the system for 30 days.";
                break;
            case 'keep':
                infoTypeText = "These are all the files that gets selected by the user to keep previous versions that is more than 30 days.";
                break;
            case 'extension-info': 
                infoTypeText = "By default, files transferred from external third-party cloud storage services will be placed in this folder. You will also have the option to temporarily change the destination during the process, if necessary.";
                break;
            default:
                break;
        }

        return infoTypeText;
    }
    getModalTitle(type){
        let title = "";
        switch(type){
            case 'link':
                title = "Link Downloads Info";
                break;
            case 'direct':
                title = "Direct Downloads Info";
                break;
            case 'office':
                title = "Previous Versions: Office Documents Info";
                break;
            case 'shared':
                title = "Shared Documents Info";
                break;
            case 'keep':
                title = "Keep Forever Info";
                break;
            case 'extension-info': 
                title = "Default Destination";
                break;
            default:
                break;
        }

        return title;
    }
    render() {
        const { classes, type } = this.props;
        return (
        <Dialog
            classes={{
                root: classes.modalRoot,
                paper: classes.modal+" test"
            }}
            open={this.props.open}
            TransitionComponent={Transition}
            keepMounted
            aria-labelledby="deleteFile-slide-title"
            aria-describedby="deleteFile-slide-description"
            onClose={() => this.props.onClose()}
        >
            <DialogTitle
                id="attachments-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
            >
                <Button
                    simple
                    className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                    key="close"
                    aria-label="Close"
                    onClick={() => this.props.onClose()}
                >
                    <Close className={classes.modalClose} />
                </Button>
                <h4 className={classes.modalTitle}>{this.getModalTitle(type)}</h4>
            </DialogTitle>
            <DialogContent
                id="deleteFile-slide-description"
                className={classes.modalBody}
            >
                <p>{this.getInfoText(type)}</p>
            </DialogContent>
            <DialogActions
                className={classes.modalFooter}
            >
                <Button onClick={() => this.props.onClose()} color="primary">Close</Button>
            </DialogActions>        
        </Dialog>
        );
    }
};

ArchivesInfoModal.defaultProps = {
  open: false,
};
ArchivesInfoModal.propTypes = {
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  open: PropTypes.bool,
};
export default withStyles(archivesInfoModalStyle)(ArchivesInfoModal);
