import React from "react";
import PropTypes from "prop-types";
// @material-ui/icons
import PersonIcon from "@material-ui/icons/Person";
import CloseIcon from "@material-ui/icons/Close";
import Button from "../../CustomButtons/Button.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CustomInput from "../../CustomInput/CustomInput.jsx";
import Config from "../../../../../Config";

// core components
import GridContainer from "../../Grid/GridContainer.jsx";
import GridItem from "../../Grid/GridItem.jsx";

const style = {
    files: {
        listStyleType: "none",
        padding: "0px",
        marginBottom: "10px",
        "& li": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "5px 10px",
            "& svg": {
                cursor: "pointer"
            },
            "&+li": {
                marginTop: "10px"
            }
        }
    },
    recipientDetail: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        textAlign: "left",
        "& svg": {
            marginRight: "10px"
        }
    },
    infoText: {
        fontWeight: "300",
        margin: "10px 0 15px",
        textAlign: "center",
        fontSize: "14px"
    },
    addRecipient: {
        textAlign: "left",
    },
    cancelButton: {
        marginLeft: "10px"
    }
};

class Recipients extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        const { user } = this.store.getState();
        const userinfo = this.props.userinfo;
        let email = user.email;
        if(userinfo !== null && typeof(userinfo) === "object" && userinfo.hasOwnProperty("sub")){
            email = userinfo.email;
        }
        this.state = {
            recipients: [
                {
                    email: email,
                    name: "Me"
                },
            ],
            newRecipient: false,
            recipientName: "",
            recipientEmail: "",
            validation: {
                recipientName: "",
                recipientEmail: "",
            }
        };
    }
    sendState() {
        return this.state;
    }
    onRecipientRemove(recipient){
        const { recipients } = this.state;
        let newRecipients = [];
        recipients.map(newRecipient => {
            if(newRecipient.email !== recipient.email){
                newRecipients.push(newRecipient);
            }
            return null;
        });
        this.setState({
            recipients: newRecipients
        });
    }
    onAddRecipient(e){
        e.preventDefault();
        this.setState({
            newRecipient: true
        });
    }
    onCancelRecipient(){
        this.setState({
            newRecipient: false
        });
    }
    handleChange(e, name){
        let state = {};
        state[name] = e.target.value;
        this.setState(state);
    }
    validateForm(){
        let validation = {
            recipientName: "success",
            recipientEmail: "success",
            isValid: true
        };
        const { recipientName, recipientEmail } = this.state;
        if(recipientName.length <= 0){
            validation.recipientName = "error";
            validation.isValid = false;
        }
        var emailRegex = Config.getEmailRegex();
        if(recipientEmail.length <= 0 || !emailRegex.test(recipientEmail)){
            validation.recipientEmail = "error";
            validation.isValid = false;
        }
        return validation.isValid;
    }
    onAddRecipientSubmit(){
        const isValid = this.validateForm();
        if(!isValid){
            return;
        }
        const { recipients, recipientName, recipientEmail } = this.state;
        recipients.push({
            email: recipientEmail,
            name: recipientName
        });
        this.setState({
            recipients: recipients,
            recipientName: "",
            recipientEmail: "",
            newRecipient: false
        });
    }
    isValidated(){
        return this.state.recipients.length > 0;
    }
    render() {
        const { classes } = this.props;
        const { recipients, newRecipient, validation, recipientName, recipientEmail } = this.state;
        return (
            <GridContainer justifyContent="center">
                <GridItem xs={12} sm={12}>
                    <h4 className={classes.infoText}>We've added the DocuSign account owner automatically.</h4>
                    <ul className={classes.files}>
                        {
                            recipients.map((recipient, key) => {
                                return (
                                    <li key={key}>
                                        <div className={classes.recipientDetail}>
                                            <PersonIcon />
                                            <div>
                                                <div>{recipient.name}</div>
                                                <div>{recipient.email}</div>
                                            </div>
                                        </div>
                                        <div>
                                            <CloseIcon onClick={() => this.onRecipientRemove(recipient)} />
                                        </div>
                                    </li>
                                )
                            })
                        }
                    </ul>
                    {
                        newRecipient ?
                            <div>
                                <CustomInput
                                    success={validation.recipientName === "success"}
                                    error={validation.recipientName === "error"}
                                    id="input-recipientName"
                                    labelText="Name"
                                    inputProps={{
                                        required: true,
                                        onChange: (e) => this.handleChange(e,'recipientName'),
                                        name: "recipientName",
                                        value: recipientName,
                                    }}                                    
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                />
                                <CustomInput
                                    success={validation.recipientEmail === "success"}
                                    error={validation.recipientEmail === "error"}
                                    id="input-recipientEmail"
                                    labelText="Email"
                                    inputProps={{
                                        required: true,
                                        onChange: (e) => this.handleChange(e,'recipientEmail'),
                                        name: "recipientEmail",
                                        value: recipientEmail,
                                    }}                                    
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                />
                            </div>
                        :
                        null
                    }
                    <div className={classes.addRecipient}>
                        {
                            newRecipient ?
                                <>
                                    <Button color="info" onClick={() => this.onAddRecipientSubmit()}>Add</Button>
                                    <Button
                                        color="white"
                                        className={classes.cancelButton}
                                        onClick={() => this.onCancelRecipient()}
                                    >
                                        Cancel
                                    </Button>
                                </>
                            :
                                <a href="/" onClick={(e) => this.onAddRecipient(e)}>Add Recipient</a>
                        }
                        
                    </div>
                </GridItem>
            </GridContainer>
        );
    }
}

Recipients.propTypes = {
  classes: PropTypes.object
};

export default withStyles(style)(Recipients);
