import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CustomInput from "../CustomInput/CustomInput";
import Close from "@material-ui/icons/Close";
import LoaderComponent from "../Loader";

import newFileModalStyle from "../../../assets/jss/user/newFolderModalStyle.jsx";
import Danger from '../Typography/Danger.jsx';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const NewFileModal = class extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            fileName: this.props.fileName
        };

        this.handleDateChange = this.handleDateChange.bind(this);
    }
    handleDateChange(e, name) {
        this.setState({
            [name]: e.target.value
        });
    }
    render() {
        const { classes, saving, confirmationMessage, extension, error, errorMessage } = this.props;
        const { fileName } = this.state;
        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="newFileModal-modal-slide-title"
                aria-describedby="newFileModal-modal-slide-description"
            >
                <DialogTitle
                    id="newFileModal-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                        <Button
                            simple
                            className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                            key="close"
                            aria-label="Close"
                            onClick={() => this.props.onClose()}
                        >
                            {" "}
                            <Close className={classes.modalClose} />
                        </Button>
                        {
                            saving ?
                                <></>
                            :
                            <h4 className={classes.modalTitle}>{confirmationMessage}</h4> 
                        }
                </DialogTitle>
                <DialogContent
                    id="newFileModal-modal-slide-description"
                    className={classes.modalBody}
                    >
                        {
                            saving ?
                                <LoaderComponent color="custom" align="center" />
                            :
                            <>
                                <CustomInput
                                    labelText="File Name"
                                    id="input-fileName"
                                    success={fileName.length > 0}
                                    value={fileName}
                                    inputProps={{
                                        name: "fileName",
                                        type: "text",
                                        onChange: (e) => this.handleDateChange(e, 'fileName')
                                    }}
                                    formControlProps={{
                                        fullWidth: true,
                                        className: "custom-input m-zero"
                                    }}
                                />
                                <span className={classes.extension}>.{extension}</span>
                            </>
                        }
                </DialogContent>
                {
                    error ?
                        <Danger>{errorMessage}</Danger>
                    :
                    null
                }
                {
                    saving ?
                        <></>
                    :
                    <DialogActions className={classes.modalFooter}>
                        <Button color="custom" className={classes.changeButton} disabled={(fileName.length === 0)} onClick={() => this.props.onSuccess(fileName)}>
                            Create
                        </Button>
                    </DialogActions>
                }
            </Dialog>
        )
    }
}

NewFileModal.defaultProps = {
    open: false,
    confirmationMessage: "",
    saving: false,
    fileName: "",
    extension: "",
    error: false,
    errorMessage: null
}
NewFileModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    confirmationMessage: PropTypes.string,
    saving: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string
}
export default withStyles(newFileModalStyle)(NewFileModal);