import React from 'react';
import { Helmet } from 'react-helmet';
import Auth from "../../../assets/js/utils/Auth.js";
import { helper } from "../../../assets/js/utils/Element.js";
import PropTypes from "prop-types";
import BackgroundSlider from "../../components/Slider/BackgroundSlider.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";

import withStyles from "@material-ui/core/styles/withStyles";
import userHomePageStyle from "../../../assets/jss/user/userHomePageStyle.jsx";

const UserTrash = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        this.history = this.props.history;
        let path = helper.getParam("dir");
        if(path === null || path === "null"){
            path = "/";
        }

        this.state = {
            limit: 200,
            totalItemCount: 0,
            response: null,
            cancelToken: null,
            page: 1,
            loading: false,
            loadingMore: false,
            totalPages: 1,
            checked: [],
            path: decodeURIComponent(path.trim()),
            deleteFileModal: false,
            restoreFileModal: false,
            trash: [],
            shareFileModal: false,
            sharingFiles: false,
            downloadModal: false,
            downloadingFiles: false,
            downloadUrl: "",
            checkedAll: false,
            saving: false,
            errorMessage: null,
            activeTab: 0
        };

        this.loadingFolder = false;
    }
    componentDidMount(){
        const { authorized } = this.store.getState();
        if(!authorized && !Auth.hasAccessToken()){
            const location = this.history.location;
            const loginRequired = "/auth/login?return="+encodeURIComponent(location.pathname+location.search);
            this.history.push(loginRequired);
            return;
        }
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    onTabChange(urlTab){
        if(urlTab === '0'){
            this.setState({activeTab:0})
            this.history.push("/user/trash");
        }else if(urlTab === '1'){
            this.setState({activeTab:1})
            this.history.push("/user/trash/files");
        }else if(urlTab === '2'){
            this.setState({activeTab:2})
            this.history.push("/user/trash/links");
        }
    }
    render() {
        const { classes } = this.props;
        const { activeTab } = this.state;
        const { authorized } = this.store.getState();
        if(!authorized){
            return (
                <div className={classes.main}>
                    <BackgroundSlider store={this.store} />
                    <Helmet>
                        <title>{process.env.REACT_APP_TITLE}</title>
                    </Helmet>
                </div>
            )
        }
        return (
            <div className={classes.main}>
                <div className={classes.container}>
                    <div className={classes.content}>
                        <div className={classes.controls}>
                            <GridItem className={classes.controlsTabs}>
                                <div className='sd-custom-tabs'>
                                    <div className={'sd-custom-tab '+(activeTab === 0 ? 'active': '')} onClick={() => this.onTabChange("0")}>
                                        <span>Trash</span>
                                    </div>
                                    <div className={'sd-custom-tab '+(activeTab === 1 ? 'active': '')} onClick={() => this.onTabChange("1")}>
                                        <span>Files</span>
                                    </div>
                                    <div className={'sd-custom-tab '+(activeTab === 2 ? 'active': '')} onClick={() => this.onTabChange("2")}>
                                        <span>Links</span>
                                    </div>
                                </div>
                            </GridItem>
                            <div className={classes.controlButtons}></div>
                        </div>
                        <GridItem>
                            <div className={classes.textContainer}>
                                <h5>Trash: Deleted Files and Links Overview</h5>
                                <p>The <span>Trash</span> section provides users with a convenient location to view and restore files and links that have been deleted. This includes items from both <span>Email Transfers</span> and <span>My Docs</span>, as well as <span>deleted links</span> from the <span>Link Manager</span>. Users can easily recover any mistakenly deleted content, offering peace of mind knowing that files and links aren't permanently lost immediately after deletion.</p>
                                <p>Key Features:</p>
                                <ul>
                                    <li><p><span>Centralized Deleted Items:</span> View all deleted files from <span>Email Transfers</span> and <span>My Docs</span> as well as deleted links from the <span>Link Manager</span>, organized in one place.</p></li>
                                    <li><p><span>Restoration Options:</span> Items in the Trash can be restored back to their original locations, whether it's a document, email attachment, or link. Users can select and restore any deleted file or link with a simple click.</p></li>
                                    <li><p><span>Clear All:</span> Users have the option to permanently delete all items in the Trash, helping manage storage and keep things organized.</p></li>
                                    <li><p><span>Reminder to Empty Trash:</span> To ensure your account remains organized and free of unnecessary clutter, users will receive reminders to clear the Trash from time to time. Regular housekeeping of the Trash bin helps optimize storage space and ensures that your account stays efficient.</p></li>
                                </ul>
                                <p>By maintaining a clean Trash bin, users can not only keep their account tidy but also free up valuable storage space for new content.</p>
                            </div>
                        </GridItem>
                    </div>
                </div>
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </div>
        )
    }
}

UserTrash.propTypes = {
    classes: PropTypes.object
};

export default withStyles(userHomePageStyle)(UserTrash);
