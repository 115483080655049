import { 
    dangerColor, 
    successColor 
} from "../material-kit-pro-react.jsx";
import modalStyle from "../material-kit-pro-react/modalStyle.jsx";

const addRuleModelStyle = theme => ({
    ...modalStyle(theme),
    modal: {
        ...modalStyle(theme).modal,
        maxWidth: "550px !important",
        padding: "25px 28px 30px",
    },
    modalHeader: {
        padding: "0px",
        position: "relative",
    },
    modalTitle: {
        ...modalStyle(theme).modalTitle,
        textAlign: "left",
        fontWeight: "600",
    },
    modalClose: {
        width: "16px",
        height: "16px"
    },
    modalBody: {
        ...modalStyle(theme).modalBody,
        paddingLeft: "0px",
        paddingRight: "0px",
        paddingTop: "65px",
        paddingBottom: "50px",
        "& .sd_loader": {
            width: "300px !important",
            height: "300px !important",
            [theme.breakpoints.down("xs")]: {
                width: "180px !important",
                height: "180px !important",
            },
            "& > div": {
                width: "250px !important",
                height: "250px !important",
                [theme.breakpoints.down("xs")]: {
                    width: "150px !important",
                    height: "150px !important",
                },
            },
            "& svg": {
                "& circle": {
                    strokeWidth: "1px"
                }
            }
        },
    },
    modalFooter: {
        ...modalStyle(theme).modalFooter,
        justifyContent: "end !important",
        MsFlexPack: "end !important",
        WebkitBoxPack: "end !important",
        padding: "0px",
    },
    modalCloseButtonCustom: {
        position: "absolute",
        top: "0px",
        right: "0px",
        margin: "0px",
        padding: "0px",
        "& svg": {
            marginRight: "0px"
        }
    },
    transferFooter: {
        borderTop: "0px",
        "& svg": {
            transform: "none !important",
            marginTop: "0px !important"
        }
    },
    datePicker: {
        marginBottom: "17px"
    },
    selectFormControl: {
        margin: "0px !Important"
    },
    customSelectFormControl: {
        textAlign: "left",
        marginBottom: "17px !important"
    },
    customFormControlClasses: {
        textAlign: "left",
        marginBottom: "10px",
        paddingTop: "15px",
        "& label + .MuiInput-formControl": {
            marginTop: "0px"
        }
    },
    fileField: {
        "& >.MuiSvgIcon-root": {
            right: "45px",
            bottom: "11px"
        }
    },
    cardIcons: {
        top: "6px",
        position: "relative"
    },
    dangerColor: {
        color: dangerColor[0]
    },
    successColor: {
        color: successColor[0]
    },
    addUserContainer: {
        marginTop: 0,
        "& .date-fields": {
            display: 'flex',
            justifyContent: "space-between",

        }
    },
    alignLeft: {
        textAlign: "left"
    }
});

export default addRuleModelStyle;
