/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Button from "../CustomButtons/Button.jsx";
import DuplicateFileModal from  "../../components/User/DuplicateFileModal";

import styles from "../../../assets/jss/material-kit-pro-react/components/customFileUploadStyle.jsx";
import Config from "../../../../Config.js";

class CustomFileUpload extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        
        this.state = {
            files: this.props.files,
            showPopup: false,
            duplicateFileModal: false,
        };
        this.hiddenFile = React.createRef();
        this.hiddenFileDirectory = React.createRef();

        this.addFile = this.addFile.bind(this);
        this.addFolder = this.addFolder.bind(this);
        this.popupToggle = this.popupToggle.bind(this);
    }
    componentWillReceiveProps(props){
        this.setState({
            files: props.files
        });
    }
    popupToggle(){
        this.setState({
            showPopup: !this.state.showPopup
        });
    }
    onFocus = e => {
        this.hiddenFile.current.click(e);
    };
    onDirectoryFocus = e => {
        this.hiddenFileDirectory.current.click(e);
    };
    handleSubmit = e => {
        e.preventDefault();
        // this.state.file is the file/image uploaded
        // in this function you can save the image (this.state.file) on form submit
        // you have to call it yourself
    };
    hasFile(file, filesArray){
        let fileName = file.name;
        let hasFile = false;
        if(filesArray.length > 0){
            filesArray.map((item, key) => {
                if(item.type === 'file' && item.file.name === fileName){
                    hasFile = true;
                }
                return null;
            })
        }

        return hasFile;
    }
    hasFolder(folderName, filesArray){
        let hasFolder = false;
        if(filesArray.length > 0){
            filesArray.map((item, key) => {
                if(item.type === 'folder' && item.name === folderName){
                    hasFolder = true;
                }
                return null;
            })
        }

        return hasFolder;
    }
    addFile = e => {
        let files = e.target.files;
        let filesArray = this.state.files;
        let hasFile = false;
        let duplicateFileModal = false;
        const disallowedExtensions = Config.getDisallowedExtensions();
        for (let i = 0; i < files.length; i++) {
            if(disallowedExtensions && disallowedExtensions.length > 0){
                const extension = files[i].name.split('.').pop();
                if(disallowedExtensions.includes(extension)){
                    continue;
                }
            }
            
            hasFile = this.hasFile(files[i], filesArray);
            if(hasFile === true){
                duplicateFileModal = true;
                continue;
            }
            filesArray.push({
                type: "file",
                file: files[i]
            });
        }

        this.props.onChange(filesArray);
        this.setState({
            files: filesArray,
            duplicateFileModal: duplicateFileModal
        });
    };
    addFolder = e => {
        let files = e.target.files;
        if(files.length <= 0){
            return;
        }
        let newFilesArray = [];
        const disallowedExtensions = Config.getDisallowedExtensions();
        for (let i = 0; i < files.length; i++) {
            if(disallowedExtensions && disallowedExtensions.length > 0){
                const extension = files[i].name.split('.').pop();
                if(disallowedExtensions.includes(extension)){
                    continue;
                }
            }
            newFilesArray.push(files[i]);
        }
        let filesArray = this.state.files;
        var folderPath = files[0].webkitRelativePath;
        var folder = folderPath.split("/")[0];
        let hasFolder = this.hasFolder(folder, filesArray);
        if(hasFolder === false){
            filesArray.push({
                type: "folder",
                name: folder,
                files: newFilesArray
            });
        }
        this.props.onChange(filesArray);
        this.setState({
            files: filesArray,
            duplicateFileModal: hasFolder
        });
    }
    filesList(files){
        let filesLists = [];
        for (var i = 0; i < files.length; i++) {
            let file = files[i];
            filesLists.push(file);
        }
        return filesLists;
    }
    onDuplicateFileModal(status = false){
        this.setState({
            duplicateFileModal: status,
        })
    }
    render() {
        const { classes, multiple, uploadFileModal, 
            // uploadType 
        } = this.props;
        const { duplicateFileModal } = this.state;

        return (
            <div className={classes.inputFileWrapper+" "+(false ? classes.mobile : "")}>
                <input
                    type="file"
                    className={classes.inputFile}
                    multiple={multiple}
                    ref={this.hiddenFile}
                    onChange={this.addFile}
                    value=''
                />
                <input
                    type="file"
                    className={classes.inputFile}
                    multiple={multiple}
                    ref={this.hiddenFileDirectory}
                    onChange={this.addFolder}
                    webkitdirectory="webkitdirectory"
                    directory="directory"
                    value=''
                />
                {
                    uploadFileModal ?
                        <div className={classes.addFilesSection}>
                            <svg width="21" height="28" viewBox="0 0 21 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.4" d="M0 3.5C0 1.56953 1.56953 0 3.5 0H12.25V7C12.25 7.96797 13.032 8.75 14 8.75H21V24.5C21 26.4305 19.4305 28 17.5 28H3.5C1.56953 28 0 26.4305 0 24.5V3.5ZM5.25 16.625C5.25 16.9586 5.37578 17.2977 5.63281 17.5547C6.14687 18.0688 6.97813 18.0688 7.48672 17.5547L9.18203 15.8594L9.1875 21.4375C9.1875 22.1648 9.77266 22.75 10.5 22.75C11.2273 22.75 11.8125 22.1648 11.8125 21.4375V15.8539L13.5078 17.5492C14.0219 18.0633 14.8531 18.0633 15.3617 17.5492C15.8703 17.0352 15.8758 16.2039 15.3617 15.6953L11.4242 11.7578C10.9102 11.2438 10.0789 11.2438 9.57031 11.7578L5.63281 15.6953C5.37578 15.9523 5.25 16.2859 5.25 16.625Z" fill="#556DC2"/>
                                <path d="M21.0001 8.75H14.0001C13.0321 8.75 12.2501 7.96797 12.2501 7V0L21.0001 8.75ZM11.8126 21.4375C11.8126 22.1648 11.2274 22.75 10.5001 22.75C9.77276 22.75 9.1876 22.1648 9.1876 21.4375V15.8539L7.49229 17.5492C6.97823 18.0633 6.14698 18.0633 5.63838 17.5492C5.12979 17.0352 5.12432 16.2039 5.63838 15.6953L9.57588 11.7578C10.0899 11.2437 10.9212 11.2437 11.4298 11.7578L15.3673 15.6953C15.8814 16.2094 15.8814 17.0406 15.3673 17.5492C14.8532 18.0578 14.022 18.0633 13.5134 17.5492L11.8181 15.8539L11.8126 21.4375Z" fill="#556DC2"/>
                            </svg>
                            <p>
                                Drag and Drop files here or
                                <Button link className="add-file-title" onClick={this.onFocus}>
                                    Choose file
                                </Button>
                                &nbsp; or
                                <Button link className="add-file-title" onClick={this.onDirectoryFocus}>
                                    Choose Folder
                                </Button>
                            </p>
                        </div>
                    :
                        <div className={classes.addFilesButton+" add-files-button"}>
                            <ul>
                                <li>
                                    <Button onClick={this.onFocus} className="add-file-title">
                                        <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_1017_4426)">
                                            <path opacity="0.4" d="M0 2.25C0 1.00898 1.00898 0 2.25 0H7.875V4.5C7.875 5.12227 8.37773 5.625 9 5.625H13.5V15.75C13.5 16.991 12.491 18 11.25 18H2.25C1.00898 18 0 16.991 0 15.75V2.25ZM3.375 10.6875C3.375 10.902 3.45586 11.1199 3.62109 11.2852C3.95156 11.6156 4.48594 11.6156 4.81289 11.2852L5.90273 10.1953L5.90625 13.7812C5.90625 14.2488 6.28242 14.625 6.75 14.625C7.21758 14.625 7.59375 14.2488 7.59375 13.7812V10.1918L8.68359 11.2816C9.01406 11.6121 9.54844 11.6121 9.87539 11.2816C10.2023 10.9512 10.2059 10.4168 9.87539 10.0898L7.34414 7.55859C7.01367 7.22813 6.4793 7.22813 6.15234 7.55859L3.62109 10.0898C3.45586 10.2551 3.375 10.4695 3.375 10.6875Z" fill="#556DC2"/>
                                            <path d="M13.4999 5.625H8.99986C8.37759 5.625 7.87486 5.12227 7.87486 4.5V0L13.4999 5.625ZM7.59361 13.7812C7.59361 14.2488 7.21743 14.625 6.74986 14.625C6.28228 14.625 5.90611 14.2488 5.90611 13.7812V10.1918L4.81626 11.2816C4.48579 11.6121 3.95142 11.6121 3.62446 11.2816C3.29751 10.9512 3.294 10.4168 3.62446 10.0898L6.15571 7.55859C6.48618 7.22813 7.02056 7.22813 7.34751 7.55859L9.87876 10.0898C10.2092 10.4203 10.2092 10.9547 9.87876 11.2816C9.54829 11.6086 9.01392 11.6121 8.68696 11.2816L7.59712 10.1918L7.59361 13.7812Z" fill="#556DC2"/>
                                            </g>
                                            <defs>
                                            <clipPath id="clip0_1017_4426">
                                            <rect width="13.5" height="18" fill="white"/>
                                            </clipPath>
                                            </defs>
                                        </svg>
                                        Add files
                                    </Button>
                                </li>
                                <li className="or-text">or</li>
                                <li>
                                    <Button onClick={this.onDirectoryFocus} className="select-folder">
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.4" d="M0 3.375V14.625C0 15.866 1.00898 16.875 2.25 16.875H15.75C16.991 16.875 18 15.866 18 14.625V5.625C18 4.38398 16.991 3.375 15.75 3.375H10.125C9.76992 3.375 9.43594 3.20977 9.225 2.925L8.55 2.025C8.12461 1.45898 7.45664 1.125 6.75 1.125H2.25C1.00898 1.125 0 2.13398 0 3.375ZM5.625 9.5625C5.625 9.34805 5.70586 9.13008 5.87109 8.96484L8.40234 6.43359C8.56758 6.26836 8.78203 6.1875 9 6.1875C9.21797 6.1875 9.43242 6.26836 9.59766 6.43359L12.1289 8.96484C12.2941 9.13008 12.375 9.34453 12.375 9.5625C12.375 9.78047 12.2941 9.99492 12.1289 10.1602C11.7984 10.4906 11.2641 10.4906 10.9371 10.1602L9.84727 9.07031L9.84375 12.6562C9.84375 13.1238 9.46758 13.5 9 13.5C8.53242 13.5 8.15625 13.1238 8.15625 12.6562V9.0668L7.06641 10.1566C6.73594 10.4871 6.20156 10.4871 5.87461 10.1566C5.70937 9.99141 5.62852 9.77695 5.62852 9.55898L5.625 9.5625Z" fill="#FAAF42"/>
                                            <path d="M8.9998 13.5C9.46738 13.5 9.84355 13.1239 9.84355 12.6563V9.06685L10.9334 10.1567C11.2639 10.4872 11.7982 10.4872 12.1252 10.1567C12.4521 9.82622 12.4557 9.29185 12.1252 8.96489L9.59395 6.43364C9.26348 6.10317 8.7291 6.10317 8.40215 6.43364L5.8709 8.96489C5.54043 9.29536 5.54043 9.82974 5.8709 10.1567C6.20137 10.4836 6.73574 10.4872 7.0627 10.1567L8.15254 9.06685L8.15605 12.6563C8.15605 13.1239 8.53223 13.5 8.9998 13.5Z" fill="#FAAF42"/>
                                        </svg>
                                        Select a folder
                                    </Button>
                                </li>
                            </ul>
                        </div>
                }
                {
                    duplicateFileModal === true ? 
                        <DuplicateFileModal 
                            open={duplicateFileModal} 
                            onClose={() => this.onDuplicateFileModal(false)} 
                            successMessage={"The File/folder has already been added to the list to be uploaded!"}
                        />
                    :
                        <></>
                }
            </div>
        );
    }
}

CustomFileUpload.defaultProps = {
    multiple: false,
    onChange: function(){  },
    uploadFileModal: false,
    uploadType: 'file'
};

CustomFileUpload.propTypes = {
    classes: PropTypes.object,
    id: PropTypes.string,
    endButton: PropTypes.object,
    startButton: PropTypes.object,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    multiple: PropTypes.bool,
    onChange: PropTypes.func,
    uploadFileModal: PropTypes.bool,
    uploadType: PropTypes.string
};

export default withStyles(styles)(CustomFileUpload);
