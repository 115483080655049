import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import axios from "axios";
import Api from "../../../../assets/js/utils/Api";
import ApiOneDrive from "../../../../assets/js/utils/oneDrive/Api";
import ApiGoogleDrive from "../../../../assets/js/utils/GoogleDrive/Api";
import ReactTable from "react-table";
import CreateNewFolder from "@material-ui/icons/CreateNewFolder"
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { helper } from "../../../../assets/js/utils/Element.js";
import NewFolderModal from "../NewFolderModal.js";
import Check from "@material-ui/icons/Check";
import userAccountModalStyle from '../../../../assets/jss/user/userAccountModalStyle.jsx';
import LoaderComponent from "../../Loader";
import InfoIcon from "@material-ui/icons/Info";
import ArchivesInfoModal from "../ArchivesInfoModal";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const NewEditAccountModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        this.history = this.props.history;
        this.state = {
            emailid: this.props.emailid,
            limit: 200,
            totalItemCount: 0,
            response: [],
            cancelToken: null,
            page: 1,
            loading: false,
            loadingMore: false,
            totalPages: 1,
            currentPath: 'My Docs',
            backPath: "/My Docs/",
            favourites: [],
            file: null,
            creatingFolder: false,
            files: [],
            newDataPush:[],
            checked:'',
            storeOneDriveFolderPath:'',
            currentPathOld:'',
            checkedVal:'mydoc',
            responseNew:[],
            folderParentIdOneDrive:null,
            folderParentPathOneDrive:null,
            newFolderModal: false,
            checkedPath: '/My Docs',
            previousChecked: '',
            infoModal: false
        };
    }
    componentDidMount() {
        const { type } = this.props;
        if(type === 'one_drive'){
            this.getOneDriveData();
        }else if(type === 'google_drive'){
            this.getGoogleDriveData();
        }

        // const {user} = this.props
        const userData = JSON.parse(localStorage.getItem("sendlinx_userinfo"));
        const New = userData.extensions[type].path;
        if (New) {
            const Newt = this.removeLastCharacterFromUrl(New);
            let getPathW ='';
            if (Newt) {
                getPathW ="/My Docs/"+ Newt;   
            }else{
                getPathW ="/My Docs"; 
            }
            this.myDocTable(null, getPathW,"",false)
        }else{
            const {currentPath}=this.state;
            this.myDocTable(null, currentPath,"",false)
        }

    }
    getOneDriveData(){
        ApiOneDrive.getOneDriveEmail().then(data => {
            // console.log(data);
        }).catch(err => {
            console.log('error',err);
        });
    }
    getGoogleDriveData(){
        ApiGoogleDrive.getGoogleDriveEmail().then(data => {
            // console.log(data);
        }).catch(err => {
            console.log('error',err);
        });
    }
    removeLastCharacterFromUrl(url) {
        const lastSlashIndex = url.lastIndexOf('/');
        const segments = url.split('/');
        if (segments.length > 1) {
            return url.slice(0, lastSlashIndex);
        }else{
            return null; 
        }
    }
    extractLastSegmentFromUrl(url) {
        if (!url) {
            return false;
        }
        const segments = url.split('/');
        if (segments.length > 1) {
            return segments[segments.length - 1];
        }else{
            return url;
        }
    }      
    myDocTable(file =null, newPath = null, backPath = "" ,isBack = false){
        const { type } = this.props;
        const userData = JSON.parse(localStorage.getItem("sendlinx_userinfo"));
        const New = userData.extensions[type].path;
        let stateObj = {
            checked:'',
            loading: true,
            storeOneDriveFolderPath: '',
            newFolderModal: false,
            creatingFolder: false,
        };
        if (New) {
            stateObj['checkedVal'] = '';
            stateObj['checkedPath'] = '/My Docs/'+New;
        }else{
            stateObj['checkedPath'] = '/My Docs';
        }

        this.setState(stateObj);

        if (isBack) {
            if (newPath === "/My Docs" || newPath === "" || newPath === "/" ||newPath === "/My Docs/") {
                newPath = null
            }else{
                var lastIndex = newPath.lastIndexOf('/');
                var updatedPath = newPath.substring(0, lastIndex);
                var lastIndex1 = updatedPath.lastIndexOf('/');
                var updatedPath1 = updatedPath.substring(0, lastIndex1);
                newPath = updatedPath1;
            }
        }
        const source = axios.CancelToken.source();
        let requestData = {
            page: 1,
            limit: 200,
            path: newPath == null ? '/My Docs':newPath,
            // nested_files: 1,
            foldersOnly: true
        };
        Api.getFiles(requestData, source).then(data => {
            const New = userData.extensions[type].path;
            const Raj = this.extractLastSegmentFromUrl(New);
            if (Raj) {
                data.response.forEach(element => {
                    if(element.name === Raj){
                        this.setState({
                            checked: element.id,
                            previousChecked: element.id
                        })
                    }
                });
            }
            this.setState({
                response: data.response,
                // backPath:(data.response.length != 0 ? data.response[0].folder_path : newPath),
                currentPath:(data.response.length !== 0 ? data.response[0].folder_path : newPath),
                loading: false
            })
        }).catch(err => {
            this.setState({ loading: false })
        });
    }
    handleChackBox(file){
        const { checked} = this.state;
        if(checked === file.id){
            this.setState({
                checked: '',
                checkedVal: 'mydoc',
                storeOneDriveFolderPath: '',
                checkedPath: '/My Docs',
             })
        }else{
            this.setState({
                checked: file.id,
                checkedVal: '',
                storeOneDriveFolderPath: file.path,
                checkedPath: file.path,
            })
        }
    }
    saveOneDrivePath(){
        this.setState({
            loading: true
        })
        const source = axios.CancelToken.source();
        const { user, type } = this.props
        const { storeOneDriveFolderPath } = this.state;
        let requestData = {
            path: storeOneDriveFolderPath.slice(9),
            name: type,
            userId:user.id
        };
        this.setState({
            loading: true
        })
        Api.addOneDrivePath(requestData, source).then(data => {
            const userData = JSON.parse(localStorage.getItem("sendlinx_userinfo"));
            userData.extensions[type].path = data?.data?.path;
            localStorage.setItem("sendlinx_userinfo", JSON.stringify(userData));
            let newUser = Api.prepareMemberObject(userData);
            this.store.dispatch({type: 'UPDATE_STATE',state: {user: newUser}});

            this.setState({
                loading: false
            })

            this.history.push('/user/extensions');
            // this.props.onClose(false, true);
        }).catch(err => {
            this.setState({ loading: false })
        }); 
    }
    getColumns(){
        let columns = [
            {
                Header: "",
                accessor: "check",
                sortable: false,
                filterable: false,
                headerClassName: "hd_check",
                className: "hd_check td_check",
            },
            {
                Header: "Name",
                accessor: "name",
                headerClassName: "hd_name",
                className: "hd_name td_name",
            },
        ];

        return columns;
    }
    getTableData(){
        const { classes } = this.props;
        const { response,backPath ,currentPath,checked,checkedVal} = this.state;
        if(response === null){
            return [];
        }
        let tableData = [];
        
        if(backPath !== currentPath){
            let fileArray = {
                id: 0,
                orignalName: "",
                check:"",
                name: (<Link href="/user/home"  onClick={(e) => {e.preventDefault(); this.myDocTable('',currentPath, backPath,true);}}>
                        <Button justIcon color="transparent" className={classes.fileIcon}>
                            { helper.getFolderIcon() }
                        </Button>
                        {"..."}
                    </Link>
                ),
                orignalSize: "",
                size: "",
                path:'',
                folderPath: "",
                recipients: "",
                type:''
            };
            tableData.push(fileArray);
        }
        response.map((file) => {
            if(file.type==='folder'){
                let fileArray = {
                    id: file.id,
                    check:(
                        <Checkbox
                            className={classes.positionAbsolute}
                            // disabled={( checked !=='' && file.id !== checked)}
                            classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                            }}
                            checkedIcon={<Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            checked={!checkedVal ? (file.id === checked ? true : false) : false}
                            onClick={() => this.handleChackBox(file)}
                        />
                    ),
                    type: file.type,
                    orignalName: file.name,
                    name: (
                        <div style={{display:'flex'}}>
                        <div className='thumbnail-icon'>
                            <Button justIcon color="transparent" className={classes.fileIcon}>
                                { helper.getFolderIcon() }
                            </Button>
                        </div>
                        <Link href={"/user/home"}  onClick={(e) => {e.preventDefault(); this.myDocTable(file, file?.path, backPath,false);}}>
                            <div className='filename'>{file.name}</div>
                        </Link>
                    </div>
                    ),
                    size: file.size,
                    folderPath: file?.folder_path,
                    path: file?.folder_path,
                };
                tableData.push(fileArray);
            }
            
            return null;
            
        });
            
        return tableData;
    }
    getTrGroupProps(state, rowInfo){
        const { checked } = this.state;
        
        if (rowInfo && rowInfo.row) {
            if(checked === rowInfo.original.id){
                return {
                    className: 'selected_row'
                }
            }
        }else{
            return {}
        }
    }
    getBreadCrumbs(){
        let path = '';
        const { currentPath} = this.state;
        path = currentPath;
        let breadcrumbs = [];
        if(path === "/"){
            return breadcrumbs;
        }
        if(path.substr(0,1) === "/"){
            path = path.substr(1);
        }
        const pathArray = path.split("/");
        const newdar = pathArray.filter(function (item){
            return item !== "";
        } );
        let newPath = "";
        newdar.map(path => {
            newPath += "/"+path;
            breadcrumbs.push({
                value: newPath,
                label: path
            });
            return null;
        });
        return breadcrumbs;
    }
    onNewFileModalSuccess(folderName){
        const {currentPath} = this.state;
        const source = axios.CancelToken.source();
        this.setState({
            creatingFolder: true,
            cancelToken: source,
        });
        const requestData = {
            folderName: folderName,
            path: currentPath
        }
        Api.addNewFolder(requestData, source).then(data => {
            const newPath = currentPath;
            this.myDocTable(null, newPath, "",false)
        }).catch(err => {
            console.log(err);
        });
    }
    onNewFolderModal(status = false){
        this.setState({
            newFolderModal: status
        })
    }
    onInfoModal(status = false){
        this.setState({
            infoModal: status,
        });
    }
    render() {
        const { classes, confirmationMessage } = this.props;
        const {checked, newFolderModal, creatingFolder, loading ,currentPath, checkedPath, previousChecked, infoModal } = this.state;
        const tableData = this.getTableData(); 
        const breadcrumbs = this.getBreadCrumbs(currentPath);

        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="NewEditAccountModal-modal-slide-title"
                aria-describedby="NewEditAccountModal-modal-slide-description"
            >
                <DialogTitle
                    id="NewEditAccountModal-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                >
                    <Button
                        simple
                        className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.props.onClose()}
                    >
                        <Close className={classes.modalClose} />
                    </Button>
                    <h4 className={classes.modalTitle}>
                        {confirmationMessage}
                        <Button justIcon color="transparent" className='last-modified' onClick={() => this.onInfoModal(true)}><InfoIcon /></Button>
                    </h4> 
                </DialogTitle>
                <DialogContent id="NewEditAccountModal-modal-slide-description" className={classes.modalBody} >
                    <div className='defultPath'>
                        <p>Current Destination Path : {checkedPath}</p>
                    </div>
                    {
                        loading ? 
                            <LoaderComponent color="custom" align="center" />
                        :
                            <>
                                <div className={classes.sdBackNewfolder}>
                                    <div className={classes.breadcrumbs} style={{textAlign:"left",padding:"5px"}}>
                                        <div>
                                            {
                                                breadcrumbs.map((breadCrumb, key) => {
                                                    return (
                                                        <div key={key}>
                                                            {
                                                                key !== 0 ?
                                                                    <span><ChevronRight /></span>
                                                                :
                                                                <></>
                                                            }
                                                            <Link href="/user/home" onClick={(e) => {e.preventDefault();this.myDocTable('',breadCrumb.value, '',false);}}>
                                                                {breadCrumb.label}
                                                            </Link>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    <Button color="transparent" onClick={() => this.onNewFolderModal(true)} justIcon round title="Create New Folder"><CreateNewFolder /></Button>
                                </div>
                                <ReactTable style={{overflow:"auto", height:"230px"}}
                                    data={tableData}  
                                    columns={this.getColumns()}
                                    defaultPageSize={100} 
                                    showPaginationTop={false}
                                    minRows={0}
                                    showPaginationBottom={false}
                                    className={"-striped -highlight  "+classes.filesTable+" "+classes.modalFilesTable} 
                                    getTrGroupProps={(state, rowInfo) => this.getTrGroupProps(state, rowInfo)}
                                />
                            </>
                    }
                </DialogContent>
                <DialogActions className={classes.modalFooter}>
                    {
                        loading ?
                            <></>
                        :
                            <>
                                <Button color="custom" className={classes.changeButton} onClick={() => this.saveOneDrivePath()} disabled={(previousChecked === checked)}>
                                    Update
                                </Button>
                                <Button color="white" className={classes.changeButton} onClick={() => this.props.onClose()}>
                                    Continue
                                </Button>
                            </>
                    }
                </DialogActions>
                {
                    newFolderModal ?
                        <NewFolderModal
                            open={newFolderModal}
                            saving={creatingFolder}
                            store={this.store} 
                            onClose={() => this.onNewFolderModal(false)}
                            onSuccess={(folderName) => this.onNewFileModalSuccess(folderName)}
                            confirmationMessage="Create a folder"
                            path={this.state.currentPath}
                        />
                    :
                        <></>
                }
                {
                    infoModal ?
                        <ArchivesInfoModal
                            open={infoModal}
                            onClose={() => this.onInfoModal(false)}
                            type={"extension-info"}
                        />
                    :
                        <></>
                }
            </Dialog>
        )
    }
}

NewEditAccountModal.defaultProps = {
    open: false,
    confirmationMessage: "",
    saving: false,
    emailid: "",
    type: 'one_drive'
}
NewEditAccountModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    confirmationMessage: PropTypes.string,
    saving: PropTypes.bool
}
export default withStyles(userAccountModalStyle)(NewEditAccountModal);